import React, { useState, useEffect } from "react";
import AuthorList from "../../components/library/AuthorList";
import { getAuthors } from "../../api/library/AuthorAPI";
import { Helmet } from "react-helmet-async";

const AuthorsPage = () => {
  const [authors, setAuthors] = useState([]);

  const refreshAuthors = async () => {
    try {
      const data = await getAuthors();
      setAuthors(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    refreshAuthors();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Authors | Maidan</title>
      </Helmet>
      <div className="content-below-header">
        <AuthorList
          authors={authors}
          refreshAuthors={refreshAuthors}
          showNewButton={true}
          showSearchBar={true}
        />
      </div>
    </div>
  );
};

export default AuthorsPage;
