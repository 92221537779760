import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import moment from "moment";
import { Input, Table, Row, Col, DatePicker } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";
import FilterSection from "../../components/record-keeper/FilterSection";
import LoadingSpinner from "../../components/LoadingSpinner";
import NoClassroomMessage from "../../components/NoClassroomMessage";
import "../../css/record-keeper/RecordKeeperPage.css";
import { useAuth } from "../../contexts/AuthContext";
import {
  fetchInitialData,
  fetchMasteryLevels,
  handleCellChange,
  handleKeyDown,
} from "../../helpers/recordKeeperHelper";
import {
  getFilteredChildren,
  getSortedChildren,
} from "../../helpers/childHelper";
import { calculateAge } from "../../helpers/dateTimeHelper";
import { shouldShowClassroomSelector } from "../../helpers/teacherHelper";
import { trackEvent } from "../../utils/mixpanel";

const RecordKeeperPage = ({ isMobile, setIsSidebarVisible }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const inputsRef = useRef({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    masteryLevels: [],
    domains: [],
    classrooms: [],
    concepts: {},
    children: {},
  });
  const [filters, setFilters] = useState({
    selectedDomain: "",
    selectedClassroom: "",
    conceptSearch: "",
    childSearch: "",
    sortOption: "ageLowToHigh",
    selectedLevels: [],
    shouldShowClassroomSelector: shouldShowClassroomSelector(currentUser),
  });
  const [selectedDate, setSelectedDate] = useState(moment());
  const [focusedCell, setFocusedCell] = useState(null);
  const [temporaryDisplay, setTemporaryDisplay] = useState({});

  useEffect(() => {
    if (!isMobile) {
      setIsSidebarVisible(false);
      return () => setIsSidebarVisible(true);
    }
  }, [isMobile, setIsSidebarVisible]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchInitialData(setData);
        setLoading(false);
        trackEvent("RecordKeeperPage Loaded", {}, currentUser);
      } catch (error) {
        console.error("Error fetching initial data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    fetchMasteryLevels(filters, setData);
  }, [filters.selectedClassroom, filters.selectedDomain, filters]);

  useEffect(() => {
    if (currentUser.classrooms?.length > 0 && !filters.selectedClassroom) {
      setFilters((prev) => ({
        ...prev,
        selectedClassroom: currentUser.classrooms[0].id,
      }));
    }
  }, [currentUser.classrooms]);

  const handleChange = (field) => (value) => {
    setFilters((prev) => ({ ...prev, [field]: value }));
    trackEvent(
      "RecordKeeperPage Filter Changed",
      {
        field,
        value,
      },
      currentUser
    );
  };

  // Filter and sort concepts
  const filteredConcepts = Object.values(data.concepts)
    .filter((concept) =>
      concept.name.toLowerCase().includes(filters.conceptSearch.toLowerCase())
    )
    .sort((a, b) => {
      // Sort by formatted_sequence numerically where '.' is considered as a separator
      const aParts = a.formatted_sequence.split(".").map(Number);
      const bParts = b.formatted_sequence.split(".").map(Number);

      for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
        if ((aParts[i] || 0) < (bParts[i] || 0)) return -1;
        if ((aParts[i] || 0) > (bParts[i] || 0)) return 1;
      }
      return 0;
    });

  const filteredChildren = getFilteredChildren(
    data.children,
    filters.childSearch
  );
  const sortedChildren = getSortedChildren(
    filteredChildren,
    filters.sortOption
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".ant-input")) {
        setFocusedCell(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = async (e, record, child, rowIndex, colIndex) => {
    const value = e.target.value;
    const masteryLevel = data.masteryLevels.find(
      (level) => level.level.toString() === value
    );
    if (masteryLevel) {
      const temporaryText = masteryLevel.code;
      setTemporaryDisplay((prev) => ({
        ...prev,
        [`${rowIndex}-${colIndex}`]: temporaryText,
      }));
      setTimeout(() => {
        setTemporaryDisplay((prev) => ({
          ...prev,
          [`${rowIndex}-${colIndex}`]: value,
        }));
      }, 1000);
    } else {
      setTemporaryDisplay((prev) => ({
        ...prev,
        [`${rowIndex}-${colIndex}`]: value === "0" ? "" : value,
      }));
    }
    await handleCellChange(
      value,
      record.key,
      child.child_id,
      child.first_name,
      selectedDate,
      filters,
      setData
    );
  };

  const columns = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 250,
      className: "left-align",
      render: (text, record) => (
        <div
          className={`${record.is_root ? "root-concept" : "non-root-concept"}`}
        >
          {record.formatted_sequence} {text}
        </div>
      ),
    },
    ...sortedChildren.map((child, colIndex) => {
      const childDOB = data.children[child.child_id]?.date_of_birth;
      const age = calculateAge(childDOB);
      return {
        title: (
          <>
            <div className="age">{age ? `${age}` : "NA"}</div>
            <Link to={`/children/${child.child_id}`} className="child-link">
              {child.first_name}
            </Link>
          </>
        ),
        dataIndex: `child_${child.child_id}`,
        key: `child_${child.child_id}`,
        className: "center-align top-align",
        render: (text, record, rowIndex) => {
          if (record.is_root) {
            return {
              children: <div className="root-concept-cell">{text}</div>,
              props: {
                colSpan: 1,
                className: "root-concept-cell",
              },
            };
          }

          const mastery = data.children[child.child_id]?.concept_mastery.find(
            (entry) => Number(entry.concept_id) === Number(record.key)
          );

          const color = mastery
            ? data.masteryLevels.find((level) => level.level === mastery.level)
                ?.color
            : "#FFFFFF";

          if (!inputsRef.current[`cell-${rowIndex}-${colIndex}`]) {
            inputsRef.current[`cell-${rowIndex}-${colIndex}`] =
              React.createRef();
          }

          const isFocused = focusedCell === `cell-${rowIndex}-${colIndex}`;

          const shouldRenderCell =
            filters.selectedLevels.length === 0 ||
            (mastery && filters.selectedLevels.includes(mastery.level));

          return shouldRenderCell ? (
            <Input
              type="text"
              inputMode="numeric"
              value={temporaryDisplay[`${rowIndex}-${colIndex}`] || text || ""}
              onChange={(e) => {
                handleInputChange(e, record, child, rowIndex, colIndex);
              }}
              className={`${isFocused ? "highlight-border" : ""}`}
              style={{
                backgroundColor: color,
              }}
              ref={inputsRef.current[`cell-${rowIndex}-${colIndex}`]}
              onKeyDown={(e) =>
                handleKeyDown(
                  e,
                  rowIndex,
                  colIndex,
                  filteredConcepts,
                  sortedChildren,
                  inputsRef,
                  setFocusedCell
                )
              }
              onFocus={(e) => e.target.select()}
              onClick={(e) => {
                e.target.select();
                setFocusedCell(`cell-${rowIndex}-${colIndex}`);
              }}
            />
          ) : null;
        },
      };
    }),
  ];

  // Creating a sorted array from the concepts object for rendering in the table
  const dataSource = Object.entries(data.concepts)
    .map(([id, concept]) => ({ id: Number(id), ...concept }))
    .sort((a, b) => {
      // Sort by formatted_sequence numerically where '.' is considered as a separator
      const aParts = a.formatted_sequence.split(".").map(Number);
      const bParts = b.formatted_sequence.split(".").map(Number);

      for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
        if ((aParts[i] || 0) < (bParts[i] || 0)) return -1;
        if ((aParts[i] || 0) > (bParts[i] || 0)) return 1;
      }
      return 0;
    })
    .map((concept, rowIndex) => {
      const conceptData = {
        key: concept.id,
        name: concept.name,
        formatted_sequence: concept.formatted_sequence, // Ensure formatted_sequence is included
        is_root: concept.is_root,
        level: concept.level,
        className: concept.is_root ? "root-concept-row" : "",
      };

      // Populate mastery levels for each child in each concept
      if (!concept.is_root) {
        sortedChildren.forEach((child) => {
          const conceptId = concept.id;
          const childId = child.child_id;
          const mastery = data.children[childId]?.concept_mastery.find(
            (entry) => entry.concept_id === conceptId
          );
          conceptData[`child_${childId}`] = mastery ? mastery.level : "";
        });
      }
      return conceptData;
    });

  if (currentUser.role === "Teacher" && currentUser.classrooms.length === 0) {
    setIsSidebarVisible(true);
    return <NoClassroomMessage title="Record Keeper" />;
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="record-keeper-page">
      <Helmet>
        <title>Record Keeper | Maidan</title>
      </Helmet>

      <Row
        align="middle"
        justify="space-between"
        gutter={8}
        style={{ marginBottom: 16 }}
      >
        <Col>
          <Row align="middle" gutter={8}>
            {!isMobile && (
              <Col>
                <CloseOutlined
                  className="close-icon"
                  onClick={() => navigate("/")}
                />
              </Col>
            )}
            <Col>
              <h1 className="page-heading-without-bottom-margin">
                Record Keeper
              </h1>
            </Col>
          </Row>
        </Col>
        <Col>
          <DatePicker
            onChange={setSelectedDate}
            defaultValue={moment()}
            format="DD-MM-YYYY"
          />
        </Col>
      </Row>

      <FilterSection
        filters={filters}
        handleChange={handleChange}
        data={data}
        isMobile={isMobile}
      />

      {sortedChildren.length > 0 && filteredConcepts.length > 0 && (
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          bordered
          scroll={{ x: "max-content", y: 560 }}
          sticky={{ offsetHeader: 64 }}
        />
      )}
    </div>
  );
};

export default RecordKeeperPage;
