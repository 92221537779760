// client/src/api/GuardAPI.js
import axios from "axios";

const baseURL = "/guards";

const api = axios.create({
  baseURL,
});

export const getGuards = async () => {
  try {
    const response = await api.get("/");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getGuardById = async (guardId = "") => {
  try {
    const response = await api.get(`/${guardId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const markGuardAttendance = (selfie, latitude, longitude) => {
  const formData = new FormData();

  const file = new File([selfie], `selfie-${Date.now()}.png`, {
    type: "image/png",
  });

  formData.append("selfie", file);
  formData.append("latitude", latitude);
  formData.append("longitude", longitude);

  return axios
    .post(`${baseURL}/attendance`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error marking attendance:", error);
      throw error;
    });
};

export const getGuardAttendanceReportByDate = async (date) => {
  try {
    const response = await api.get(`/attendance/daily-report?date=${date}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getGuardAttendanceReportByMonth = async (month) => {
  try {
    const response = await api.get(`/attendance/monthly-report?month=${month}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
