export const snakeCaseToSentenceCase = (text) => {
  return text.replace(/_/g, " ").replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
};

export const formatPhoneNumber = (number) => {
  if (!number || number.toString().trim() === "") {
    return "";
  }

  return number.toString().replace(/(\d{5})(\d{5})/, "$1 $2");
};
