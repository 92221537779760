import React from "react";
import { Select } from "antd";

const { Option } = Select;

const languages = [
  "Hindi",
  "Bengali",
  "Telugu",
  "Marathi",
  "Tamil",
  "Gujarati",
  "Kannada",
  "Odia",
  "Malayalam",
  "Punjabi",
  "Assamese",
  "English",
  "Spanish",
  "French",
  "German",
  "Chinese",
  "Japanese",
  "Other",
];

const LanguageDropdown = ({ value, onChange }) => (
  <Select
    value={value}
    onChange={onChange}
    showSearch
    placeholder="Select Language"
    optionFilterProp="children"
    filterOption={(input, option) =>
      option.children.toLowerCase().includes(input.toLowerCase())
    }
  >
    {languages.map((language) => (
      <Option key={language} value={language}>
        {language}
      </Option>
    ))}
  </Select>
);

export default LanguageDropdown;
