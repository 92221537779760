import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Alert } from "antd";
import { useAuth } from "../contexts/AuthContext";
import SchoolNameOnboardingForm from "../components/SchoolNameOnboardingForm";
import LoadingSpinner from "../components/LoadingSpinner";
import "../css/UserRoleSelectionPage.css";

const UserRoleSelectionPage = ({ currentUser }) => {
  const { handleLogout } = useAuth();
  const [role, setRole] = useState("");
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleRoleChange = (value) => {
    setRole(value);
    setMessage(null);
  };

  const renderRoleContent = () => {
    if (role === "Founder") {
      return <SchoolNameOnboardingForm setMessage={setMessage} />;
    } else if (role === "Guardian" || role === "Teacher") {
      const infoMessage = "Please contact your school to set up your account.";
      return (
        <Alert
          message={infoMessage}
          type="info"
          showIcon
          className="user-role-alert"
        />
      );
    }
    return null;
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <Helmet>
        <title>Onboarding | Maidan</title>
      </Helmet>
      <div className="user-role-selection-page">
        <header className="onboarding-header">
          <Button onClick={handleLogout} type="link" className="logout-button">
            Logout
          </Button>
        </header>
        <div className="user-role-selection-container">
          <h2 className="heading">
            Hi {currentUser.display_name},<br /> What's your role?
          </h2>
          <div className="role-buttons-container">
            <Button
              type={role === "Founder" ? "primary" : "default"}
              onClick={() => handleRoleChange("Founder")}
              className="role-button"
            >
              Founder
            </Button>
            <Button
              type={role === "Guardian" ? "primary" : "default"}
              onClick={() => handleRoleChange("Guardian")}
              className="role-button"
            >
              Guardian
            </Button>
            <Button
              type={role === "Teacher" ? "primary" : "default"}
              onClick={() => handleRoleChange("Teacher")}
              className="role-button"
            >
              Teacher
            </Button>
          </div>
          {renderRoleContent()}
        </div>
      </div>
    </div>
  );
};

export default UserRoleSelectionPage;
