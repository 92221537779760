import React from "react";
import { Form, Input, Button, Typography, Upload, Image } from "antd";
import { toast } from "react-toastify";
import LanguageDropdown from "./LanguageDropdown";
import PublisherDropdown from "./PublisherDropdown";
import CategoriesDropdown from "./CategoriesDropdown"; 
import CollectionsDropdown from "./CollectionsDropdown";
import AuthorsDropdown from "./AuthorsDropdown";
import { UploadOutlined } from '@ant-design/icons';
import { useState } from "react";

const { Title } = Typography;

const BookEditForm = ({ book = {}, onFinish, refreshBooks, title }) => {
  const [image, setImage] = useState(null);
  
  const handleImageChange = (file) => {
    setImage(file); 
  };

  const handleUpload = async () => {
    if (!image) return null;
    return image
  };

  const handleFinish = async(bookData) => {
    const s3ImageUrl = await handleUpload();  // Upload image and get the S3 URL
    if (image) {
      bookData.cover_image_url = s3ImageUrl;  // Add S3 image URL to form data
    }
    onFinish(bookData)
      .then(() => {
        refreshBooks();
        toast.success(
          <>
            Book <b>{bookData.title}</b> {book.id ? "updated" : "created"} successfully!
          </>
        );
      })
      .catch((error) => {
        console.error("Error handling book:", error);
        toast.error(
          <>
            Failed to {book.id ? "update" : "create"} book <b>{bookData.title}</b>.
          </>
        );
      });
  };

  return (
    <>
      <Title level={2}>{title}</Title>
      <Form
        initialValues={book}
        onFinish={handleFinish}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: "Please input the book title." }]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="description" label="Description">
          <Input />
        </Form.Item>

        <Form.Item name="page_count" label="Page Count">
          <Input type="number" />
        </Form.Item>

        <Form.Item
          name="language"
          label="Language"
          rules={[{ required: true, message: "Please select the language." }]}
        >
          <LanguageDropdown />
        </Form.Item>

        <Form.Item
          name="publisher_id"
          label="Publisher"
        >
          <PublisherDropdown />
        </Form.Item>

        {/* New Form Item for Authors */}
        <Form.Item
          name="authors"
          label="Authors"
        >
          <AuthorsDropdown />
        </Form.Item>

        {/* New Form Item for Categories */}
        <Form.Item
          name="categories"
          label="Categories"
        >
          <CategoriesDropdown />
        </Form.Item>

        {/* New Form Item for Collections */}
        <Form.Item
          name="collections"
          label="Collections"
        >
          <CollectionsDropdown />
        </Form.Item>

        <Form.Item
          name="published_year"
          label="Published Year"
        >
          <Input type="number" />
        </Form.Item>
        <div style={{display:"flex", width:"100%"}}>
        <Form.Item name="cover_image_url" label="cover_image_url" style={{width:"180px"}}>
            <Upload
              listType="picture"
              maxCount={1}
              beforeUpload={(e)=> {
                handleImageChange(e)
                return false;
              }}
            >
              <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
        </Form.Item>
        {book.cover_image_url && <Image src={`${book.cover_image_url}`} style={{width:"100px", marginTop:"10px", marginLeft:"50px"}}/>}    
        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {book.id ? "Update Book" : "Create Book"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default BookEditForm;
