import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Button, message } from "antd";
import { CameraOutlined } from "@ant-design/icons";
import { markGuardAttendance } from "../../api/GuardAPI";
import { trackEvent } from "../../utils/mixpanel";
import { useAuth } from "../../contexts/AuthContext";

const MarkGuardAttendancePage = () => {
  const { currentUser } = useAuth();
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [imageCaptured, setImageCaptured] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false);
  const [attendanceTime, setAttendanceTime] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    trackEvent("MarkGuardAttendancePage Loaded", {}, currentUser);

    // Access the camera and stream the video to the video element
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            width: 400,
            height: 400,
          },
        })
        .then((stream) => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            videoRef.current.muted = true;
            videoRef.current.addEventListener("loadedmetadata", () => {
              videoRef.current
                .play()
                .then(() => {
                  setVideoVisible(true); // Show buttons when video feed is ready
                })
                .catch((error) => {
                  console.error("Error trying to play the video:", error);
                });
            });
          }
        })
        .catch((err) => {
          console.error("Error accessing the camera", err);
          message.error("Error accessing the camera");
        });
    } else {
      message.error("Camera not supported by this browser.");
      trackEvent("Guard Attendance: Camera Not Supported", {}, currentUser);
    }
  }, [currentUser]);

  const handleCaptureSelfie = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);

          // Capture the image after the location has been successfully retrieved
          const context = canvasRef.current.getContext("2d");
          context.drawImage(
            videoRef.current,
            0,
            0,
            canvasRef.current.width,
            canvasRef.current.height
          );
          setImageCaptured(true);
          setVideoVisible(false); // Hide video feed and show captured image

          trackEvent(
            "Guard Attendance: Selfie Captured",
            {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
            currentUser
          );
        },
        (error) => {
          message.error("Failed to retrieve location");
          console.error("Error getting location:", error);
          trackEvent(
            "Guard Attendance: Geolocation Failure",
            { error: error.message },
            currentUser
          );
        }
      );
    } else {
      message.error("Geolocation is not supported by this browser.");
      trackEvent(
        "Guard Attendance: Geolocation Not Supported",
        {},
        currentUser
      );
    }
  };

  const handleCancel = () => {
    setImageCaptured(false);
    setVideoVisible(true); // Show video feed again

    trackEvent("Guard Attendance: Selfie Capture Canceled", {}, currentUser);
  };

  const handleMarkAttendance = () => {
    if (!imageCaptured || !latitude || !longitude) {
      message.error("Please capture a selfie and allow location access.");
      trackEvent(
        "Guard Attendance: Attempted Without Image or Location",
        {},
        currentUser
      );
      return;
    }

    canvasRef.current.toBlob((blob) => {
      if (!blob) {
        console.error("Failed to create blob from canvas");
        return;
      }

      markGuardAttendance(blob, latitude, longitude)
        .then(() => {
          const currentTime = new Date().toLocaleTimeString();
          setAttendanceTime(currentTime);
          message.success(`Attendance marked successfully at ${currentTime}`);

          trackEvent(
            "Guard Attendance: Attendance Marked Successfully",
            { attendanceTime: currentTime },
            currentUser
          );
        })
        .catch((error) => {
          console.error("Error marking attendance:", error);
          message.error("Failed to mark attendance");

          trackEvent(
            "Guard Attendance: Attendance Mark Failed",
            { error: error.message },
            currentUser
          );
        });
    }, "image/png");
  };

  return (
    <div>
      <Helmet>
        <title>Guard Attendance | Maidan</title>
      </Helmet>

      <h1 className="page-heading">Mark Attendance</h1>

      <div style={{ margin: "20px 0", position: "relative" }}>
        <video
          ref={videoRef}
          style={{
            width: "400px",
            height: "400px",
            display: videoVisible ? "block" : "none",
            objectFit: "cover",
          }}
          muted
          playsInline
        />
        <canvas
          ref={canvasRef}
          style={{
            display: imageCaptured ? "block" : "none",
            width: "400px",
            height: "400px",
          }}
          width="400"
          height="400"
        ></canvas>
      </div>
      {attendanceTime ? (
        <p>Attendance recorded at {attendanceTime}</p>
      ) : (
        <>
          {videoVisible && (
            <Button
              icon={<CameraOutlined />}
              onClick={handleCaptureSelfie}
              style={{ marginRight: 10 }}
            >
              Capture Image
            </Button>
          )}
          {imageCaptured && (
            <>
              <Button onClick={handleCancel} style={{ marginRight: 10 }}>
                Cancel
              </Button>
              <Button type="primary" onClick={handleMarkAttendance}>
                Mark Attendance
              </Button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MarkGuardAttendancePage;
