import React from "react";
import { Form, Input, Button, Typography } from "antd";
import { toast } from "react-toastify";

const { Title } = Typography;

const CategoryEditForm = ({
  category = {},
  onFinish,
  refreshCategories,
  title,
}) => {
  const handleFinish = (categoryData) => {
    onFinish(categoryData)
      .then(() => {
        refreshCategories();
        toast.success(
          <>
            Category <b>{categoryData.name}</b>{" "}
            {category.id ? "updated" : "created"} successfully!
          </>
        );
      })
      .catch((error) => {
        console.error("Error handling category:", error);
        toast.error(
          <>
            Failed to {category.id ? "update" : "create"} category{" "}
            <b>{categoryData.name}</b>.
          </>
        );
      });
  };

  return (
    <>
      <Title level={2}>{title}</Title>
      <Form
        initialValues={category}
        onFinish={handleFinish}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please input the name." }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {category.id ? "Update Category" : "Create Category"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default CategoryEditForm;
