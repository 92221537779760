import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Table, Drawer, Button, Input } from "antd";
import AuthorEditForm from "./AuthorEditForm";
import { createAuthor, updateAuthor } from "../../api/library/AuthorAPI";

const { Search } = Input;

const AuthorList = ({
  authors,
  refreshAuthors,
  showNewButton = false,
  showSearchBar = false,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedAuthor, setSelectedAuthor] = useState(null);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  
  const filteredAuthors = authors.filter((author) =>
    author.first_name.toLowerCase().includes(searchQuery.toLowerCase())||(author.last_name && (author.last_name.toLowerCase().includes(searchQuery.toLowerCase())))
  );

  const handleRowClick = (record) => {
    setSelectedAuthor(record);
    setIsDrawerOpen(true);
  };

  const handleNewAuthor = () => {
    setSelectedAuthor({});
    setIsDrawerOpen(true);
  };

  const handleFormFinish = (authorData) => {
    if (selectedAuthor && selectedAuthor.id) {
      return updateAuthor(selectedAuthor.id, authorData).then(() => {
        setIsDrawerOpen(false);
        refreshAuthors();
      });
    } else {
      return createAuthor(authorData).then(() => {
        setIsDrawerOpen(false);
        refreshAuthors();
      });
    }
  };

  const columns = [
    {
      title: "Sl No",
      key: "sl_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record) => (
        <Link to={`/library/authors/${record.id}`}>{text}</Link>
      ),
    },
    {
        title: "Last Name",
        dataIndex: "last_name",
        key: "last_name",
        render: (text, record) => (
          <Link to={`/library/authors/${record.id}`}>{text}</Link>
        ),
      }
  ];

  return (
    <div className="table-view">
      {showNewButton && (
        <Button
          type="default"
          onClick={handleNewAuthor}
          style={{ marginBottom: 16 }}
        >
          New Author
        </Button>
      )}
      {showSearchBar && (
        <Search
          placeholder="Search by name"
          allowClear
          onChange={handleSearch}
          style={{ marginBottom: 16 }}
        />
      )}
      <Table
        className="custom-table"
        columns={columns}
        dataSource={filteredAuthors}
        rowKey="id"
        rowClassName={(record, index) =>
          index % 2 === 0 ? "row-even" : "row-odd"
        }
        onRow={(record) => {
          return {
            onClick: () => {
              handleRowClick(record);
            },
          };
        }}
        pagination={filteredAuthors.length > 10 ? { pageSize: 10 } : false}
      />
      <Drawer
        width={400}
        onClose={() => {
          setIsDrawerOpen(false);
          setSelectedAuthor(null);
        }}
        open={isDrawerOpen}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {isDrawerOpen && (
          <AuthorEditForm
            author={selectedAuthor}
            onFinish={handleFormFinish}
            refreshAuthors={refreshAuthors}
            title={
              selectedAuthor && selectedAuthor.id
                ? "Edit Author"
                : "New Author"
            }
          />
        )}
      </Drawer>
    </div>
  );
};

export default AuthorList;
