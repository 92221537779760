import React, { useState, useEffect } from "react";
import { Form, Input, Button, Spin } from "antd";
import { TwitterPicker } from "react-color";

const MasteryLevelEditForm = ({ masteryLevel, onFinish, title }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#ffffff");

  useEffect(() => {
    if (masteryLevel) {
      form.setFieldsValue({
        level: masteryLevel.level,
        name: masteryLevel.name,
        code: masteryLevel.code,
        color: masteryLevel.color,
      });
      setColor(masteryLevel.color || "#ffffff");
    }
  }, [masteryLevel, form]);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await onFinish({ ...values, color });
    } finally {
      setLoading(false);
    }
  };

  const handleColorChange = (color) => {
    setColor(color.hex);
  };

  return (
    <Spin spinning={loading}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="level"
          label="Level"
          rules={[{ required: true, message: "Please enter the level" }]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please enter the name" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="code"
          label="Code"
          rules={[{ required: true, message: "Please enter the code" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="color"
          label="Color"
          rules={[{ required: true, message: "Please select a color" }]}
        >
          <TwitterPicker color={color} onChangeComplete={handleColorChange} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {title}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default MasteryLevelEditForm;
