import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import EmployeeList from "../components/EmployeeList";
import ChildList from "../components/ChildList";
import ManageChildrenModal from "../components/ManageChildrenModal";
import ManageTeachersModal from "../components/ManageTeachersModal";
import LoadingSpinner from "../components/LoadingSpinner";
import {
  getClassroomById,
  mapChildToClassroom,
  unmapChildFromClassroom,
  mapTeacherToClassroom,
  unmapTeacherFromClassroom,
} from "../api/SchoolAPI";
import { useAbility } from "../hooks/useAbility";

const ClassroomPage = () => {
  const { classroomId } = useParams();
  const [classroom, setClassroom] = useState(null);
  const [isChildModalVisible, setIsChildModalVisible] = useState(false);
  const [isTeacherModalVisible, setIsTeacherModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [modalType, setModalType] = useState("child");
  const ability = useAbility();

  useEffect(() => {
    const fetchClassroom = async () => {
      try {
        const data = await getClassroomById(classroomId);
        setClassroom(data);
      } catch (error) {
        console.error("Error fetching classroom:", error);
      }
    };
    fetchClassroom();
  }, [classroomId]);

  const refreshClassroom = useCallback(async () => {
    try {
      const data = await getClassroomById(classroomId);
      setClassroom(data);
    } catch (error) {
      console.error(error);
    }
  }, [classroomId]);

  useEffect(() => {
    refreshClassroom();
  }, [refreshClassroom]);

  const handleAddChildren = async (childIds) => {
    try {
      for (const childId of childIds) {
        await mapChildToClassroom(classroomId, childId);
      }
      setIsChildModalVisible(false);
      refreshClassroom();
    } catch (error) {
      console.error("Error adding children to classroom:", error);
    }
  };

  const handleRemoveChildren = async (childIds) => {
    try {
      for (const childId of childIds) {
        await unmapChildFromClassroom(classroomId, childId);
      }
      setIsChildModalVisible(false);
      refreshClassroom();
    } catch (error) {
      console.error("Error removing children from classroom:", error);
    }
  };

  const handleAddTeachers = async (teacherIds) => {
    try {
      for (const teacherId of teacherIds) {
        await mapTeacherToClassroom(classroomId, teacherId);
      }
      setIsTeacherModalVisible(false);
      refreshClassroom();
    } catch (error) {
      console.error("Error adding teachers to classroom:", error);
    }
  };

  const handleRemoveTeachers = async (teacherIds) => {
    try {
      for (const teacherId of teacherIds) {
        await unmapTeacherFromClassroom(classroomId, teacherId);
      }
      setIsTeacherModalVisible(false);
      refreshClassroom();
    } catch (error) {
      console.error("Error removing teachers from classroom:", error);
    }
  };

  if (!classroom) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <Helmet>
        <title>{classroom.name} | Maidan</title>
      </Helmet>

      <h1 className="page-heading">{classroom.name}</h1>

      <h3>Teachers</h3>
      {ability.can("add", "teachers") && (
        <Button
          onClick={() => {
            setModalType("teacher");
            setModalMode("add");
            setIsTeacherModalVisible(true);
          }}
        >
          Add Teachers
        </Button>
      )}
      {ability.can("remove", "teachers") && (
        <Button
          onClick={() => {
            setModalType("teacher");
            setModalMode("remove");
            setIsTeacherModalVisible(true);
          }}
          style={{ marginLeft: 8 }}
        >
          Remove Teachers
        </Button>
      )}
      <EmployeeList employees={classroom.teachers} />

      <h3>Children</h3>
      {ability.can("add", "children") && (
        <Button
          onClick={() => {
            setModalType("child");
            setModalMode("add");
            setIsChildModalVisible(true);
          }}
        >
          Add Children
        </Button>
      )}
      {ability.can("remove", "children") && (
        <Button
          onClick={() => {
            setModalType("child");
            setModalMode("remove");
            setIsChildModalVisible(true);
          }}
          style={{ marginLeft: 8 }}
        >
          Remove Children
        </Button>
      )}
      <ChildList
        children={classroom.children}
        showSortOption={true}
        showSearchBar={true}
      />

      <ManageChildrenModal
        classroomId={classroomId}
        isOpen={isChildModalVisible}
        onClose={() => setIsChildModalVisible(false)}
        onAdd={modalMode === "add" ? handleAddChildren : handleRemoveChildren}
        mode={modalMode}
        existingChildren={classroom.children}
      />
      <ManageTeachersModal
        classroomId={classroomId}
        isOpen={isTeacherModalVisible}
        onClose={() => setIsTeacherModalVisible(false)}
        onAdd={modalMode === "add" ? handleAddTeachers : handleRemoveTeachers}
        mode={modalMode}
        existingTeachers={classroom.teachers}
      />
    </div>
  );
};

export default ClassroomPage;
