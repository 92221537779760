import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { getClassrooms } from "../api/SchoolAPI";

const { Option } = Select;

const ClassroomSelector = ({ selectedClassroom, setSelectedClassroom }) => {
  const [classrooms, setClassrooms] = useState([]);

  useEffect(() => {
    getClassrooms()
      .then((data) => setClassrooms(data))
      .catch((error) => console.error("Error fetching classrooms:", error));
  }, []);

  return (
    <Select
      placeholder="Select Classroom"
      value={selectedClassroom}
      onChange={setSelectedClassroom}
    >
      {classrooms.map((classroom) => (
        <Option key={classroom.id} value={classroom.id}>
          {classroom.name}
        </Option>
      ))}
    </Select>
  );
};

export default ClassroomSelector;
