// src/pages/ChildPage.js

import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import ClassroomList from "../components/ClassroomList";
import GuardianList from "../components/GuardianList";
import ManageClassroomsModal from "../components/ManageClassroomsModal";
import ManageGuardiansModal from "../components/ManageGuardiansModal";
import { getChildById } from "../api/ChildAPI";
import { useAbility } from "../hooks/useAbility";
import LoadingSpinner from "../components/LoadingSpinner";
import {
  mapChildToClassroom,
  unmapChildFromClassroom,
  mapGuardianToChild,
  unmapGuardianFromChild,
} from "../api/SchoolAPI";
import { getFullName } from "../helpers/childHelper";

const ChildPage = () => {
  const { childId } = useParams();
  const [child, setChild] = useState(null);
  const [isClassroomModalOpen, setIsClassroomModalOpen] = useState(false);
  const [isGuardianModalOpen, setIsGuardianModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");

  const ability = useAbility();

  useEffect(() => {
    const fetchChild = async () => {
      try {
        const data = await getChildById(childId);
        setChild(data);
      } catch (error) {
        console.error("Error fetching child:", error);
      }
    };
    fetchChild();
  }, [childId]);

  const refreshChild = useCallback(async () => {
    try {
      const data = await getChildById(childId);
      setChild(data);
    } catch (error) {
      console.error(error);
    }
  }, [childId]);

  useEffect(() => {
    refreshChild();
  }, [refreshChild]);

  const handleAddClassrooms = async (classroomIds) => {
    try {
      for (const classroomId of classroomIds) {
        await mapChildToClassroom(classroomId, childId);
      }
      setIsClassroomModalOpen(false);
      refreshChild();
    } catch (error) {
      console.error("Error adding classrooms to child:", error);
    }
  };

  const handleRemoveClassrooms = async (classroomIds) => {
    try {
      for (const classroomId of classroomIds) {
        await unmapChildFromClassroom(classroomId, childId);
      }
      setIsClassroomModalOpen(false);
      refreshChild();
    } catch (error) {
      console.error("Error removing classrooms from child:", error);
    }
  };

  const handleAddGuardians = async (guardianData) => {
    try {
      for (const { guardianId, relationship } of guardianData) {
        await mapGuardianToChild(childId, guardianId, relationship);
      }
      setIsGuardianModalOpen(false);
      refreshChild();
    } catch (error) {
      console.error("Error adding guardians to child:", error);
    }
  };

  const handleRemoveGuardians = async (guardianData) => {
    try {
      for (const { guardianId } of guardianData) {
        await unmapGuardianFromChild(childId, guardianId);
      }
      setIsGuardianModalOpen(false);
      refreshChild();
    } catch (error) {
      console.error("Error removing guardians from child:", error);
    }
  };

  if (!child) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <Helmet>
        <title>{child.first_name} | Maidan</title>
      </Helmet>

      <h1 className="page-heading">{getFullName(child)}</h1>

      <h3>Classrooms</h3>
      {ability.can("add", "classroom") && (
        <>
          <Button
            onClick={() => {
              setModalMode("add");
              setIsClassroomModalOpen(true);
            }}
          >
            Add Classrooms
          </Button>
          <Button
            onClick={() => {
              setModalMode("remove");
              setIsClassroomModalOpen(true);
            }}
            style={{ marginLeft: 8 }}
          >
            Remove Classrooms
          </Button>
        </>
      )}
      <ClassroomList classrooms={child.classrooms} />

      <h3>Guardians</h3>
      {ability.can("add", "guardian") && (
        <>
          <Button
            onClick={() => {
              setModalMode("add");
              setIsGuardianModalOpen(true);
            }}
          >
            Add Guardians
          </Button>
          <Button
            onClick={() => {
              setModalMode("remove");
              setIsGuardianModalOpen(true);
            }}
            style={{ marginLeft: 8 }}
          >
            Remove Guardians
          </Button>
        </>
      )}
      <GuardianList guardians={child.guardians} />

      {ability.can("add", "classroom") && (
        <ManageClassroomsModal
          childId={childId}
          isOpen={isClassroomModalOpen}
          onClose={() => setIsClassroomModalOpen(false)}
          onAdd={
            modalMode === "add" ? handleAddClassrooms : handleRemoveClassrooms
          }
          mode={modalMode}
          existingClassrooms={child.classrooms}
        />
      )}

      {ability.can("add", "guardian") && (
        <ManageGuardiansModal
          childId={childId}
          isOpen={isGuardianModalOpen}
          onClose={() => setIsGuardianModalOpen(false)}
          onAdd={
            modalMode === "add" ? handleAddGuardians : handleRemoveGuardians
          }
          mode={modalMode}
          existingGuardians={child.guardians}
        />
      )}
    </div>
  );
};

export default ChildPage;
