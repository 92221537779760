import React, { useState, useEffect } from "react";
import { Modal, Table, Input } from "antd";
import { getTeachersNotInClassroom } from "../api/SchoolAPI";

const { Search } = Input;

const ManageTeachersModal = ({
  classroomId,
  isOpen,
  onClose,
  onAdd,
  mode,
  existingTeachers,
}) => {
  const [teachers, setTeachers] = useState([]);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (isOpen) {
      const fetchTeachers = async () => {
        try {
          let teachers = [];
          if (mode === "add") {
            teachers = await getTeachersNotInClassroom(classroomId);
          } else {
            teachers = existingTeachers;
          }
          setTeachers(teachers);
        } catch (error) {
          console.error("Error fetching teachers:", error);
        }
      };
      fetchTeachers();
    }
  }, [isOpen, classroomId, mode, existingTeachers]);

  const handleAddTeachers = () => {
    onAdd(selectedTeachers);
    setSelectedTeachers([]);
  };

  const handleTeacherSelection = (selectedRowKeys) => {
    setSelectedTeachers(selectedRowKeys);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredTeachers = teachers.filter((teacher) =>
    teacher.first_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const teacherColumns = [
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Teacher ID",
      dataIndex: "eid",
      key: "eid",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectedTeachers,
    onChange: handleTeacherSelection,
  };

  return (
    <Modal
      title={
        mode === "add"
          ? "Add Teachers to Classroom"
          : "Remove Teachers from Classroom"
      }
      open={isOpen}
      onOk={handleAddTeachers}
      onCancel={onClose}
      okText={mode === "add" ? "Add to Classroom" : "Remove from Classroom"}
    >
      <Search
        placeholder="Search by name"
        allowClear
        onChange={handleSearch}
        style={{ marginBottom: 16 }}
      />
      <Table
        rowSelection={rowSelection}
        columns={teacherColumns}
        dataSource={filteredTeachers}
        rowKey="teacher_id"
        pagination={{ pageSize: 10 }}
      />
    </Modal>
  );
};

export default ManageTeachersModal;
