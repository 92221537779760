import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Table, Drawer, Button, Input, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import GuardianEditForm from "./GuardianEditForm";
import { useAbility } from "../hooks/useAbility";
import { createGuardian, updateGuardian } from "../api/GuardianAPI";
import { getFullName } from "../helpers/childHelper";
import { formatPhoneNumber } from "../helpers/formatHelper";

const { Search } = Input;

const GuardianList = ({
  guardians,
  refreshGuardians,
  showNewButton = false,
  showSearchBar = false,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedGuardian, setSelectedGuardian] = useState(null);

  const ability = useAbility();

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredGuardians = guardians.filter((guardian) =>
    guardian.first_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleRowClick = (record) => {
    if (ability.can("update", "guardian")) {
      setSelectedGuardian(record);
      setIsDrawerOpen(true);
    }
  };

  const handleNewGuardian = () => {
    setSelectedGuardian({});
    setIsDrawerOpen(true);
  };

  const handleFormFinish = (guardianData) => {
    if (selectedGuardian && selectedGuardian.id) {
      return updateGuardian(selectedGuardian.guardian_id, guardianData).then(
        () => {
          setIsDrawerOpen(false);
          refreshGuardians();
        }
      );
    } else {
      return createGuardian(guardianData).then(() => {
        setIsDrawerOpen(false);
        refreshGuardians();
      });
    }
  };

  const columns = [
    {
      key: "sl_no",
      className: "fixed-column",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record) => (
        <Link to={`/guardians/${record.guardian_id}`}>
          {getFullName(record)}
        </Link>
      ),
    },
    {
      title: "Relationship",
      dataIndex: "relationship",
      key: "relationship",
    },
    {
      title: "Phone",
      dataIndex: "phone_number",
      render: (text, record) => (
        <div>
          <a href={`tel:${record.phone_number}`}>
            {formatPhoneNumber(record.phone_number)}
          </a>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];

  return (
    <div className="table-view">
      <Row
        gutter={16}
        align="middle"
        className="controls"
        style={{ marginBottom: 16 }}
      >
        {showNewButton && ability.can("create", "guardian") && (
          <Col>
            <Button
              type="default"
              onClick={handleNewGuardian}
              icon={<PlusOutlined />}
            />
          </Col>
        )}
        {showSearchBar && (
          <Col flex="auto">
            <Search
              placeholder="Search by name"
              allowClear
              onChange={handleSearch}
            />
          </Col>
        )}
      </Row>
      <Table
        className="custom-table"
        columns={columns}
        dataSource={filteredGuardians}
        rowKey="guardian_id"
        rowClassName={(record, index) =>
          index % 2 === 0 ? "row-even" : "row-odd"
        }
        onRow={(record) => {
          return {
            onClick: () => {
              handleRowClick(record);
            },
          };
        }}
        pagination={filteredGuardians.length > 10 ? { pageSize: 10 } : false}
        scroll={{ x: "max-content" }}
      />

      <Drawer
        width={350}
        onClose={() => {
          setIsDrawerOpen(false);
          setSelectedGuardian(null);
        }}
        open={isDrawerOpen}
        closable={false}
      >
        {isDrawerOpen && (
          <GuardianEditForm
            guardian={selectedGuardian}
            onFinish={handleFormFinish}
            refreshGuardians={refreshGuardians}
            title={
              selectedGuardian && selectedGuardian.id
                ? "Edit Guardian"
                : "New Guardian"
            }
          />
        )}
      </Drawer>
    </div>
  );
};

export default GuardianList;
