import axios from "axios";

const baseURL = "/library/authors";

const api = axios.create({
  baseURL,
});

export const createAuthor = async (authorData) => {
    try {
      const response = await api.post("/", authorData);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  export const getAuthors = async () => {
    try {
      const response = await api.get("/");
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  export const getAuthorById = async (authorId = "") => {
    try {
      const response = await api.get(`/${authorId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  export const updateAuthor = async (authorId, authorData) => {
    try {
      const response = await api.put(`/${authorId}`, authorData);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

    // Map a Book to an Author
    export const mapBooksToAuthor = async (authorId, bookId) => {
    try {
      const response = await api.post(
        `/${authorId}/books/${bookId}/map`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  // Unmap a Book from an Author
  export const unmapBooksToAuthor = async (authorId, bookId) => {
    try {
      const response = await api.delete(
        `/${authorId}/books/${bookId}/unmap`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  // Get Books mapped to an Author
  export const getBooksByAuthor = async (authorId) => {
    try {
      const response = await api.get(`/${authorId}/books`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  // Get Books not mapped to an Author
  export const getBooksNotByAuthor = async (authorId) => {
    try {
      const response = await api.get(
        `/${authorId}/books/not-mapped`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  