// client/src/components/NoClassroomMessage.js
import React from "react";
import { Helmet } from "react-helmet-async";

const NoClassroomMessage = ({ title }) => {
  const messageStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100px",
    fontSize: "18px",
    textAlign: "center",
    lineHeight: "1.5",
  };

  return (
    <div>
      <Helmet>
        <title>{title} | Maidan</title>
      </Helmet>
      <div style={messageStyle}>
        <div>No classroom has been assigned to you.</div>
        <div>Please contact your school admin.</div>
      </div>
    </div>
  );
};

export default NoClassroomMessage;
