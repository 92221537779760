import React, { useState, useEffect } from "react";
import { DatePicker } from "antd";
import { getGuardAttendanceReportByMonth } from "../../api/GuardAPI";
import "../../css/guards/GuardAttendanceMonthlyReport.css";
import moment from "moment";

const GuardAttendanceMonthlyReport = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(
    moment().format("YYYY-MM")
  );
  const [daysInMonth, setDaysInMonth] = useState([]);

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const data = await getGuardAttendanceReportByMonth(selectedMonth);
        setAttendanceData(data);
      } catch (error) {
        console.error("Error fetching monthly attendance report:", error);
      }
    };
    fetchReport();
  }, [selectedMonth]);

  useEffect(() => {
    const days = Array.from(
      { length: moment(selectedMonth, "YYYY-MM").daysInMonth() },
      (_, i) => i + 1
    );
    setDaysInMonth(days);
  }, [selectedMonth]);

  const handleMonthChange = (date, dateString) => {
    setSelectedMonth(dateString);
  };

  return (
    <div className="attendance-monthly-report-container">
      <h2>
        Attendance Report for{" "}
        {moment(selectedMonth, "YYYY-MM").format("MMMM YYYY")}
      </h2>
      <div className="report-controls">
        <DatePicker
          picker="month"
          value={moment(selectedMonth, "YYYY-MM")}
          onChange={handleMonthChange}
        />
      </div>
      <div className="table-container">
        <table className="attendance-table">
          <thead>
            <tr>
              <th>Day</th>
              {attendanceData.map((guard) => (
                <th key={guard.guard_id}>{guard.guard_name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* Summary Row */}
            <tr>
              <td>Summary</td>
              {attendanceData.map((guard) => (
                <td key={guard.guard_id}>
                  Present: {guard.present_percent}%
                  <br />
                  Late: {guard.late_percent}%
                </td>
              ))}
            </tr>
            {/* Daily Attendance Rows */}
            {daysInMonth.map((day) => (
              <tr key={day}>
                <td>{day}</td>
                {attendanceData.map((guard) => {
                  const dailyAttendance = guard.daily_attendance.find(
                    (attendance) => moment(attendance.day).date() === day
                  );
                  return (
                    <td key={guard.guard_id}>
                      Present: {dailyAttendance?.present_percent || 0}%
                      <br />
                      Late: {dailyAttendance?.late_percent || 0}%
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GuardAttendanceMonthlyReport;
