import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import BookList from "../../components/library/BookList"; // Ensure this component is created to display books
import ManageBookAuthorModal from "../../components/library/ManageBookAuthorModal"; // Ensure this component is created as per previous instructions
import {
  getAuthorById,
  getBooksByAuthor,
  mapBooksToAuthor,
  unmapBooksToAuthor,
} from "../../api/library/AuthorAPI";
import { Helmet } from "react-helmet-async";

const AuthorPage = () => {
  const { authorId } = useParams();
  const [author, setAuthor] = useState(null);
  const [books, setBooks] = useState([]);
  const [isBooksModalVisible, setIsBooksModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState("add");

  useEffect(() => {
    const fetchAuthorAndBooks = async () => {
      try {
        // Fetch author details
        const authorData = await getAuthorById(authorId);
        setAuthor(authorData);

        // Fetch books associated with the author
        const booksData = await getBooksByAuthor(authorId);
        setBooks(booksData);
      } catch (error) {
        console.error("Error fetching author and books:", error);
      }
    };
    fetchAuthorAndBooks();
  }, [authorId]);

  const refreshAuthor = useCallback(async () => {
    try {
      // Fetch updated author details
      const authorData = await getAuthorById(authorId);
      setAuthor(authorData);

      // Fetch updated books associated with the author
      const booksData = await getBooksByAuthor(authorId);
      setBooks(booksData);
    } catch (error) {
      console.error("Error refreshing author and books:", error);
    }
  }, [authorId]);

  useEffect(() => {
    refreshAuthor();
  }, [refreshAuthor]);

  const handleAddBooks = async (bookIds) => {
    try {
      for (const bookId of bookIds) {
        await mapBooksToAuthor(bookId, authorId);
      }
      setIsBooksModalVisible(false);
      refreshAuthor();
    } catch (error) {
      console.error("Error adding books to author:", error);
    }
  };

  const handleRemoveBooks = async (bookIds) => {
    try {
      for (const bookId of bookIds) {
        await unmapBooksToAuthor(bookId, authorId);
      }
      setIsBooksModalVisible(false);
      refreshAuthor();
    } catch (error) {
      console.error("Error removing books from author:", error);
    }
  };

  if (!author) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Helmet>
        <title>{author.first_name} | Author</title>
      </Helmet>
      <h2>{author.first_name} {author.last_name}</h2>

      <h3 style={{marginLeft:8}}>Books</h3>
      <Button
        onClick={() => {
          setModalMode("add");
          setIsBooksModalVisible(true);
        }}
        style={{marginLeft:8}}
      >
        Add Books
      </Button>
      <Button
        onClick={() => {
          setModalMode("remove");
          setIsBooksModalVisible(true);
        }}
        style={{ marginLeft: 8 }}
      >
        Remove Books
      </Button>
      <BookList books={books} /> {/* Display the list of books associated with the author */}

      <ManageBookAuthorModal
        authorId={authorId}
        isVisible={isBooksModalVisible}
        onClose={() => setIsBooksModalVisible(false)}
        onAdd={modalMode === "add" ? handleAddBooks : handleRemoveBooks}
        mode={modalMode}
        existingBooks={books}
      />
    </div>
  );
};

export default AuthorPage;
