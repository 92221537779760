// client/src/components/OnboardingChecklist.js
import React from "react";
import { Table, Progress } from "antd";
import { Link } from "react-router-dom";
import {
  CheckCircleFilled,
  QuestionCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { snakeCaseToSentenceCase } from "../helpers/formatHelper";
import "../css/OnboardingChecklist.css";

const OnboardingChecklist = ({ checklist }) => {
  const dataSource = Object.entries(checklist).map(([key, value]) => ({
    key,
    sequence: value.sequence,
    item: snakeCaseToSentenceCase(key),
    status: value.completed,
    action: value.action,
  }));

  // Sort data by sequence
  dataSource.sort((a, b) => a.sequence - b.sequence);

  // Calculate the percentage of completed items
  const totalItems = dataSource.length;
  const completedItems = dataSource.filter((item) => item.status).length;
  const completionPercentage = Math.round((completedItems / totalItems) * 100);

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      className: "status-column",
      render: (completed) =>
        completed ? (
          <CheckCircleFilled className="status-icon completed" />
        ) : (
          <QuestionCircleOutlined className="status-icon pending" />
        ),
    },
    {
      title: "Checklist Item",
      dataIndex: "item",
      key: "item",
      render: (text, record) => (
        <Link
          to={record.action}
          className={`checklist-item ${record.status ? "completed" : ""}`}
        >
          <span>{text}</span>
          <RightOutlined style={{ marginLeft: "8px" }} />
        </Link>
      ),
    },
  ];

  return (
    <div>
      <h2>Onboarding Checklist</h2>
      <Progress
        percent={completionPercentage}
        status="active"
        className="onboarding-progress-bar"
      />

      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        rowKey="key"
        showHeader={false}
        className="onboarding-table"
      />
    </div>
  );
};

export default OnboardingChecklist;
