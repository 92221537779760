import axios from "axios";

const baseURL = "/import";

export const importChildren = (fileData) => {
  const formData = new FormData();
  formData.append("file", fileData);

  return axios
    .post(`${baseURL}/children`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error importing children:", error);
      throw error;
    });
};

export const importEmployees = (fileData) => {
  const formData = new FormData();
  formData.append("file", fileData);

  return axios
    .post(`${baseURL}/employees`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error importing employees:", error);
      throw error;
    });
};

export const importConcepts = (fileData) => {
  const formData = new FormData();
  formData.append("file", fileData);

  return axios
    .post(`${baseURL}/concepts`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error importing concepts:", error);
      throw error;
    });
};

export const importBooks = (fileData) => {
  const formData = new FormData();
  formData.append("file", fileData);

  return axios
    .post(`${baseURL}/books`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error importing books:", error);
      throw error;
    });
};