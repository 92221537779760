import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { getAuthors } from "../../api/library/AuthorAPI"; // Import the API call for fetching authors

const { Option } = Select;

const AuthorsDropdown = ({ value = [], onChange }) => {
  const [authors, setAuthors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const fetchAuthors = async () => {
      setLoading(true);
      try {
        const authorsList = await getAuthors(); // Fetch authors from the API
        setAuthors(authorsList);
      } catch (error) {
        console.error("Error fetching authors:", error);
      }
      setLoading(false);
    };

    fetchAuthors();
  }, []);

  const handleSearch = (searchText) => {
    setInputValue(searchText);
  };

  const handleSelect = (selectedValue) => {
    const collectionExists = authors.some((author) => author.id === selectedValue);

    if (!collectionExists) {
      // Split the input value into first and last name
      const [first_name, last_name] = inputValue.split(" ").length > 1
        ? inputValue.split(" ")
        : [inputValue, ""]; // If there's no last name, keep it empty
      
      const newAuthor = { id: selectedValue, first_name, last_name };
      setAuthors((prevAuthors) => [...prevAuthors, newAuthor]);
    }

    onChange([...value, selectedValue]); // Spread the current value array and add the new value
  };

  return (
    <Select
      mode="multiple" // Allow multiple selections
      value={value}
      onChange={onChange}
      onSearch={handleSearch}
      onSelect={handleSelect}
      showSearch
      placeholder="Select Authors"
      loading={loading}
      optionLabelProp="label"
      filterOption={(input, option) =>
        option.label.toLowerCase().includes(input.toLowerCase())
      }
    >
      {authors.map((author) => (
        <Option key={author.id} value={author.id} label={author.last_name ?`${author.first_name} ${author.last_name}`:`${author.first_name}`}>
          {author.first_name} {author.last_name}
        </Option>
      ))}
      {/* Allow user to add a new option dynamically */}
      {inputValue && !authors.some((author) => `${author.first_name} ${author.last_name}` === inputValue) && (
        <Option key={inputValue} value={inputValue} label={`Add "${inputValue}"`}>
          {`Add "${inputValue}"`}
        </Option>
      )}
    </Select>
  );
};

export default AuthorsDropdown;
