import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  Drawer,
  Button,
  Input,
  Row,
  Col,
  Dropdown,
  Menu,
  Modal,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import DomainEditForm from "./DomainEditForm";
import ImportConcepts from "./ImportConcepts";
import { createDomain, updateDomain } from "../api/DomainAPI";
import { useAbility } from "../hooks/useAbility";

const { Search } = Input;

const DomainList = ({
  domains = [],
  refreshDomains,
  showNewButton = false,
  showSearchBar = false,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const ability = useAbility();

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredDomains = Array.isArray(domains)
    ? domains.filter((domain) =>
        domain.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  const handleRowClick = (record) => {
    if (ability.can("update", "domain")) {
      setSelectedDomain(record);
      setIsDrawerOpen(true);
    }
  };

  const handleNewDomain = () => {
    setSelectedDomain({});
    setIsDrawerOpen(true);
  };

  const handleFormFinish = (domainData) => {
    if (selectedDomain && selectedDomain.id) {
      return updateDomain(selectedDomain.id, domainData).then(() => {
        setIsDrawerOpen(false);
        refreshDomains();
      });
    } else {
      return createDomain(domainData).then(() => {
        setIsDrawerOpen(false);
        refreshDomains();
      });
    }
  };

  // Dropdown menu items for the Plus button
  const menu = (
    <Menu>
      <Menu.Item key="new-domain" onClick={handleNewDomain}>
        New Domain
      </Menu.Item>
      <Menu.Item key="import-concepts" onClick={() => setIsModalOpen(true)}>
        Import
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      key: "sl_no",
      className: "fixed-column",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Domain Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`/record-keeper-settings/domains/${record.id}`}>{text}</Link>
      ),
    },
  ];

  return (
    <div className="table-view">
      <Row
        gutter={16}
        align="middle"
        className="controls"
        style={{ marginBottom: 16 }}
      >
        {showNewButton && ability.can("create", "domain") && (
          <Col>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button type="default" icon={<PlusOutlined />}></Button>
            </Dropdown>
          </Col>
        )}
        {showSearchBar && (
          <Col flex="auto">
            <Search
              placeholder="Search by name"
              allowClear
              onChange={handleSearch}
            />
          </Col>
        )}
      </Row>
      <Table
        className="custom-table"
        columns={columns}
        dataSource={filteredDomains}
        rowKey="id"
        rowClassName={(record, index) =>
          index % 2 === 0 ? "row-even" : "row-odd"
        }
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
        pagination={filteredDomains.length > 10 ? { pageSize: 10 } : false}
      />
      {ability.can("update", "domain") && (
        <Drawer
          width={350}
          onClose={() => {
            setIsDrawerOpen(false);
            setSelectedDomain(null);
          }}
          open={isDrawerOpen}
          closable={false}
        >
          {isDrawerOpen && (
            <DomainEditForm
              domain={selectedDomain}
              onFinish={handleFormFinish}
              refreshDomains={refreshDomains}
              title={
                selectedDomain && selectedDomain.id
                  ? "Edit Domain"
                  : "New Domain"
              }
            />
          )}
        </Drawer>
      )}

      <Modal
        title="Import Concepts"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <ImportConcepts />
      </Modal>
    </div>
  );
};

export default DomainList;
