// src/api/MasteryLevelAPI.js

import axios from "axios";

const baseURL = "/mastery-levels";

const api = axios.create({
  baseURL,
});

export const getMasteryLevels = async () => {
  try {
    const response = await api.get("/");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createMasteryLevel = async (masteryLevelData) => {
  try {
    const response = await api.post("/", masteryLevelData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateMasteryLevel = async (masteryLevelId, masteryLevelData) => {
  try {
    const response = await api.put(`/${masteryLevelId}`, masteryLevelData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getChildrenInClassroom = async (classroomId) => {
  try {
    const response = await api.get(`/classrooms/${classroomId}/children`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
