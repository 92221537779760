import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import BookList from "../../components/library/BookList"; // Ensure this component is created to display books
import ManageBookCollectionModal from "../../components/library/ManageBookCollectionModal"; // Ensure this component is created as per previous instructions
import {
  getCollectionById,
  getBooksByCollection,
  mapBooksToCollection,
  unmapBooksToCollection,
} from "../../api/library/CollectionAPI";
import { Helmet } from "react-helmet-async";

const CollectionPage = () => {
  const { collectionId } = useParams();
  const [collection, setCollection] = useState(null);
  const [books, setBooks] = useState([]);
  const [isBooksModalVisible, setIsBooksModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState("add");

  useEffect(() => {
    const fetchCollectionAndBooks = async () => {
      try {
        // Fetch collection details
        const collectionData = await getCollectionById(collectionId);
        setCollection(collectionData);

        // Fetch books associated with the collection
        const booksData = await getBooksByCollection(collectionId);
        setBooks(booksData);
      } catch (error) {
        console.error("Error fetching collection and books:", error);
      }
    };
    fetchCollectionAndBooks();
  }, [collectionId]);

  const refreshCollection = useCallback(async () => {
    try {
      // Fetch updated collection details
      const collectionData = await getCollectionById(collectionId);
      setCollection(collectionData);

      // Fetch updated books associated with the collection
      const booksData = await getBooksByCollection(collectionId);
      setBooks(booksData);
    } catch (error) {
      console.error("Error refreshing collection and books:", error);
    }
  }, [collectionId]);

  useEffect(() => {
    refreshCollection();
  }, [refreshCollection]);

  const handleAddBooks = async (bookIds) => {
    try {
      for (const bookId of bookIds) {
        await mapBooksToCollection(bookId, collectionId);
      }
      setIsBooksModalVisible(false);
      refreshCollection();
    } catch (error) {
      console.error("Error adding books to collection:", error);
    }
  };

  const handleRemoveBooks = async (bookIds) => {
    try {
      for (const bookId of bookIds) {
        await unmapBooksToCollection(bookId, collectionId);
      }
      setIsBooksModalVisible(false);
      refreshCollection();
    } catch (error) {
      console.error("Error removing books from collection:", error);
    }
  };

  if (!collection) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Helmet>
        <title>{collection.name} | Collection</title>
      </Helmet>
      <h2>{collection.name} </h2>

      <h3 style={{marginLeft:8}}>Books</h3>
      <Button
        onClick={() => {
          setModalMode("add");
          setIsBooksModalVisible(true);
        }}
        style={{marginLeft:8}}
      >
        Add Books
      </Button>
      <Button
        onClick={() => {
          setModalMode("remove");
          setIsBooksModalVisible(true);
        }}
        style={{ marginLeft: 8 }}
      >
        Remove Books
      </Button>
      <BookList books={books} /> {/* Display the list of books associated with the collection */}

      <ManageBookCollectionModal
        collectionId={collectionId}
        isVisible={isBooksModalVisible}
        onClose={() => setIsBooksModalVisible(false)}
        onAdd={modalMode === "add" ? handleAddBooks : handleRemoveBooks}
        mode={modalMode}
        existingBooks={books}
      />
    </div>
  );
};

export default CollectionPage;
