import React, { useState, useEffect } from "react";
import { Modal, Table, Input } from "antd";
import { getCategoriesNotByBook } from "../../api/library/BookAPI"; 

const { Search } = Input;

const ManageCategoryBookModal = ({
  bookId,
  isVisible,
  onClose,
  onAdd,
  mode,
  existingCategories,
}) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (isVisible) {
      const fetchCategories = async () => {
        try {
          let categories = [];
          if (mode === "add") {
            categories = await getCategoriesNotByBook(bookId);  // Fetch categories not mapped to book
          } else {
            categories = existingCategories;  // Use existing categories mapped to the book
          }
          setCategories(categories);
        } catch (error) {
          console.error("Error fetching categories:", error);
        }
      };
      fetchCategories();
    }
  }, [isVisible, bookId, mode, existingCategories]);

  const handleAddCategories = () => {
    onAdd(selectedCategories);  // Trigger the function to handle category-book mapping
    setSelectedCategories([]);
  };

  const handleCategorySelection = (selectedRowKeys) => {
    setSelectedCategories(selectedRowKeys);  // Update selected categories
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);  // Set the search query for filtering categories
  };

  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(searchQuery.toLowerCase())  // Filter categories by name
  );

  const categoryColumns = [
    {
      title: "Category Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Category ID",
      dataIndex: "id",
      key: "id",
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectedCategories,
    onChange: handleCategorySelection,
  };

  return (
    <Modal
      title={
        mode === "add"
          ? "Add Categories to Book"
          : "Remove Categories from Book"
      }
      visible={isVisible}
      onOk={handleAddCategories}
      onCancel={onClose}
      okText={mode === "add" ? "Add to Book" : "Remove from Book"}
    >
      <Search
        placeholder="Search by category name"
        allowClear
        onChange={handleSearch}
        style={{ marginBottom: 16 }}
      />
      <Table
        rowSelection={rowSelection}
        columns={categoryColumns}
        dataSource={filteredCategories}
        rowKey="id"
        pagination={{ pageSize: 10 }}
      />
    </Modal>
  );
};

export default ManageCategoryBookModal;
