import { message } from "antd";
import { getMasteryLevels } from "../api/MasteryLevelAPI";
import {
  getMasteryLevelsByClassroomAndDomain,
  upsertChildMastery,
} from "../api/RecordKeeperAPI";
import { getDomains } from "../api/DomainAPI";
import { getClassrooms } from "../api/SchoolAPI";

export const fetchInitialData = async (setData) => {
  try {
    const [masteryLevelsResponse, domainsResponse, classroomsResponse] =
      await Promise.all([getMasteryLevels(), getDomains(), getClassrooms()]);
    setData((prevData) => ({
      ...prevData,
      masteryLevels: masteryLevelsResponse || [],
      domains: domainsResponse || [],
      classrooms: classroomsResponse || [],
    }));
  } catch (error) {
    console.error("Error fetching initial data:", error);
  }
};

export const fetchMasteryLevels = async (filters, setData) => {
  if (filters.selectedClassroom && filters.selectedDomain) {
    try {
      const response = await getMasteryLevelsByClassroomAndDomain(
        filters.selectedClassroom,
        filters.selectedDomain
      );

      const { concepts, children } = response;

      setData((prevData) => ({
        ...prevData,
        concepts,
        children,
      }));
    } catch (error) {
      console.error("Error fetching mastery levels:", error);
    }
  } else {
    setData((prevData) => ({
      ...prevData,
      concepts: {},
      children: {},
    }));
  }
};

export const handleCellChange = async (
  value,
  conceptId,
  childId,
  firstName,
  selectedDate,
  filters,
  setData
) => {
  try {
    const masteryLevelId = value === "" || value === "0" ? null : value;
    const date = selectedDate ? selectedDate.format("YYYY-MM-DD") : null;
    await upsertChildMastery(childId, conceptId, masteryLevelId, date);
    message.success("Mastery level updated successfully for " + firstName);

    if (filters.selectedClassroom && filters.selectedDomain) {
      const response = await getMasteryLevelsByClassroomAndDomain(
        filters.selectedClassroom,
        filters.selectedDomain
      );

      const { children } = response;

      setData((prevData) => ({
        ...prevData,
        children,
      }));
    }
  } catch (error) {
    message.error("Failed to update mastery level");
  }
};

export const handleKeyDown = (
  e,
  rowIndex,
  colIndex,
  sortedConcepts,
  sortedChildren,
  inputsRef,
  setFocusedCell
) => {
  let newRow = rowIndex;
  let newCol = colIndex;

  const moveCell = (row, col) => {
    const newCellRef = inputsRef.current[`cell-${row}-${col}`]?.current;
    if (newCellRef) {
      newCellRef.focus();
      setTimeout(() => newCellRef.select(), 0);
    }
    setFocusedCell(`cell-${row}-${col}`);
  };

  switch (e.key) {
    case "ArrowUp":
      newRow = rowIndex > 0 ? rowIndex - 1 : rowIndex;
      while (newRow > 0 && sortedConcepts[newRow].is_root) {
        newRow--;
      }
      break;
    case "ArrowDown":
      newRow = rowIndex < sortedConcepts.length - 1 ? rowIndex + 1 : rowIndex;
      while (newRow < sortedConcepts.length && sortedConcepts[newRow].is_root) {
        newRow++;
      }
      break;
    case "ArrowLeft":
      if (e.ctrlKey || e.metaKey) {
        newCol = 0;
      } else {
        newCol = colIndex > 0 ? colIndex - 1 : colIndex;
      }
      break;
    case "ArrowRight":
    case "Tab":
      if (e.ctrlKey || e.metaKey) {
        newCol = sortedChildren.length - 1;
      } else {
        newCol = colIndex < sortedChildren.length - 1 ? colIndex + 1 : colIndex;
      }
      break;
    default:
      break;
  }

  moveCell(newRow, newCol);
};
