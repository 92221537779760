import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import GuardianList from "../components/GuardianList";
import LoadingSpinner from "../components/LoadingSpinner";
import { getGuardians } from "../api/GuardianAPI";

const GuardiansPage = () => {
  const [guardians, setGuardians] = useState([]);
  const [loading, setLoading] = useState(true);

  const refreshGuardians = async () => {
    try {
      const data = await getGuardians();
      setGuardians(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refreshGuardians();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <Helmet>
        <title>Guardians | Maidan</title>
      </Helmet>

      <h1 className="page-heading">Guardians</h1>

      <GuardianList
        guardians={guardians}
        refreshGuardians={refreshGuardians}
        showNewButton={true}
        showSearchBar={true}
      />
    </div>
  );
};

export default GuardiansPage;
