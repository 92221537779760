// src/api/RecordKeeperAPI.js

import axios from "axios";

const baseURL = "/record-keeper";

const api = axios.create({
  baseURL,
});

export const getMyPlannedActivities = async () => {
  try {
    const response = await api.get("/my-planned-activities");
    return response.data;
  } catch (error) {
    console.error("Error fetching planned activities:", error);
    throw error;
  }
};

export const getMasteryLevelsByClassroomAndDomain = async (
  classroomId,
  domainId
) => {
  try {
    const response = await api.get(
      `/classroom/${classroomId}/domain/${domainId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const upsertChildMastery = async (
  childId,
  conceptId,
  masteryLevelId,
  date
) => {
  try {
    const response = await api.post("/upsert", {
      childId,
      conceptId,
      masteryLevelId,
      date,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const createNote = async (noteData) => {
  try {
    const response = await api.post("/notes", noteData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateNote = async (noteId, noteData) => {
  try {
    const response = await api.put(`/notes/${noteId}`, noteData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteNote = async (noteId) => {
  try {
    const response = await api.delete(`/notes/${noteId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getNotes = async (childId, conceptId) => {
  try {
    const response = await api.get(`/notes`, {
      params: {
        childId,
        conceptId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
