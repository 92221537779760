import React, { useState, useEffect } from "react";
import { Modal, Table, Input } from "antd";
import { getAuthorsNotByBook } from "../../api/library/BookAPI"; 

const { Search } = Input;

const ManageAuthorBookModal = ({
  bookId,
  isVisible,
  onClose,
  onAdd,
  mode,
  existingAuthors,
}) => {
  const [authors, setAuthors] = useState([]);
  const [selectedAuthors, setSelectedAuthors] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (isVisible) {
      const fetchAuthors = async () => {
        try {
          let authors = [];
          if (mode === "add") {
            authors = await getAuthorsNotByBook(bookId);  // Fetch authors not mapped to book
          } else {
            authors = existingAuthors;  // Use existing authors mapped to the book
          }
          setAuthors(authors);
        } catch (error) {
          console.error("Error fetching authors:", error);
        }
      };
      fetchAuthors();
    }
  }, [isVisible, bookId, mode, existingAuthors]);

  const handleAddAuthors = () => {
    onAdd(selectedAuthors);  // Trigger the function to handle author-book mapping
    setSelectedAuthors([]);
  };

  const handleAuthorSelection = (selectedRowKeys) => {
    setSelectedAuthors(selectedRowKeys);  // Update selected authors
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);  // Set the search query for filtering authors
  };

  const filteredAuthors = authors.filter((author) =>
  author.first_name.toLowerCase().includes(searchQuery.toLowerCase())  // Filter authors by name
  );

  const authorColumns = [
    {
        title: "Author ID",
        dataIndex: "id",
        key: "id",
    },
    {
      title: "Author Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
        title: "Author Name",
        dataIndex: "last_name",
        key: "last_name",
    }
  ];

  const rowSelection = {
    selectedRowKeys: selectedAuthors,
    onChange: handleAuthorSelection,
  };

  return (
    <Modal
      title={
        mode === "add"
          ? "Add Authors to Book"
          : "Remove Authors from Book"
      }
      visible={isVisible}
      onOk={handleAddAuthors}
      onCancel={onClose}
      okText={mode === "add" ? "Add to Book" : "Remove from Book"}
    >
      <Search
        placeholder="Search by author name"
        allowClear
        onChange={handleSearch}
        style={{ marginBottom: 16 }}
      />
      <Table
        rowSelection={rowSelection}
        columns={authorColumns}
        dataSource={filteredAuthors}
        rowKey="id"
        pagination={{ pageSize: 10 }}
      />
    </Modal>
  );
};

export default ManageAuthorBookModal;
