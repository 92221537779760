import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Table, Button, message } from "antd";
import LoadingSpinner from "../components/LoadingSpinner";
import { acceptInvitation, rejectInvitation } from "../api/MembershipsAPI";

const MembershipsPage = ({ memberships }) => {
  const [invitations, setInvitations] = useState(
    memberships.filter((membership) => membership.user_id === null)
  );
  const [loading, setLoading] = useState(false);

  const handleAccept = async (id) => {
    setLoading(true);
    try {
      await acceptInvitation(id);
      message.success("Invitation accepted successfully!");
      setInvitations((prevInvitations) =>
        prevInvitations.filter((invitation) => invitation.id !== id)
      );
    } catch (error) {
      console.error("Error accepting invitation:", error);
      message.error("Failed to accept invitation.");
    } finally {
      setLoading(false);
    }
  };

  const handleReject = async (id) => {
    setLoading(true);
    try {
      await rejectInvitation(id);
      message.success("Invitation rejected successfully!");
      setInvitations((prevInvitations) =>
        prevInvitations.filter((invitation) => invitation.id !== id)
      );
    } catch (error) {
      console.error("Error rejecting invitation:", error);
      message.error("Failed to reject invitation.");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "School Name",
      dataIndex: "school_name",
      key: "school_name",
    },
    {
      title: "Invitation Sent On",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Button type="primary" onClick={() => handleAccept(record.id)}>
            Accept
          </Button>
          <Button
            type="danger"
            onClick={() => handleReject(record.id)}
            style={{ marginLeft: 10 }}
          >
            Reject
          </Button>
        </>
      ),
    },
  ];

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <Helmet>
        <title>My Memberships | Maidan</title>
      </Helmet>

      <h1 className="page-heading">My Memberships</h1>

      <Table
        columns={columns}
        dataSource={invitations}
        rowKey="id"
        pagination={false}
      />
    </div>
  );
};

export default MembershipsPage;
