import React, { useState, useEffect } from "react";
import { Table, Button, Drawer, Input, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import MasteryLevelEditForm from "./MasteryLevelEditForm";
import {
  getMasteryLevels,
  createMasteryLevel,
  updateMasteryLevel,
} from "../api/MasteryLevelAPI";
import { useAbility } from "../hooks/useAbility";

const { Search } = Input;

const MasteryLevelList = () => {
  const [masteryLevels, setMasteryLevels] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedMasteryLevel, setSelectedMasteryLevel] = useState(null);

  const ability = useAbility();

  useEffect(() => {
    refreshMasteryLevels();
  }, []);

  const refreshMasteryLevels = async () => {
    try {
      const data = await getMasteryLevels();
      setMasteryLevels(data);
    } catch (error) {
      console.error("Error fetching mastery levels:", error);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredMasteryLevels = Array.isArray(masteryLevels)
    ? masteryLevels.filter((level) =>
        level.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  const handleRowClick = (record) => {
    if (ability.can("update", "masteryLevel")) {
      setSelectedMasteryLevel(record);
      setIsDrawerOpen(true);
    }
  };

  const handleNewMasteryLevel = () => {
    setSelectedMasteryLevel({});
    setIsDrawerOpen(true);
  };

  const handleFormFinish = (levelData) => {
    if (selectedMasteryLevel && selectedMasteryLevel.id) {
      return updateMasteryLevel(selectedMasteryLevel.id, levelData).then(() => {
        setIsDrawerOpen(false);
        refreshMasteryLevels();
      });
    } else {
      return createMasteryLevel(levelData).then(() => {
        setIsDrawerOpen(false);
        refreshMasteryLevels();
      });
    }
  };

  // Define the columns, excluding the "Actions" column initially
  const columns = [
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
      className: "fixed-column",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      render: (color) => (
        <div
          style={{
            width: 50,
            height: 20,
            backgroundColor: color,
            border: "1px solid #ccc",
          }}
        />
      ),
    },
  ];

  // Conditionally add the "Actions" column if the user has permission
  if (ability.can("update", "masteryLevel")) {
    columns.push({
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Button onClick={() => handleRowClick(record)}>Edit</Button>
      ),
    });
  }

  return (
    <div className="mastery-level-list">
      <Row
        gutter={16}
        align="middle"
        className="controls"
        style={{ marginBottom: 16 }}
      >
        {ability.can("create", "masteryLevel") && (
          <Col>
            <Button
              type="default"
              onClick={handleNewMasteryLevel}
              icon={<PlusOutlined />}
            ></Button>
          </Col>
        )}
        <Col flex="auto">
          <Search
            placeholder="Search by name"
            allowClear
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <Table
        className="custom-table"
        columns={columns}
        dataSource={filteredMasteryLevels}
        rowKey="id"
        pagination={
          filteredMasteryLevels.length > 10 ? { pageSize: 10 } : false
        }
      />
      <Drawer
        width={350}
        onClose={() => {
          setIsDrawerOpen(false);
          setSelectedMasteryLevel(null);
        }}
        open={isDrawerOpen}
        closable={false}
      >
        {isDrawerOpen && (
          <MasteryLevelEditForm
            masteryLevel={selectedMasteryLevel}
            onFinish={handleFormFinish}
            title={
              selectedMasteryLevel && selectedMasteryLevel.id
                ? "Edit Mastery Level"
                : "New Mastery Level"
            }
          />
        )}
      </Drawer>
    </div>
  );
};

export default MasteryLevelList;
