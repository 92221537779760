// client/src/components/EmployeeEditForm.js
import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Typography,
  Radio,
  Select,
  Alert,
  Row,
  Col,
} from "antd";
import { toast } from "react-toastify";
import moment from "moment";
import BloodGroupDropdown from "./BloodGroupDropdown";
import { getFullName } from "../helpers/childHelper";
import GuardDataForm from "./guards/GuardDataForm";
import ProfilePhotoUploader from "./ProfilePhotoUploader";
import { formatTimeToString } from "../helpers/dateTimeHelper";

const { TextArea } = Input;
const { Title } = Typography;
const { Option } = Select;

const EmployeeEditForm = ({
  employee = {},
  roles,
  onFinish,
  refreshEmployees,
  title,
}) => {
  const [role, setRole] = useState(employee.role);
  const [errorMessage, setErrorMessage] = useState("");

  const handleRoleChange = (value) => {
    setRole(value);
  };

  const handleFinish = (employeeData) => {
    const formattedData = {
      ...employeeData,
      shift_start: formatTimeToString(employeeData.shift_start),
      shift_end: formatTimeToString(employeeData.shift_end),
    };

    onFinish(formattedData)
      .then(() => {
        setErrorMessage("");
        refreshEmployees();
        toast.success(
          <>
            Employee <b>{getFullName(employeeData)}</b>{" "}
            {employee.id ? "updated" : "created"} successfully!
          </>
        );
      })
      .catch((error) => {
        setErrorMessage(error.message);
        toast.error(
          <>
            Failed to {employee.id ? "update" : "create"} employee{" "}
            <b>{getFullName(employeeData)}</b>.
          </>
        );
      });
  };

  const showGuardDataForm =
    role === "Guard" && (!employee.id || employee.role === "Guard");

  return (
    <>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "24px" }}
      >
        <ProfilePhotoUploader
          memberId={employee.id}
          initialPhotoUrl={employee.profile_photo_url}
          refreshProfile={refreshEmployees}
        />
        <Title level={2}>{title}</Title>
      </div>

      <Form
        initialValues={{
          ...employee,
          date_of_birth: employee.date_of_birth
            ? moment(employee.date_of_birth)
            : null,
          date_of_joining: employee.date_of_joining
            ? moment(employee.date_of_joining)
            : null,
          date_of_leaving: employee.date_of_leaving
            ? moment(employee.date_of_leaving)
            : null,
          role: employee.role || "Teacher",
          shift_start: employee.shift_start
            ? moment(employee.shift_start, "HH:mm")
            : null,
          shift_end: employee.shift_end
            ? moment(employee.shift_end, "HH:mm")
            : null,
        }}
        onFinish={handleFinish}
        layout="vertical"
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="eid"
              label="ID"
              rules={[
                { required: true, message: "Please input the employee ID." },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="role"
              label="Role"
              style={{ display: employee.id ? "none" : "block" }}
            >
              <Select onChange={handleRoleChange} value={role}>
                {roles
                  .filter(
                    (role) => !["Owner", "Child", "Guardian"].includes(role)
                  )
                  .sort()
                  .map((role) => (
                    <Option key={role} value={role}>
                      {role}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="first_name"
              label="First Name"
              rules={[
                { required: true, message: "Please input the first name." },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="last_name" label="Last Name">
              <Input />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { type: "email", message: "Please input a valid email." },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="phone_number"
              label="Phone Number"
              rules={[
                {
                  pattern: /^\d{10}$/,
                  message: "The phone number must be 10 digits.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="gender" label="Gender">
              <Radio.Group>
                <Radio value="Male">Male</Radio>
                <Radio value="Female">Female</Radio>
                <Radio value="Other">Other</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="date_of_birth" label="Date of Birth">
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="blood_group" label="Blood Group">
              <BloodGroupDropdown />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="current_address" label="Current Address">
              <TextArea rows={3} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="permanent_address" label="Permanent Address">
              <TextArea rows={3} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="date_of_joining" label="Date of Joining">
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="date_of_leaving" label="Date of Leaving">
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
        </Row>

        {showGuardDataForm && <GuardDataForm />}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {employee.id ? "Update Employee" : "Create Employee"}
          </Button>
        </Form.Item>

        {errorMessage && (
          <Alert
            message={errorMessage}
            type="error"
            showIcon
            style={{ marginTop: 16 }}
          />
        )}
      </Form>
    </>
  );
};

export default EmployeeEditForm;
