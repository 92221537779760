import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import ClassroomList from "../components/ClassroomList";
import LoadingSpinner from "../components/LoadingSpinner";
import { getClassrooms } from "../api/SchoolAPI";

const ClassroomsPage = () => {
  const [classrooms, setClassrooms] = useState([]);
  const [loading, setLoading] = useState(true);

  const refreshClassrooms = async () => {
    try {
      const data = await getClassrooms();
      setClassrooms(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refreshClassrooms();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <Helmet>
        <title>Classrooms | Maidan</title>
      </Helmet>

      <h1 className="page-heading">Classrooms</h1>

      <div>
        <ClassroomList
          classrooms={classrooms}
          refreshClassrooms={refreshClassrooms}
          showNewButton={true}
          showSearchBar={true}
        />
      </div>
    </div>
  );
};

export default ClassroomsPage;
