// src/components/LoadingSpinner.js

import React from "react";
import { Spin } from "antd";
import "../css/LoadingSpinner.css";

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner-container">
      <Spin size="large" />
    </div>
  );
};

export default LoadingSpinner;
