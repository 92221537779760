import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, Typography } from "antd";
import { getPossibleParents, getPossibleSequences } from "../api/DomainAPI";
import { toast } from "react-toastify";

const { TextArea } = Input;
const { Option } = Select;
const { Title } = Typography;

const ConceptEditForm = ({
  domainId,
  concept = {},
  onFinish,
  refreshConcepts,
  title,
}) => {
  const [possibleParents, setPossibleParents] = useState([]);
  const [possibleSequences, setPossibleSequences] = useState([]);
  const [parentId, setParentId] = useState(concept.parent_id || "");

  useEffect(() => {
    const fetchPossibleParents = async () => {
      try {
        const parents = await getPossibleParents(domainId, concept.id || 0);
        setPossibleParents(parents);
      } catch (error) {
        console.error("Error fetching possible parents:", error);
      }
    };

    fetchPossibleParents();
  }, [domainId, concept.id]);

  useEffect(() => {
    const fetchPossibleSequences = async () => {
      try {
        const sequences = await getPossibleSequences(
          domainId,
          parentId !== "" ? parentId : "null",
          !concept.id // Pass true if creating a new concept, false if updating
        );
        setPossibleSequences(sequences);
      } catch (error) {
        console.error("Error fetching possible sequences:", error);
      }
    };

    fetchPossibleSequences();
  }, [domainId, parentId, concept.id]);

  const handleFinish = (conceptData) => {
    const dataWithDomainId = { ...conceptData, domain_id: domainId };
    onFinish(dataWithDomainId)
      .then(() => {
        refreshConcepts();
        toast.success(
          <>
            Concept <b>{conceptData.name}</b>{" "}
            {concept.id ? "updated" : "created"} successfully!
          </>
        );
      })
      .catch((error) => {
        console.error("Error handling concept:", error);
        toast.error(
          <>
            Failed to {concept.id ? "update" : "create"} concept{" "}
            <b>{conceptData.name}</b>.
          </>
        );
      });
  };

  return (
    <>
      <Title level={2}>{title}</Title>
      <Form
        initialValues={concept}
        onFinish={handleFinish}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          name="name"
          label="Concept Name"
          rules={[
            { required: true, message: "Please input the concept name." },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="parent_id" label="Parent Concept">
          <Select onChange={setParentId} value={parentId}>
            <Option value="">None</Option>
            {possibleParents.map((parent) => (
              <Option key={parent.id} value={parent.id}>
                {parent.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="sequence" label="Sequence">
          <Select>
            {possibleSequences.map((sequence) => (
              <Option key={sequence} value={sequence}>
                {sequence}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="description" label="Description">
          <TextArea rows={3} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {concept.id ? "Update Concept" : "Create Concept"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ConceptEditForm;
