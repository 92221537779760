import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Table, Drawer, Button, Input, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ClassroomEditForm from "./ClassroomEditForm";
import { useAbility } from "../hooks/useAbility";
import { createClassroom, updateClassroom } from "../api/SchoolAPI";

const { Search } = Input;

const ClassroomList = ({
  classrooms,
  refreshClassrooms,
  showNewButton = false,
  showSearchBar = false,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedClassroom, setSelectedClassroom] = useState(null);

  const ability = useAbility();

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredClassrooms = classrooms.filter((classroom) =>
    classroom.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleRowClick = (record) => {
    if (ability.can("update", "classroom")) {
      setSelectedClassroom(record);
      setIsDrawerOpen(true);
    }
  };

  const handleNewClassroom = () => {
    setSelectedClassroom({});
    setIsDrawerOpen(true);
  };

  const handleFormFinish = (classroomData) => {
    if (selectedClassroom && selectedClassroom.id) {
      return updateClassroom(selectedClassroom.id, classroomData).then(() => {
        setIsDrawerOpen(false);
        refreshClassrooms();
      });
    } else {
      return createClassroom(classroomData).then(() => {
        setIsDrawerOpen(false);
        refreshClassrooms();
      });
    }
  };

  const columns = [
    {
      key: "sl_no",
      className: "fixed-column",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`/classrooms/${record.id}`}>{text}</Link>
      ),
    },
  ];

  return (
    <div className="table-view">
      <Row
        gutter={16}
        align="middle"
        className="controls"
        style={{ marginBottom: 16 }}
      >
        {showNewButton && ability.can("create", "classroom") && (
          <Col>
            <Button
              type="default"
              onClick={handleNewClassroom}
              icon={<PlusOutlined />}
            />
          </Col>
        )}
        {showSearchBar && (
          <Col flex="auto">
            <Search
              placeholder="Search by name"
              allowClear
              onChange={handleSearch}
            />
          </Col>
        )}
      </Row>
      <Table
        className="custom-table"
        columns={columns}
        dataSource={filteredClassrooms}
        rowKey="id"
        rowClassName={(record, index) =>
          index % 2 === 0 ? "row-even" : "row-odd"
        }
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
        pagination={filteredClassrooms.length > 10 ? { pageSize: 10 } : false}
      />
      <Drawer
        width={350}
        onClose={() => {
          setIsDrawerOpen(false);
          setSelectedClassroom(null);
        }}
        open={isDrawerOpen}
        closable={false}
      >
        {isDrawerOpen && (
          <ClassroomEditForm
            classroom={selectedClassroom}
            onFinish={handleFormFinish}
            refreshClassrooms={refreshClassrooms}
            title={
              selectedClassroom && selectedClassroom.id
                ? "Edit Classroom"
                : "New Classroom"
            }
          />
        )}
      </Drawer>
    </div>
  );
};

export default ClassroomList;
