import React from "react";
import { Form, Input, Button, Typography } from "antd";
import { toast } from "react-toastify";

const { Title } = Typography;

const CollectionEditForm = ({
  collection = {},
  onFinish,
  refreshCollections,
  title,
}) => {
  const handleFinish = (collectionData) => {
    onFinish(collectionData)
      .then(() => {
        refreshCollections();
        toast.success(
          <>
            Collection <b>{collectionData.name}</b>{" "}
            {collection.id ? "updated" : "created"} successfully!
          </>
        );
      })
      .catch((error) => {
        console.error("Error handling collection:", error);
        toast.error(
          <>
            Failed to {collection.id ? "update" : "create"} collection{" "}
            <b>{collectionData.name}</b>.
          </>
        );
      });
  };

  return (
    <>
      <Title level={2}>{title}</Title>
      <Form
        initialValues={collection}
        onFinish={handleFinish}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please input the name." }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {collection.id ? "Update Collection" : "Create Collection"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default CollectionEditForm;
