// src/config/sidebarItems.js
import {
  BarChartOutlined,
  SmileOutlined,
  RiseOutlined,
  CalendarOutlined,
  FileTextOutlined,
  UsergroupAddOutlined,
  SafetyCertificateOutlined,
  ReadOutlined,
  ApartmentOutlined,
  ToolOutlined,
  BankOutlined,
  IdcardOutlined,
  UserOutlined,
  UnorderedListOutlined,
  GroupOutlined,
} from "@ant-design/icons";

export const sidebarItems = [
  {
    key: "dashboard",
    label: "Dashboard",
    icon: <BarChartOutlined />,
    path: "/",
  },
  {
    key: "children",
    label: "Children",
    icon: <SmileOutlined />,
    path: "/children",
  },
  {
    key: "record-keeper",
    label: "Record Keeper",
    icon: <RiseOutlined />,
    path: "/record-keeper",
  },
  {
    key: "record-attendance",
    label: "Attendance",
    icon: <CalendarOutlined />,
    path: "/record-attendance",
  },
  {
    key: "attendance-report",
    label: "Attendance Report",
    icon: <FileTextOutlined />,
    path: "/attendance-report",
  },
  {
    key: "record-keeper-settings",
    label: "Settings",
    icon: <ApartmentOutlined />,
    path: "/record-keeper-settings",
  },
  {
    key: "guardians",
    label: "Guardians",
    icon: <UsergroupAddOutlined />,
    path: "/guardians",
  },
  {
    key: "staff-members",
    label: "Staff",
    icon: <IdcardOutlined />,
    path: "/staff-members",
  },
  {
    key: "security-guards",
    label: "Security",
    icon: <SafetyCertificateOutlined />,
    path: "/security-guards",
  },
  {
    key: "books",
    label: "Books",
    icon: <ReadOutlined />,
    path: "/library/books",
  },
  {
    key: "authors",
    label: "Authors",
    icon: <UserOutlined />,
    path: "/library/authors",
  },
  {
    key: "collections",
    label: "Collections",
    icon: <UnorderedListOutlined />,
    path: "/library/collections",
  },
  {
    key: "publishers",
    label: "Publishers",
    icon: <IdcardOutlined />,
    path: "/library/publishers",
  },
  {
    key: "categories",
    label: "Categories",
    icon: <GroupOutlined />,
    path: "/library/categories",
  },
  {
    key: "classrooms",
    label: "Classrooms",
    icon: <ReadOutlined />,
    path: "/classrooms",
  },
  {
    key: "school-info",
    label: "School Info",
    icon: <BankOutlined />,
    path: "/school-info",
  },
  {
    key: "settings",
    label: "Settings",
    icon: <ToolOutlined />,
    path: "/settings",
  },
];
