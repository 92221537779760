// src/components/DomainEditForm.js

import React from "react";
import { Form, Input, Button, Typography } from "antd";
import { toast } from "react-toastify";

const { Title } = Typography;

const DomainEditForm = ({ domain = {}, onFinish, refreshDomains, title }) => {
  const handleFinish = (domainData) => {
    onFinish(domainData)
      .then(() => {
        refreshDomains();
        toast.success(
          <>
            Domain <b>{domainData.name}</b> {domain.id ? "updated" : "created"}{" "}
            successfully!
          </>
        );
      })
      .catch((error) => {
        console.error("Error handling domain:", error);
        toast.error(
          <>
            Failed to {domain.id ? "update" : "create"} domain{" "}
            <b>{domainData.name}</b>.
          </>
        );
      });
  };

  return (
    <>
      <Title level={2}>{title}</Title>
      <Form
        initialValues={domain}
        onFinish={handleFinish}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          name="name"
          label="Domain Name"
          rules={[{ required: true, message: "Please input the domain name." }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {domain.id ? "Update Domain" : "Create Domain"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default DomainEditForm;
