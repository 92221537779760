import React from "react";
import { Card } from "antd";
import { Link } from "react-router-dom";
import { CheckCircleOutlined, EditOutlined } from "@ant-design/icons";
import { getFullName } from "../helpers/childHelper";
import { formatPhoneNumber } from "../helpers/formatHelper";
import "../css/EmployeeCardList.css";

const EmployeeCardList = ({ employees, onEmployeeClick, ability }) => {
  return (
    <div className="employee-card-list">
      {employees.map((employee) => (
        <Card key={employee.employee_id} className="employee-card">
          <div className="employee-card-content">
            <img
              src={employee.profile_photo_url || "/icons/profile-icon.png"}
              alt="Profile"
              className="profile-image"
            />
            <div className="employee-info">
              <div className="employee-header">
                <Link
                  to={`/staff-members/${employee.employee_id}`}
                  className="employee-card-name"
                >
                  {getFullName(employee)}
                </Link>
                {ability.can("update", "employee") && (
                  <EditOutlined
                    className="edit-icon"
                    onClick={() => onEmployeeClick(employee)}
                  />
                )}
              </div>
              <div className="employee-id-title">
                {employee.eid} | {employee.employee_title}
              </div>
              {ability.can("create", "employee") && (
                <div className="employee-role">{employee.role}</div>
              )}
              <div className="employee-contact">
                <a href={`tel:${employee.phone_number}`}>
                  {formatPhoneNumber(employee.phone_number)}
                </a>
              </div>
              {employee.email && (
                <div className="employee-email">
                  {employee.user_id && (
                    <CheckCircleOutlined
                      style={{ color: "green", marginRight: 8 }}
                    />
                  )}
                  {employee.email}
                </div>
              )}
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default EmployeeCardList;
