// client/src/pages/DashboardPage.js
import React from "react";
import { Helmet } from "react-helmet-async";
import { useAuth } from "../contexts/AuthContext";
import OnboardingChecklist from "../components/OnboardingChecklist";
import MyPlannedActivities from "../components/record-keeper/MyPlannedActivities";
import NoClassroomMessage from "../components/NoClassroomMessage";
import "../css/DashboardPage.css";

const DashboardPage = () => {
  const { currentUser } = useAuth();

  const showOnboardingChecklist =
    currentUser.onboardingStatus &&
    currentUser.onboardingStatus.status === false;

  if (showOnboardingChecklist) {
    return (
      <div className="dashboard-container">
        <Helmet>
          <title>Dashboard | Maidan</title>
        </Helmet>
        <OnboardingChecklist
          checklist={currentUser.onboardingStatus.checklist}
        />
      </div>
    );
  }

  if (currentUser.role === "Teacher" && currentUser.classrooms.length === 0) {
    return <NoClassroomMessage title="Dashboard" />;
  }

  return (
    <div className="dashboard-container">
      <Helmet>
        <title>Dashboard | Maidan</title>
      </Helmet>
      <div className="widget half-width">
        <MyPlannedActivities />
      </div>
    </div>
  );
};

export default DashboardPage;
