// client/src/api/OnboardingAPI.js
import axios from "axios";

const baseURL = "/onboarding";

const api = axios.create({
  baseURL,
});

export const createSchoolAndOwnerAccount = async (schoolData) => {
  try {
    const response = await api.post("/create-school-account", schoolData);
    return response.data;
  } catch (error) {
    console.error("Error submitting role:", error);
    throw error.response ? error.response.data : error;
  }
};
