import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import CategoryList from "../../components/library/CategoryList"; 
import ManageCategoryBookModal from "../../components/library/ManageCategoryBookModal"; 
import {
  getAuthorsByBook,
  getBookById,
  getCategoriesByBook,
  getCollectionsByBook,
  getPublisherByBook,
  mapAuthorsToBook,
  mapCategoriesToBook,
  mapCollectionsToBook,
  unmapAuthorsToBook,
  unmapCategoriesToBook,
  unmapCollectionsToBook,
} from "../../api/library/BookAPI";
import AuthorList from "../../components/library/AuthorList";
import ManageAuthorBookModal from "../../components/library/ManageAuthorBookModal";
import PublisherList from "../../components/library/PublisherList";
import CollectionList from "../../components/library/CollectionList";
import ManageCollectionBookModal from "../../components/library/ManageCollectionBookModal";
import { Helmet } from "react-helmet-async";

const BookPage = () => {
  const { bookId } = useParams();
  const [book, setBook] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isCategoriesModalVisible, setIsCategoriesModalVisible] = useState(false);
  const [authors, setAuthors] = useState([]);
  const [publisher, setPublisher] = useState([]);
  const [isAuthorsModalVisible, setIsAuthorsModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [collections, setCollections] = useState([]);
  const [isCollectionsModalVisible, setIsCollectionsModalVisible] = useState(false);

  useEffect(() => {
    const fetchBookAuthorsCategoriesCollections = async () => {
      try {
        // Fetch book details
        const bookData = await getBookById(bookId);
        setBook(bookData);

        // Fetch categories associated with the book
        const categoriesData = await getCategoriesByBook(bookId);
        setCategories(categoriesData);

        // Fetch authors associated with the book
        const authorsData = await getAuthorsByBook(bookId);
        setAuthors(authorsData);

        // Fetch the publisher associated with the book
        const publisherData = await getPublisherByBook(bookId);
        setPublisher([publisherData]);

        // Fetch collections associated with the book
        const collectionsData = await getCollectionsByBook(bookId);
        setCollections(collectionsData);

      } catch (error) {
        console.error("Error fetching book, authors, categories, publisher, and collection:", error);
      }
    };
    fetchBookAuthorsCategoriesCollections();
  }, [bookId]);

  const refreshBook = useCallback(async () => {
    try {
      // Fetch updated book details
      const bookData = await getBookById(bookId);
      setBook(bookData);

      // Fetch updated categories associated with the book
      const categoriesData = await getCategoriesByBook(bookId);
      setCategories(categoriesData);

      // Fetch updated authors associated with the book
      const authorsData = await getAuthorsByBook(bookId);
      setAuthors(authorsData);

      // Fetch updated publisher associated with the book
      const publisherData = await getPublisherByBook(bookId);
      setPublisher([publisherData]);

      // Fetch updated collections associated with the book
      const collectionsData = await getCollectionsByBook(bookId);
      setCollections(collectionsData);
      
    } catch (error) {
      console.error("Error refreshing book, authors, categories, publisher, and collection:", error);
    }
  }, [bookId]);

  useEffect(() => {
    refreshBook();
  }, [refreshBook]);

  const handleAddCategories = async (categoryIds) => {
    try {
      for (const categoryId of categoryIds) {
        await mapCategoriesToBook(bookId, categoryId);
      }
      setIsCategoriesModalVisible(false);
      refreshBook();
    } catch (error) {
      console.error("Error adding categories to book:", error);
    }
  };

  const handleRemoveCategories = async (categoryIds) => {
    try {
      for (const categoryId of categoryIds) {
        await unmapCategoriesToBook(bookId, categoryId);
      }
      setIsCategoriesModalVisible(false);
      refreshBook();
    } catch (error) {
      console.error("Error removing categories from book:", error);
    }
  };

  const handleAddAuthors = async (authorIds) => {
    try {
      for (const authorId of authorIds) {
        await mapAuthorsToBook(bookId, authorId);
      }
      setIsAuthorsModalVisible(false);
      refreshBook();
    } catch (error) {
      console.error("Error adding authors to book:", error);
    }
  };

  const handleRemoveAuthors = async (authorIds) => {
    try {
      for (const authorId of authorIds) {
        await unmapAuthorsToBook(bookId, authorId);
      }
      setIsAuthorsModalVisible(false);
      refreshBook();
    } catch (error) {
      console.error("Error removing authors from book:", error);
    }
  };

  const handleAddCollections = async (collectionIds) => {
    try {
      for (const collectionId of collectionIds) {
        await mapCollectionsToBook(bookId, collectionId);
      }
      setIsCollectionsModalVisible(false);
      refreshBook();
    } catch (error) {
      console.error("Error adding collections to book:", error);
    }
  };

  const handleRemoveCollections = async (collectionIds) => {
    try {
      for (const collectionId of collectionIds) {
        await unmapCollectionsToBook(bookId, collectionId);
      }
      setIsCollectionsModalVisible(false);
      refreshBook();
    } catch (error) {
      console.error("Error removing collections from book:", error);
    }
  };

  if (!book) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Helmet>
        <title>{book.title} | Book</title>
      </Helmet>
      <div style={{textAlign: "center",marginBottom: "20px"}}>
        <h2 style={{fontSize: "24px", color: "#333", marginTop: "0"}}>{book.title}</h2>
        {book.cover_image_url && (<img src={book.cover_image_url} style={{width:"150px", height: "auto", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"}}/>)}
      </div>
      <h3 style={{marginLeft:8}}>Categories</h3>
      <Button
        onClick={() => {
          setModalMode("add");
          setIsCategoriesModalVisible(true);
        }}
        style={{marginLeft:8}}
      >
        Add Categories
      </Button>
      <Button
        onClick={() => {
          setModalMode("remove");
          setIsCategoriesModalVisible(true);
        }}
        style={{ marginLeft: 8 }}
      >
        Remove Categories
      </Button>
      <CategoryList categories={categories} /> 

      <ManageCategoryBookModal
        bookId={bookId}
        isVisible={isCategoriesModalVisible}
        onClose={() => setIsCategoriesModalVisible(false)}
        onAdd={modalMode === "add" ? handleAddCategories : handleRemoveCategories}
        mode={modalMode}
        existingCategories={categories}
      />

      <h3 style={{marginLeft:8}}>Authors</h3>
      <Button
        onClick={() => {
          setModalMode("add");
          setIsAuthorsModalVisible(true);
        }}
        style={{marginLeft:8}}
      >
        Add Authors
      </Button>
      <Button
        onClick={() => {
          setModalMode("remove");
          setIsAuthorsModalVisible(true);
        }}
        style={{ marginLeft: 8 }}
      >
        Remove Authors
      </Button>
      <AuthorList authors={authors} /> 

      <ManageAuthorBookModal
        bookId={bookId}
        isVisible={isAuthorsModalVisible}
        onClose={() => setIsAuthorsModalVisible(false)}
        onAdd={modalMode === "add" ? handleAddAuthors : handleRemoveAuthors}
        mode={modalMode}
        existingAuthors={authors}
      />
      <h3 style={{marginLeft:8}}>Collections</h3>
      <Button
        onClick={() => {
          setModalMode("add");
          setIsCollectionsModalVisible(true);
        }}
        style={{marginLeft:8}}
      >
        Add Collections
      </Button>
      <Button
        onClick={() => {
          setModalMode("remove");
          setIsCollectionsModalVisible(true);
        }}
        style={{ marginLeft: 8 }}
      >
        Remove Collections
      </Button>
      <CollectionList collections={collections} /> 

      <ManageCollectionBookModal
        bookId={bookId}
        isVisible={isCollectionsModalVisible}
        onClose={() => setIsCollectionsModalVisible(false)}
        onAdd={modalMode === "add" ? handleAddCollections : handleRemoveCollections}
        mode={modalMode}
        existingCollections={collections}
      />
      <h3 style={{marginLeft:8}}>Publisher</h3>
      <PublisherList publishers={publisher}/>
    </div>
  );
};

export default BookPage;
