import React, { useState, useEffect } from "react";
import { Modal, Table, Input } from "antd";
import { getChildrenNotInClassroom } from "../api/SchoolAPI";
import { formatDate, calculateAge } from "../helpers/dateTimeHelper";

const { Search } = Input;

const ManageChildrenModal = ({
  classroomId,
  isOpen,
  onClose,
  onAdd,
  mode,
  existingChildren,
}) => {
  const [children, setChildren] = useState([]);
  const [selectedChildren, setSelectedChildren] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (isOpen) {
      const fetchChildren = async () => {
        try {
          let children = [];
          if (mode === "add") {
            children = await getChildrenNotInClassroom(classroomId);
          } else {
            children = existingChildren;
          }
          setChildren(children);
        } catch (error) {
          console.error("Error fetching children:", error);
        }
      };
      fetchChildren();
    }
  }, [isOpen, classroomId, mode, existingChildren]);

  const handleAddChildren = () => {
    onAdd(selectedChildren);
    setSelectedChildren([]);
  };

  const handleChildSelection = (selectedRowKeys) => {
    setSelectedChildren(selectedRowKeys);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredChildren = children.filter((child) =>
    child.first_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const childColumns = [
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Child ID",
      dataIndex: "sid",
      key: "sid",
    },
    {
      title: "DOB",
      dataIndex: "date_of_birth",
      key: "date_of_birth",
      render: (text) => (
        <div>
          <div>{formatDate(text)}</div>
          <div>{calculateAge(text)}</div>
        </div>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectedChildren,
    onChange: handleChildSelection,
  };

  return (
    <Modal
      title={
        mode === "add"
          ? "Add Children to Classroom"
          : "Remove Children from Classroom"
      }
      open={isOpen}
      onOk={handleAddChildren}
      onCancel={onClose}
      okText={mode === "add" ? "Add to Classroom" : "Remove from Classroom"}
    >
      <Search
        placeholder="Search by name"
        allowClear
        onChange={handleSearch}
        style={{ marginBottom: 16 }}
      />
      <Table
        rowSelection={rowSelection}
        columns={childColumns}
        dataSource={filteredChildren}
        rowKey="child_id"
        pagination={{ pageSize: 10 }}
      />
    </Modal>
  );
};

export default ManageChildrenModal;
