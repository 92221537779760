import React, { useState, useEffect } from "react";
import BookList from "../../components/library/BookList"; // Import the BookList component you created
import { getAuthorsByBook, getBooks, getCategoriesByBook, getCollectionsByBook, getPublisherByBook } from "../../api/library/BookAPI"; // API call to fetch books
import "../../css/LibraryPages.css"
import { Helmet } from "react-helmet-async";

const BooksPage = () => {
  const [books, setBooks] = useState([]);

  const refreshBooks = async () => {
    try {
      const data = await getBooks(); // Fetch books from the API
      const updatedBooks = await Promise.all(
        data.map(async (book) => {
          const categories = await getCategoriesByBook(book.id) || [];
          const authors = await getAuthorsByBook(book.id) || [];
          const collections = await getCollectionsByBook(book.id) || [];
          const publisher = await getPublisherByBook(book.id) || null;
  
          return {
            ...book,
            categories,
            authors,
            collections,
            publisher
          };
        })
      );
      setBooks(updatedBooks);
    } catch (error) {
      console.error(error);
    }
  };
  
  useEffect(() => {
    refreshBooks(); // Fetch books when the component is mounted
  }, []);

  return (
    <div>
      <Helmet>
        <title>Boooks | Maidan</title>
      </Helmet>
      <div className="content-below-header">
        <BookList
          books={books}
          refreshBooks={refreshBooks}
          showNewButton={true}
          showSearchBar={true}
        />
      </div>
    </div>
  );
};

export default BooksPage;
