import React from "react";
import { Select } from "antd";

const { Option } = Select;

const ChildSortOptions = ({ value, onChange }) => (
  <Select value={value} style={{ width: "100%" }} onChange={onChange}>
    <Option value="alphabetic">A-Z</Option>
    <Option value="ageLowToHigh">Age ↑</Option>
    <Option value="ageHighToLow">Age ↓</Option>
  </Select>
);

export default ChildSortOptions;
