import React from "react";
import { Form, Input, Button, DatePicker, Typography, Radio } from "antd";
import { toast } from "react-toastify";
import moment from "moment";
import BloodGroupDropdown from "./BloodGroupDropdown";
import { getFullName } from "../helpers/childHelper";

const { TextArea } = Input;
const { Title } = Typography;

const GuardianEditForm = ({
  guardian = {},
  onFinish,
  refreshGuardians,
  title,
}) => {
  const handleFinish = (guardianData) => {
    onFinish(guardianData)
      .then(() => {
        refreshGuardians();
        toast.success(
          <>
            Guardian <b>{getFullName(guardianData)}</b>{" "}
            {guardian.id ? "updated" : "created"} successfully!
          </>
        );
      })
      .catch((error) => {
        console.error("Error handling guardian:", error);
        toast.error(
          <>
            Failed to {guardian.id ? "update" : "create"} guardian{" "}
            <b>{getFullName(guardianData)}</b>.
          </>
        );
      });
  };

  return (
    <>
      <Title level={2}>{title}</Title>
      <Form
        initialValues={{
          ...guardian,
          date_of_birth: guardian.date_of_birth
            ? moment(guardian.date_of_birth)
            : null,
        }}
        onFinish={handleFinish}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          name="first_name"
          label="First Name"
          rules={[{ required: true, message: "Please input the full name." }]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="last_name" label="Last Name">
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[{ type: "email", message: "Please input a valid email." }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone_number"
          label="Phone Number"
          rules={[
            {
              pattern: /^\d{10}$/,
              message: "The phone number must be 10 digits.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="date_of_birth" label="Date of Birth">
          <DatePicker format="DD-MM-YYYY" />
        </Form.Item>
        <Form.Item name="gender" label="Gender">
          <Radio.Group>
            <Radio value="Male">Male</Radio>
            <Radio value="Female">Female</Radio>
            <Radio value="Other">Other</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="current_address" label="Current Address">
          <TextArea rows={3} />
        </Form.Item>
        <Form.Item name="permanent_address" label="Permanent Address">
          <TextArea rows={3} />
        </Form.Item>
        <Form.Item name="blood_group" label="Blood Group">
          <BloodGroupDropdown />
        </Form.Item>
        <Form.Item name="occupation" label="Occupation">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {guardian.id ? "Update Guardian" : "Create Guardian"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default GuardianEditForm;
