// client/src/components/ChildEditForm.js

import React from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Typography,
  Radio,
  Row,
  Col,
} from "antd";
import { toast } from "react-toastify";
import moment from "moment";
import BloodGroupDropdown from "./BloodGroupDropdown";
import LanguageDropdown from "./LanguageDropdown";
import NationalityDropdown from "./NationalityDropdown";
import AadhaarInput from "./AadhaarInput";
import { getFullName } from "../helpers/childHelper";
import ProfilePhotoUploader from "./ProfilePhotoUploader";

const { Title } = Typography;

const ChildEditForm = ({ child = {}, onFinish, refreshChildren, title }) => {
  const handleFinish = (childData) => {
    onFinish(childData)
      .then(() => {
        refreshChildren();
        toast.success(
          <>
            Child <b>{getFullName(childData)}</b>{" "}
            {child.id ? "updated" : "created"} successfully!
          </>
        );
      })
      .catch((error) => {
        console.error("Error handling child:", error);
        toast.error(
          <>
            Failed to {child.id ? "update" : "create"} child{" "}
            <b>{getFullName(childData)}</b>.
          </>
        );
      });
  };

  return (
    <>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "24px" }}
      >
        <ProfilePhotoUploader
          memberId={child.id}
          initialPhotoUrl={child.profile_photo_url}
          refreshProfile={refreshChildren}
        />
        <Title level={2}>{title}</Title>
      </div>

      <Form
        initialValues={{
          ...child,
          date_of_birth: child.date_of_birth
            ? moment(child.date_of_birth)
            : null,
          date_of_joining: child.date_of_joining
            ? moment(child.date_of_joining)
            : null,
          date_of_leaving: child.date_of_leaving
            ? moment(child.date_of_leaving)
            : null,
          mother_tongue: child.mother_tongue || undefined,
          nationality: child.nationality || undefined,
        }}
        onFinish={handleFinish}
        layout="vertical"
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="sid"
              label="Child ID"
              rules={[
                { required: true, message: "Please input the child ID." },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="first_name"
              label="First Name"
              rules={[
                { required: true, message: "Please input the first name." },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="last_name" label="Last Name">
              <Input />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="gender" label="Gender">
              <Radio.Group>
                <Radio value="Male">Male</Radio>
                <Radio value="Female">Female</Radio>
                <Radio value="Other">Other</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="date_of_birth" label="Date of Birth">
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="blood_group" label="Blood Group">
              <BloodGroupDropdown />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="mother_tongue" label="Mother Tongue">
              <LanguageDropdown />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="nationality" label="Nationality">
              <NationalityDropdown />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="aadhaar"
              label="Aadhaar"
              rules={[
                {
                  pattern: /^\d{12}$/,
                  message: "The Aadhaar number must be 12 digits.",
                },
              ]}
            >
              <AadhaarInput />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="date_of_joining" label="Date of Joining">
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="date_of_leaving" label="Date of Leaving">
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {child.id ? "Update Child" : "Create Child"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ChildEditForm;
