import React, { useState } from "react";
import { Button, Upload, Row, Col, Form } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { importChildren } from "../api/ImportAPI";

const ImportChildren = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (file) => {
    setFile(file);
    return false; // prevent auto-upload
  };

  const handleFormSubmit = async () => {
    if (!file) return;

    try {
      await importChildren(file);
    } catch (error) {
      console.error(error);
    }
  };

  const uploadProps = {
    name: "file",
    maxCount: 1,
    beforeUpload: handleFileChange,
    accept: ".xlsx,.xls,.csv",
  };

  return (
    <div>
      <Form onFinish={handleFormSubmit}>
        <Row gutter={16}>
          <Col xs={24} md={9}>
            <Upload {...uploadProps}>
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
            <a
              href="/sample_templates/Import_Children_Template.xlsx"
              download
              style={{ display: "block", marginTop: 8 }}
            >
              Download Template
            </a>
          </Col>
          <Col xs={24} md={6}>
            <Button type="default" htmlType="submit">
              Import
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ImportChildren;
