import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Table, Drawer, Button, Input } from "antd";
import CollectionEditForm from "./CollectionEditForm";
import { createCollection, updateCollection } from "../../api/library/CollectionAPI";

const { Search } = Input;

const CollectionList = ({
  collections,
  refreshCollections,
  showNewButton = false,
  showSearchBar = false,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState(null);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredCollections = collections.filter((collection) =>
    collection.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleRowClick = (record) => {
    setSelectedCollection(record);
    setIsDrawerOpen(true);
  };

  const handleNewCollection = () => {
    setSelectedCollection({});
    setIsDrawerOpen(true);
  };

  const handleFormFinish = (collectionData) => {
    if (selectedCollection && selectedCollection.id) {
      return updateCollection(selectedCollection.id, collectionData).then(() => {
        setIsDrawerOpen(false);
        refreshCollections();
      });
    } else {
      return createCollection(collectionData).then(() => {
        setIsDrawerOpen(false);
        refreshCollections();
      });
    }
  };

  const columns = [
    {
      title: "Sl No",
      key: "sl_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`/library/collections/${record.id}`}>{text}</Link>
      ),
    },
    {
        title: "Description",
        dataIndex: "description",
        key: "description",
        render: (text, record) => (text)
    }
  ];

  return (
    <div className="table-view">
      {showNewButton && (
        <Button
          type="default"
          onClick={handleNewCollection}
          style={{ marginBottom: 16 }}
        >
          New Collection
        </Button>
      )}
      {showSearchBar && (
        <Search
          placeholder="Search by name"
          allowClear
          onChange={handleSearch}
          style={{ marginBottom: 16 }}
        />
      )}
      <Table
        className="custom-table"
        columns={columns}
        dataSource={filteredCollections}
        rowKey="id"
        rowClassName={(record, index) =>
          index % 2 === 0 ? "row-even" : "row-odd"
        }
        onRow={(record) => {
          return {
            onClick: () => {
              handleRowClick(record);
            },
          };
        }}
        pagination={filteredCollections.length > 10 ? { pageSize: 10 } : false}
      />
      <Drawer
        width={400}
        onClose={() => {
          setIsDrawerOpen(false);
          setSelectedCollection(null);
        }}
        open={isDrawerOpen}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {isDrawerOpen && (
          <CollectionEditForm
            collection={selectedCollection}
            onFinish={handleFormFinish}
            refreshCollections={refreshCollections}
            title={
              selectedCollection && selectedCollection.id
                ? "Edit Collection"
                : "New Collection"
            }
          />
        )}
      </Drawer>
    </div>
  );
};

export default CollectionList;
