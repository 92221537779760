import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getBooksById, getPublisherById } from "../../api/library/PublisherAPI";
import BookList from "../../components/library/BookList";
import { Helmet } from "react-helmet-async";

const PublisherPage = () => {
  const { publisherId } = useParams();
  const [book, setBook] = useState([]);
  const [publisher,setPublisher]=useState("");

  useEffect(() => {
    const fetchBook = async () => {
      try {
        const data = await getBooksById(publisherId);
        setBook(data);
        const publisherData = await getPublisherById(publisherId);
        setPublisher(publisherData.name);
      } catch (error) {
        console.error("Error fetching book:", error);
      }
    };
    fetchBook();
  }, [publisherId]);

  if (!book) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Helmet>
        <title>{publisher} | Publisher</title>
      </Helmet>
      <h3>{publisher}</h3>
      <h3 style={{marginLeft:8}}>Books</h3>
      <BookList books={book}/>
    </div>
  );
};

export default PublisherPage;
