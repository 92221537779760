// client/src/App.js
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Layout } from "antd";
import { HelmetProvider } from "react-helmet-async";
import CopilotPage from "./pages/copilot/CopilotPage";
import Sidebar from "./components/Sidebar";
import MobileHeader from "./components/MobileHeader";
import LandingPage from "./pages/LandingPage";
import ChildrenPage from "./pages/ChildrenPage";
import RecordKeeperPage from "./pages/record-keeper/RecordKeeperPage";
import ClassroomsPage from "./pages/ClassroomsPage";
import EmployeesPage from "./pages/EmployeesPage";
import GuardiansPage from "./pages/GuardiansPage";
import SettingsPage from "./pages/SettingsPage";
import SchoolInfoPage from "./pages/SchoolInfoPage";
import AttendancePage from "./pages/AttendancePage";
import AttendanceReportPage from "./pages/AttendanceReportPage";
import ChildPage from "./pages/ChildPage";
import EmployeePage from "./pages/EmployeePage";
import GuardianPage from "./pages/GuardianPage";
import ClassroomPage from "./pages/ClassroomPage";
import DomainsPage from "./pages/DomainsPage";
import DomainPage from "./pages/DomainPage";
import IsbnScanner from "./pages/library/IsbnScanner";
import GuardAttendanceReportPage from "./pages/guards/GuardAttendanceReportPage";
import MarkGuardAttendancePage from "./pages/guards/MarkGuardAttendancePage";
import { useAuth } from "./contexts/AuthContext";
import useIsMobile from "./hooks/useIsMobile";
import ProtectedRoute from "./components/ProtectedRoute";
import "./App.css";
import PublishersPage from "./pages/library/PublishersPage";
import PublisherPage from "./pages/library/PublisherPage";
import CategoriesPage from "./pages/library/CategoriesPage";
import AuthorsPage from "./pages/library/AuthorsPage";
import CollectionsPage from "./pages/library/CollectionsPage";
import BooksPage from "./pages/library/BooksPage";
import AuthorPage from "./pages/library/AuthorPage";
import CollectionPage from "./pages/library/CollectionPage";
import CategoryPage from "./pages/library/CategoryPage";
import BookPage from "./pages/library/BookPage";

const App = () => {
  const { currentUser, handleLogout } = useAuth();
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const isMobile = useIsMobile();

  // Set sidebar visibility based on screen size (mobile or desktop)
  useEffect(() => {
    setIsSidebarVisible(!isMobile);
  }, [isMobile]);

  const handleToggleSidebar = () => {
    setIsSidebarVisible((prevVisible) => !prevVisible); // Toggle sidebar visibility
  };

  const handleCloseSidebar = () => {
    if (isMobile) {
      setIsSidebarVisible(false);
    }
  };

  const handleOutsideClick = (e) => {
    if (
      isSidebarVisible &&
      !e.target.closest(".ant-layout-sider") &&
      isMobile
    ) {
      handleCloseSidebar();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isSidebarVisible, isMobile]);

  const getMainContentClass = () => {
    return isSidebarVisible
      ? "main-content-with-sidebar"
      : "main-content-without-sidebar";
  };

  return (
    <HelmetProvider>
      <Layout style={{ minHeight: "100vh" }}>
        {isMobile && <MobileHeader onToggleSidebar={handleToggleSidebar} />}
        {currentUser && isSidebarVisible && (
          <Sidebar
            isMobile={isMobile}
            isVisible={isSidebarVisible}
            onLogout={handleLogout}
            onToggle={handleToggleSidebar}
            onMenuItemClick={handleCloseSidebar}
            user={currentUser}
          />
        )}
        <Layout className="site-layout">
          <div className={`main-content ${!isMobile && getMainContentClass()}`}>
            <Routes>
              <Route
                path="/"
                element={
                  <LandingPage setIsSidebarVisible={setIsSidebarVisible} />
                }
              />
              <Route
                path="/copilot"
                element={
                  <ProtectedRoute allowedRoles={["Owner", "Admin", "Teacher"]}>
                    <CopilotPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/children"
                element={
                  <ProtectedRoute allowedRoles={["Owner", "Admin", "Teacher"]}>
                    <ChildrenPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/record-keeper"
                element={
                  <ProtectedRoute allowedRoles={["Owner", "Admin", "Teacher"]}>
                    <RecordKeeperPage
                      isMobile={isMobile}
                      setIsSidebarVisible={setIsSidebarVisible}
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/staff-members"
                element={
                  <ProtectedRoute allowedRoles={["Owner", "Admin", "Teacher"]}>
                    <EmployeesPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/guardians"
                element={
                  <ProtectedRoute allowedRoles={["Owner", "Admin", "Teacher"]}>
                    <GuardiansPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/classrooms"
                element={
                  <ProtectedRoute allowedRoles={["Owner", "Admin", "Teacher"]}>
                    <ClassroomsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <ProtectedRoute allowedRoles={["Owner", "Admin"]}>
                    <SettingsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/school-info"
                element={
                  <ProtectedRoute allowedRoles={["Owner", "Admin"]}>
                    <SchoolInfoPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/children/:childId"
                element={
                  <ProtectedRoute allowedRoles={["Owner", "Admin", "Teacher"]}>
                    <ChildPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/staff-members/:employeeId"
                element={
                  <ProtectedRoute allowedRoles={["Owner", "Admin"]}>
                    <EmployeePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/guardians/:guardianId"
                element={
                  <ProtectedRoute allowedRoles={["Owner", "Admin", "Teacher"]}>
                    <GuardianPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/classrooms/:classroomId"
                element={
                  <ProtectedRoute allowedRoles={["Owner", "Admin", "Teacher"]}>
                    <ClassroomPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/record-attendance"
                element={
                  <ProtectedRoute allowedRoles={["Owner", "Admin", "Teacher"]}>
                    <AttendancePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/attendance-report"
                element={
                  <ProtectedRoute allowedRoles={["Owner", "Admin", "Teacher"]}>
                    <AttendanceReportPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/record-keeper-settings"
                element={
                  <ProtectedRoute allowedRoles={["Owner", "Admin", "Teacher"]}>
                    <DomainsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/record-keeper-settings/domains/:domainId"
                element={
                  <ProtectedRoute allowedRoles={["Owner", "Admin", "Teacher"]}>
                    <DomainPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/security-guards"
                element={
                  <ProtectedRoute allowedRoles={["Owner", "Admin"]}>
                    <GuardAttendanceReportPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/security-guards/attendance"
                element={
                  <ProtectedRoute allowedRoles={["Owner", "Admin"]}>
                    <MarkGuardAttendancePage />
                  </ProtectedRoute>
                }
              />
              <Route path="/library">
                <Route path="publishers">
                  <Route
                    index
                    element={
                      <ProtectedRoute allowedRoles={["Owner", "Admin"]}>
                        <PublishersPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path=":publisherId"
                    element={
                      <ProtectedRoute allowedRoles={["Owner", "Admin"]}>
                        <PublisherPage />
                      </ProtectedRoute>
                    }
                  />
                </Route>
                <Route path="categories">
                  <Route
                    index
                    element={
                      <ProtectedRoute allowedRoles={["Owner", "Admin"]}>
                        <CategoriesPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path=":categoryId"
                    element={
                      <ProtectedRoute allowedRoles={["Owner", "Admin"]}>
                        <CategoryPage />
                      </ProtectedRoute>
                    }
                  />
                </Route>
                <Route path="authors">
                  <Route
                    index
                    element={
                      <ProtectedRoute allowedRoles={["Owner", "Admin"]}>
                        <AuthorsPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path=":authorId"
                    element={
                      <ProtectedRoute allowedRoles={["Owner", "Admin"]}>
                        <AuthorPage />
                      </ProtectedRoute>
                    }
                  />
                </Route>
                <Route path="collections">
                  <Route
                    index
                    element={
                      <ProtectedRoute allowedRoles={["Owner", "Admin"]}>
                        <CollectionsPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path=":collectionId"
                    element={
                      <ProtectedRoute allowedRoles={["Owner", "Admin"]}>
                        <CollectionPage />
                      </ProtectedRoute>
                    }
                  />
                </Route>
                <Route path="books">
                  <Route
                    index
                    element={
                      <ProtectedRoute allowedRoles={["Owner", "Admin"]}>
                        <BooksPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path=":bookId"
                    element={
                      <ProtectedRoute allowedRoles={["Owner", "Admin"]}>
                        <BookPage />
                      </ProtectedRoute>
                    }
                  />
                </Route>
                <Route
                  path="isbn"
                  element={
                    <ProtectedRoute allowedRoles={["Owner", "Admin"]}>
                      <IsbnScanner />
                    </ProtectedRoute>
                  }
                />
              </Route>
            </Routes>
          </div>
        </Layout>
      </Layout>
    </HelmetProvider>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
