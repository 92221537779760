// src/components/ProtectedRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const ProtectedRoute = ({ children, allowedRoles, redirectTo = "/" }) => {
  const { currentUser } = useAuth();

  const hasAllowedRole =
    currentUser && currentUser.role && allowedRoles.includes(currentUser.role);

  return hasAllowedRole ? (
    <>{children}</>
  ) : (
    <Navigate to={redirectTo} replace />
  );
};

export default ProtectedRoute;
