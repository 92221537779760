import React from "react";
import { Input } from "antd";

const { Search } = Input;

const SearchInput = ({ placeholder, onChange }) => (
  <Search
    placeholder={placeholder}
    onChange={(e) => onChange(e.target.value)}
    style={{ width: "100%" }}
  />
);

export default SearchInput;
