// src/utils/mixpanel.js
import mixpanel from "mixpanel-browser";

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

if (!MIXPANEL_TOKEN) {
  console.error("Mixpanel token is not defined in the environment variables!");
} else {
  mixpanel.init(MIXPANEL_TOKEN, {
    debug: process.env.NODE_ENV === "development",
  });
}

export const identifyUser = (userId, userProperties = {}) => {
  if (!userId) return;
  mixpanel.identify(userId);
  mixpanel.people.set(userProperties);
};

export const trackEvent = (eventName, eventProperties = {}, user = null) => {
  const defaultProperties = {
    userId: user?.id || null,
    currentMembershipId: user?.currentMembershipId || null,
    schoolId: user?.schoolId || null,
    role: user?.role || null,
    first_name: user?.first_name || null,
    last_name: user?.last_name || null,
  };

  mixpanel.track(eventName, {
    ...defaultProperties,
    ...eventProperties,
  });
};

// Increment a property in Mixpanel People Analytics
export const incrementProperty = (property, value = 1) => {
  mixpanel.people.increment(property, value);
};

// Set a user property once (does not change the value if it is already set)
export const setOnce = (property, value) => {
  mixpanel.people.set_once(property, value);
};

export default mixpanel;
