import React, { useState, useEffect } from "react";
import CategoryList from "../../components/library/CategoryList";
import { getCategories } from "../../api/library/CategoryAPI";
import { Helmet } from "react-helmet-async";

const CategoriesPage = () => {
  const [categories, setCategories] = useState([]);

  const refreshCategories = async () => {
    try {
      const data = await getCategories();
      setCategories(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    refreshCategories();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Categories | Maidan</title>
      </Helmet>
      <div className="content-below-header">
        <CategoryList
          categories={categories}
          refreshCategories={refreshCategories}
          showNewButton={true}
          showSearchBar={true}
        />
      </div>
    </div>
  );
};

export default CategoriesPage;
