import React, { useState, useEffect } from "react";
import PublisherList from "../../components/library/PublisherList";
import { getPublishers } from "../../api/library/PublisherAPI";
import { Helmet } from "react-helmet-async";

const PublishersPage = () => {
  const [publishers, setPublishers] = useState([]);

  const refreshPublishers = async () => {
    try {
      const data = await getPublishers();
      setPublishers(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    refreshPublishers();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Publishers | Maidan</title>
      </Helmet>
      <div className="content-below-header">
        <PublisherList
          publishers={publishers}
          refreshPublishers={refreshPublishers}
          showNewButton={true}
          showSearchBar={true}
        />
      </div>
    </div>
  );
};

export default PublishersPage;
