// src/hooks/useIsMobile.js

import { useState, useEffect } from "react";

const useIsMobile = (threshold = 768) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateScreenSize = () => {
      setIsMobile(window.innerWidth < threshold);
    };

    updateScreenSize(); // Check immediately on mount

    window.addEventListener("resize", updateScreenSize);

    // Cleanup on unmount
    return () => window.removeEventListener("resize", updateScreenSize);
  }, [threshold]);

  return isMobile;
};

export default useIsMobile;
