import axios from "axios";

const baseURL = "/library/collections";

const api = axios.create({
  baseURL,
});

export const createCollection = async (collectionData) => {
    try {
      const response = await api.post("/", collectionData);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  export const getCollections = async () => {
    try {
      const response = await api.get("/");
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  export const getCollectionById = async (collectionId = "") => {
    try {
      const response = await api.get(`/${collectionId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  export const updateCollection = async (collectionId, collectionData) => {
    try {
      const response = await api.put(`/${collectionId}`, collectionData);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  // Map a Book to an Collection
  export const mapBooksToCollection = async (collectionId, bookId) => {
    try {
      const response = await api.post(
        `/${collectionId}/books/${bookId}/map`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  // Unmap a Book from an Collection
  export const unmapBooksToCollection = async (collectionId, bookId) => {
    try {
      const response = await api.delete(
        `/${collectionId}/books/${bookId}/unmap`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  // Get Books mapped to an Collection
  export const getBooksByCollection = async (collectionId) => {
    try {
      const response = await api.get(`/${collectionId}/books`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  // Get Books not mapped to an Collection
  export const getBooksNotByCollection = async (collectionId) => {
    try {
      const response = await api.get(
        `/${collectionId}/books/not-mapped`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  