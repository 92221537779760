import React, { useState } from "react";

const VoiceRecognitionComponent = ({ onVoiceInput }) => {
  const [recognition, setRecognition] = useState(null);
  const [isListening, setIsListening] = useState(false); // Track if it's currently listening

  const startRecognition = () => {
    if (!("webkitSpeechRecognition" in window)) {
      alert("Your browser does not support speech recognition.");
      return;
    }

    const newRecognition = new window.webkitSpeechRecognition();
    newRecognition.continuous = true; // Continuously listen until stopped
    newRecognition.interimResults = false;
    newRecognition.lang = "en-US";

    newRecognition.onresult = (event) => {
      const text = Array.from(event.results)
        .map((result) => result[0].transcript)
        .join(" ");

      console.log("Recognized text:", text); // Log the recognized text
      onVoiceInput(text); // Pass the recognized text back to the parent
    };

    newRecognition.onend = () => {
      setIsListening(false); // Update state when recognition ends
    };

    newRecognition.start();
    setRecognition(newRecognition);
    setIsListening(true); // Update state to indicate it's listening
  };

  const stopRecognition = () => {
    if (recognition) {
      recognition.stop(); // Stop the recognition
      setIsListening(false); // Update state to indicate it's not listening
    }
  };

  return (
    <div>
      {!isListening ? (
        <button onClick={startRecognition}>Start Voice Command</button>
      ) : (
        <button onClick={stopRecognition}>Stop Voice Command</button>
      )}
    </div>
  );
};

export default VoiceRecognitionComponent;
