import React, { useState } from 'react';
import WebcamComponent from './WebcamComponent';
import { createBookByIsbn, getBookByIsbn } from '../../api/library/BookAPI';
import '../../css/IsbnScanner.css'
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet-async";

const IsbnScanner = () => {
  const [error, setError] = useState('');
  const [bookInfo, setBookInfo] = useState(null);
  const navigate=useNavigate();

  const handleDetected = async (code) => {
    try {
      console.log(`Book's ISBN code: ${code}`);
      const bookData = await getBookByIsbn(code);
      const volumeInfo = bookData.items[0].volumeInfo;
      setBookInfo(volumeInfo);
      setError('')
    } catch (err) {
      setError('Error fetching book information.');
      console.error(err);
    }
  };

  const handleBookInsert = async(bookData)=>{
    try{
      await createBookByIsbn(bookData);
      navigate('/books')
    }catch (err) {
      setError('Error in Inserting book information.');
      console.error(err);
    }
  }

  const handleBack = () => {
      setBookInfo(null);
  }

  return (
    
    <div className='isdn-scanner-container'>
      <Helmet>
        <title>ISBN Scanner | Maidan</title>
      </Helmet>
      <h2 className='isdn-scanner-container'>ISBN Scanner</h2>
      {bookInfo ? (
    <div className='book-details-container'>
    <div className='book-thumbnail'>
      {bookInfo.imageLinks && (
        <img src={bookInfo.imageLinks.thumbnail} alt={bookInfo.title} />
      )}
    </div>
    <div className='book-info'>
      <h1>{bookInfo.title}</h1>
      <p><strong>Authors:</strong> {bookInfo.authors && (bookInfo.authors.join(', '))}</p>
      <p><strong>Publisher:</strong> {bookInfo.publisher}</p>
      <p><strong>Published Year:</strong> {bookInfo.publishedDate && (Number.parseInt(bookInfo.publishedDate.slice(0,4)))}</p>
      <p><strong>Description:</strong> {bookInfo.description && (bookInfo.description.length <= 1400 
    ? bookInfo.description 
    : bookInfo.description.slice(0, 1400) + '...')}</p>
      <p><strong>ISBN-10:</strong> {bookInfo.industryIdentifiers && (bookInfo.industryIdentifiers.find(id => id.type === 'ISBN_10')?.identifier)}</p>
      <p><strong>ISBN-13:</strong> {bookInfo.industryIdentifiers && (bookInfo.industryIdentifiers.find(id => id.type === 'ISBN_13')?.identifier)}</p>
      <p><strong>Categories:</strong> {bookInfo.categories && (bookInfo.categories.join(', '))}</p>
      <p><strong>Language:</strong> {bookInfo.language && (bookInfo.language)}</p>
      <button className='insert-button' onClick={()=>handleBookInsert(bookInfo)}>Insert</button>
      <button className='back-button' onClick={handleBack}>Back</button>
    </div>
  </div>
) : (
  <WebcamComponent onDetected={handleDetected} setError={setError} />
)}

      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default IsbnScanner;
