// client/src/components/ProfilePhotoUploader.js

import React, { useState, useRef, useEffect } from "react";
import { Modal, Upload, Button, message } from "antd";
import { UploadOutlined, CameraOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { updateMembershipProfilePhoto } from "../api/MembershipsAPI";
import "../css/ProfilePhotoUploader.css";

const ProfilePhotoUploader = ({
  memberId,
  initialPhotoUrl,
  refreshProfile,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState(
    initialPhotoUrl || "/icons/profile-icon.png"
  );
  const [isCaptureMode, setIsCaptureMode] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false);
  const [imageCaptured, setImageCaptured] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const handleUploadClick = () => setIsModalOpen(true);

  const handleUpload = ({ file }) => {
    setUploading(true);
    updateMembershipProfilePhoto(memberId, file)
      .then(() => {
        setProfilePhotoUrl(URL.createObjectURL(file));
        toast.success("Profile photo updated successfully!");
        refreshProfile?.();
      })
      .catch(() => toast.error("Failed to upload profile photo."))
      .finally(() => {
        setUploading(false);
        setIsModalOpen(false);
      });
  };

  const handleCapturePhoto = () => {
    setIsCaptureMode(true);
    navigator.mediaDevices
      ?.getUserMedia({ video: true })
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
          setVideoVisible(true);
        }
      })
      .catch(() => message.error("Error accessing the camera"));
  };

  const handleCapture = () => {
    const context = canvasRef.current.getContext("2d");
    context.drawImage(
      videoRef.current,
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );
    setImageCaptured(true);
    setVideoVisible(false);
  };

  const handleConfirmUpload = () => {
    canvasRef.current.toBlob(
      (blob) => blob && handleUpload({ file: blob }),
      "image/png"
    );
    stopVideoStream();
    setIsCaptureMode(false);
  };

  const stopVideoStream = () => {
    videoRef.current?.srcObject?.getTracks().forEach((track) => track.stop());
    setVideoVisible(false);
  };

  useEffect(() => () => stopVideoStream(), []);

  return (
    <>
      <img
        src={profilePhotoUrl}
        alt="Profile"
        className="profile-photo"
        onClick={handleUploadClick}
      />

      <Modal
        title="Update Profile Photo"
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          stopVideoStream();
        }}
        footer={null}
      >
        {!isCaptureMode ? (
          <div className="upload-options">
            <Upload
              name="profilePhoto"
              customRequest={handleUpload}
              showUploadList={false}
            >
              <Button
                icon={<UploadOutlined />}
                loading={uploading}
                className="upload-button"
              >
                {uploading ? "Uploading..." : "Upload Photo"}
              </Button>
            </Upload>
            <Button
              icon={<CameraOutlined />}
              onClick={handleCapturePhoto}
              className="upload-button"
            >
              Capture Photo
            </Button>
          </div>
        ) : (
          <>
            <div className="video-canvas-container">
              <video
                ref={videoRef}
                className="video-canvas"
                style={{ display: videoVisible ? "block" : "none" }}
              />
              <canvas
                ref={canvasRef}
                className="video-canvas"
                style={{ display: imageCaptured ? "block" : "none" }}
                width="300"
                height="300"
              ></canvas>
            </div>
            <div className="recapture-upload-buttons">
              {imageCaptured ? (
                <>
                  <Button
                    onClick={() =>
                      setImageCaptured(false) || setVideoVisible(true)
                    }
                    className="upload-button"
                  >
                    Recapture
                  </Button>
                  <Button
                    type="primary"
                    onClick={handleConfirmUpload}
                    className="upload-button"
                  >
                    Upload
                  </Button>
                </>
              ) : (
                <Button
                  icon={<CameraOutlined />}
                  onClick={handleCapture}
                  className="upload-button"
                >
                  Capture
                </Button>
              )}
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default ProfilePhotoUploader;
