import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  Drawer,
  Button,
  Input,
  Select,
  Row,
  Col,
  Dropdown,
  Menu,
  Modal,
} from "antd";
import { PlusOutlined, CheckCircleOutlined } from "@ant-design/icons";
import EmployeeEditForm from "./EmployeeEditForm";
import ImportEmployees from "./ImportEmployees";
import useIsMobile from "../hooks/useIsMobile";
import EmployeeCardList from "./EmployeeCardList";
import { useAbility } from "../hooks/useAbility";
import { createEmployee, updateEmployee } from "../api/EmployeeAPI";
import { getFullName } from "../helpers/childHelper";
import { formatPhoneNumber } from "../helpers/formatHelper";

const { Search } = Input;
const { Option } = Select;

const EmployeeList = ({
  employees,
  roles,
  refreshEmployees,
  showNewButton = false,
  showSortOption = false,
  showSearchBar = false,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState("alphabetic");
  const [titleFilter, setTitleFilter] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const isMobile = useIsMobile();
  const ability = useAbility();

  const uniqueTitles = Array.from(
    new Set(employees.map((employee) => employee.employee_title))
  )
    .filter((title) => title)
    .sort((a, b) => a.localeCompare(b));

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSortChange = (value) => {
    setSortOption(value);
  };

  const handleTitleFilterChange = (value) => {
    setTitleFilter(value);
  };

  const sortEmployees = (employees) => {
    switch (sortOption) {
      case "alphabetic":
        return employees.sort((a, b) =>
          a.first_name.localeCompare(b.first_name)
        );
      case "title":
        return employees.sort((a, b) =>
          (a.employee_title || "").localeCompare(b.employee_title || "")
        );
      case "employee_id":
        return employees.sort((a, b) => a.eid.localeCompare(b.eid));
      default:
        return employees;
    }
  };

  const filteredEmployees = sortEmployees(
    employees
      .filter((employee) =>
        employee.first_name.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .filter((employee) =>
        titleFilter ? employee.employee_title === titleFilter : true
      )
  );

  const handleRowClick = (record) => {
    if (ability.can("update", "employee")) {
      setSelectedEmployee(record);
      setIsDrawerOpen(true);
    }
  };

  const handleNewEmployee = () => {
    setSelectedEmployee({});
    setIsDrawerOpen(true);
  };

  const handleFormFinish = (employeeData) => {
    if (selectedEmployee && selectedEmployee.id) {
      return updateEmployee(selectedEmployee.employee_id, employeeData).then(
        () => {
          setIsDrawerOpen(false);
          refreshEmployees();
        }
      );
    } else {
      return createEmployee(employeeData).then(() => {
        setIsDrawerOpen(false);
        refreshEmployees();
      });
    }
  };

  const menu = (
    <Menu>
      <Menu.Item key="new-employee" onClick={handleNewEmployee}>
        New Staff Member
      </Menu.Item>
      <Menu.Item key="import-employees" onClick={() => setIsModalOpen(true)}>
        Import
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      key: "sl_no",
      title: "",
      width: 50,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      width: 200,
      render: (text, record) => (
        <div>
          <Link to={`/staff-members/${record.employee_id}`}>
            {getFullName(record)}
          </Link>
          <div style={{ color: "grey", fontSize: "12px" }}>{record.eid}</div>
        </div>
      ),
    },
    {
      title: "Title",
      dataIndex: "employee_title",
      key: "employee_title",
      width: 150,
    },
    ...(ability.can("create", "employee")
      ? [
          {
            title: "Role",
            dataIndex: "role",
            key: "role",
            width: 150,
          },
        ]
      : []),
    {
      title: "Contact",
      dataIndex: "phone_number",
      key: "contact",
      width: 200,
      render: (text, record) => (
        <div>
          <a href={`tel:${record.phone_number}`}>
            {formatPhoneNumber(record.phone_number)}
          </a>
          <br />
          {record.email && (
            <>
              {record.user_id && (
                <CheckCircleOutlined
                  style={{ color: "green", marginRight: 8 }}
                />
              )}
              {record.email}
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="table-view">
      <Row
        gutter={16}
        align="middle"
        className="controls"
        style={{ marginBottom: 16, flexWrap: "nowrap" }}
      >
        {showNewButton && ability.can("create", "employee") && (
          <Col flex="40px">
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button type="default" icon={<PlusOutlined />}></Button>
            </Dropdown>
          </Col>
        )}
        {showSearchBar && (
          <Col flex="auto">
            <Search
              placeholder="Search by name"
              allowClear
              onChange={handleSearch}
            />
          </Col>
        )}
        {showSortOption && (
          <Col flex="100px">
            <Select
              defaultValue=""
              style={{ width: 150 }}
              onChange={handleTitleFilterChange}
            >
              <Option value="">All Titles</Option>
              {uniqueTitles.map((title) => (
                <Option key={title} value={title}>
                  {title}
                </Option>
              ))}
            </Select>
          </Col>
        )}
        {showSortOption && !isMobile && (
          <Col flex="100px">
            <Select
              defaultValue="alphabetic"
              style={{ width: 150 }}
              onChange={handleSortChange}
            >
              <Option value="alphabetic">A-Z</Option>
              <Option value="employee_id">ID</Option>
              <Option value="title">Title</Option>
            </Select>
          </Col>
        )}
      </Row>

      {/* Render as cards if isMobile is true, otherwise render as a table */}
      <div className="table-container">
        {isMobile ? (
          <EmployeeCardList
            employees={filteredEmployees}
            onEmployeeClick={handleRowClick}
            ability={ability}
          />
        ) : (
          <Table
            className="custom-table"
            columns={columns}
            dataSource={filteredEmployees}
            rowKey="employee_id"
            rowClassName={(record, index) =>
              index % 2 === 0 ? "row-even" : "row-odd"
            }
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
            pagination={
              filteredEmployees.length > 10 ? { pageSize: 10 } : false
            }
            scroll={{ x: "max-content", y: 560 }}
            sticky={{ offsetHeader: 64 }}
          />
        )}
      </div>

      <Drawer
        width={350}
        onClose={() => {
          setIsDrawerOpen(false);
          setSelectedEmployee(null);
        }}
        open={isDrawerOpen}
        closable={false}
      >
        {isDrawerOpen && (
          <EmployeeEditForm
            employee={selectedEmployee}
            roles={roles}
            onFinish={handleFormFinish}
            refreshEmployees={refreshEmployees}
            title={
              selectedEmployee && selectedEmployee.id
                ? getFullName(selectedEmployee)
                : "New Staff Member"
            }
          />
        )}
      </Drawer>

      <Modal
        title="Import Staff"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <ImportEmployees />
      </Modal>
    </div>
  );
};

export default EmployeeList;
