import React from "react";
import { Select } from "antd";

const { Option } = Select;

const SelectInput = ({ placeholder, value, onChange, options }) => (
  <Select
    placeholder={placeholder}
    value={value || undefined}
    onChange={onChange}
    style={{ width: "100%" }}
  >
    {options.map(({ id, name }) => (
      <Option key={id} value={id}>
        {name}
      </Option>
    ))}
  </Select>
);

export default SelectInput;
