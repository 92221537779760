const names = ["Aarav", "Aditi", "Meera", "Rohan", "Ananya", "Rahul", "Priya"];

export const extractNames = (text) => {
  const extractedNames = [];

  // Split the text into words
  const words = text.split(/\s+/);

  words.forEach((word) => {
    const cleanedWord = word.replace(/[^a-zA-Z]/g, ""); // Remove punctuation
    if (names.includes(cleanedWord)) {
      extractedNames.push(cleanedWord);
    }
  });

  return extractedNames;
};
