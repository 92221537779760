export const getFilteredChildren = (children, childSearch) => {
  return Object.entries(children)
    .map(([child_id, child]) => ({
      child_id,
      first_name: child.first_name,
      date_of_birth: child.date_of_birth,
    }))
    .filter((child) =>
      child.first_name.toLowerCase().includes(childSearch.toLowerCase())
    );
};

export const getSortedChildren = (filteredChildren, sortOption) => {
  return filteredChildren.sort((a, b) => {
    switch (sortOption) {
      case "ageLowToHigh":
        return new Date(b.date_of_birth) - new Date(a.date_of_birth);
      case "ageHighToLow":
        return new Date(a.date_of_birth) - new Date(b.date_of_birth);
      default:
        return a.first_name.localeCompare(b.first_name);
    }
  });
};

export const getFullName = (data) => {
  return data.last_name
    ? `${data.first_name} ${data.last_name}`
    : data.first_name;
};
