import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  Drawer,
  Button,
  Input,
  Row,
  Col,
  Dropdown,
  Menu,
  Modal,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ChildEditForm from "./ChildEditForm";
import ImportChildren from "./ImportChildren";
import ChildCardList from "./ChildCardList";
import { createChild, updateChild } from "../api/ChildAPI";
import { formatDate, calculateAge } from "../helpers/dateTimeHelper";
import "../css/ChildList.css";
import { getFullName } from "../helpers/childHelper";
import { formatPhoneNumber } from "../helpers/formatHelper";
import { useAbility } from "../hooks/useAbility";
import useIsMobile from "../hooks/useIsMobile";
import ChildSortOptions from "../components/ChildSortOptions";
import { getSortedChildren } from "../helpers/childHelper";

const { Search } = Input;

const ChildList = ({
  children,
  refreshChildren,
  showNewButton = false,
  showSortOption = false,
  showSearchBar = false,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState("ageLowToHigh");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedChild, setSelectedChild] = useState(null);

  const isMobile = useIsMobile();
  const ability = useAbility();

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSortChange = (value) => {
    setSortOption(value);
  };

  const filteredChildren = children.filter((child) =>
    child.first_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sortedChildren = getSortedChildren(filteredChildren, sortOption);

  const handleRowClick = (record) => {
    if (ability.can("update", "children")) {
      setSelectedChild(record);
      setIsDrawerOpen(true);
    }
  };

  const handleNewChild = () => {
    if (ability.can("update", "children")) {
      setSelectedChild({});
      setIsDrawerOpen(true);
    }
  };

  const handleFormFinish = (childData) => {
    if (selectedChild && selectedChild.id) {
      return updateChild(selectedChild.child_id, childData).then(() => {
        setIsDrawerOpen(false);
        refreshChildren();
      });
    } else {
      return createChild(childData).then(() => {
        setIsDrawerOpen(false);
        refreshChildren();
      });
    }
  };

  const menu = (
    <Menu>
      <Menu.Item key="new-child" onClick={handleNewChild}>
        New Child
      </Menu.Item>
      <Menu.Item key="import-children" onClick={() => setIsModalOpen(true)}>
        Import
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      key: "sl_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record) => (
        <div>
          <Link to={`/children/${record.child_id}`} className="child-link">
            {getFullName(record)}
          </Link>
          <div style={{ color: "grey", fontSize: "12px" }}>{record.sid}</div>{" "}
        </div>
      ),
    },
    {
      title: "DOB",
      dataIndex: "date_of_birth",
      key: "date_of_birth",
      render: (text) => (
        <div>
          <div>{formatDate(text)}</div>
          <div>{calculateAge(text)}</div>
        </div>
      ),
    },
    {
      title: "Mother",
      key: "mother",
      render: (text, record) => (
        <div>
          <div>{record.mother_name}</div>
          <div>
            <a href={`tel:${record.mother_phone}`}>
              {formatPhoneNumber(record.mother_phone)}
            </a>
          </div>
          <div>{record.mother_email}</div>
        </div>
      ),
    },
    {
      title: "Father",
      key: "father",
      render: (text, record) => (
        <div>
          <div>{record.father_name}</div>
          <div>
            <a href={`tel:${record.father_phone}`}>
              {formatPhoneNumber(record.father_phone)}
            </a>
          </div>
          <div>{record.father_email}</div>
        </div>
      ),
    },
  ];

  return (
    <div className="table-view">
      <Row
        gutter={16}
        align="middle"
        className="controls"
        style={{ marginBottom: 16, flexWrap: "nowrap" }}
      >
        {showNewButton && ability.can("create", "children") && (
          <Col flex="40px">
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button type="default" icon={<PlusOutlined />}></Button>
            </Dropdown>
          </Col>
        )}
        {showSearchBar && (
          <Col flex="auto">
            <Search
              placeholder="Search by name"
              allowClear
              onChange={handleSearch}
            />
          </Col>
        )}
        {showSortOption && (
          <Col flex="100px">
            <ChildSortOptions value={sortOption} onChange={handleSortChange} />
          </Col>
        )}
      </Row>

      <div className="table-container">
        {isMobile ? (
          <ChildCardList
            children={sortedChildren}
            onChildClick={handleRowClick}
            ability={ability}
          />
        ) : (
          <Table
            className="custom-table"
            columns={columns}
            dataSource={sortedChildren}
            rowKey="child_id"
            rowClassName={(record, index) =>
              index % 2 === 0 ? "row-even" : "row-odd"
            }
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
            pagination={sortedChildren.length > 10 ? { pageSize: 10 } : false}
            scroll={{ x: "max-content", y: 560 }}
            sticky={{ offsetHeader: 64 }}
          />
        )}
      </div>

      {ability.can("update", "children") && (
        <Drawer
          width={350}
          onClose={() => {
            setIsDrawerOpen(false);
            setSelectedChild(null);
          }}
          open={isDrawerOpen}
          closable={false}
        >
          {isDrawerOpen && (
            <ChildEditForm
              child={selectedChild}
              onFinish={handleFormFinish}
              refreshChildren={refreshChildren}
              title={
                selectedChild && selectedChild.id
                  ? getFullName(selectedChild)
                  : "New Child"
              }
            />
          )}
        </Drawer>
      )}

      <Modal
        title="Import Children"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <ImportChildren />
      </Modal>
    </div>
  );
};

export default ChildList;
