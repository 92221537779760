import React, { useState, useEffect } from "react";
import { DatePicker, Button } from "antd";
import {
  LeftOutlined,
  RightOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import { getGuardAttendanceReportByDate } from "../../api/GuardAPI";
import { formatTime } from "../../helpers/dateTimeHelper";
import "../../css/guards/GuardAttendanceDailyReport.css";
import moment from "moment";
import LoadingSpinner from "../../components/LoadingSpinner";

const GuardAttendanceDailyReport = () => {
  const [attendanceData, setAttendanceData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    moment().format("DD-MM-YYYY")
  );
  const [selectedImage, setSelectedImage] = useState(null);
  const hours = Array.from(
    { length: 24 },
    (_, i) => `${i.toString().padStart(2, "0")}:00`
  );

  useEffect(() => {
    const fetchReport = async () => {
      const formattedDate = moment(selectedDate, "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      );

      try {
        const data = await getGuardAttendanceReportByDate(formattedDate);
        setAttendanceData(data);

        console.log("attendanceData", data);
      } catch (error) {
        console.error("Error fetching attendance report:", error);
      }
    };
    fetchReport();
  }, [selectedDate]);

  const handleDateChange = (date) => {
    if (date) {
      setSelectedDate(date.format("DD-MM-YYYY"));
    }
  };

  const handlePreviousDate = () => {
    const newDate = moment(selectedDate, "DD-MM-YYYY")
      .subtract(1, "days")
      .format("DD-MM-YYYY");
    setSelectedDate(newDate);
  };

  const handleNextDate = () => {
    const newDate = moment(selectedDate, "DD-MM-YYYY")
      .add(1, "days")
      .format("DD-MM-YYYY");
    setSelectedDate(newDate);
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleCloseViewer = () => {
    setSelectedImage(null);
  };

  if (!attendanceData) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <h2>Attendance Report for {selectedDate}</h2>
      <div className="report-controls">
        <Button
          icon={<LeftOutlined />}
          onClick={handlePreviousDate}
          style={{ marginRight: 10 }}
        />
        <DatePicker
          value={moment(selectedDate, "DD-MM-YYYY")}
          format="DD-MM-YYYY"
          onChange={handleDateChange}
        />
        <Button
          icon={<RightOutlined />}
          onClick={handleNextDate}
          style={{ marginLeft: 10 }}
        />
      </div>

      <div className="table-container">
        <table className="attendance-table">
          <thead>
            <tr>
              <th>Hour</th>
              {attendanceData.map((guard) => (
                <th key={guard.guard_id}>{guard.guard_name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Summary</td>
              {attendanceData.map((guard) => (
                <td key={guard.guard_id}>
                  Present: {guard.present_count} ({guard.present_percent}%)
                  <br />
                  Late: {guard.late_count} ({guard.late_percent}%)
                </td>
              ))}
            </tr>
            {hours.map((hour) => (
              <tr key={hour}>
                <td>{hour}</td>
                {attendanceData.map((guard) => {
                  const status = guard.hourly_statuses.find(
                    (h) => h.hour === hour
                  );
                  const cellClass = status
                    ? status.status === "absent" || status.status === "late"
                      ? "absent-late"
                      : ""
                    : "no-data";
                  return (
                    <td key={guard.guard_id} className={cellClass}>
                      {status ? (
                        <>
                          {status.selfie_url ? (
                            <div>
                              <img
                                src={status.selfie_url}
                                alt="selfie"
                                className="selfie-image"
                                onClick={() =>
                                  handleImageClick(status.selfie_url)
                                }
                              />
                              <div>
                                {status.latitude && status.longitude && (
                                  <a
                                    href={`https://www.google.com/maps?q=${status.latitude},${status.longitude}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <EnvironmentOutlined />
                                  </a>
                                )}
                                {formatTime(status.time)}
                              </div>
                            </div>
                          ) : (
                            <span>{status.status}</span>
                          )}
                        </>
                      ) : (
                        <span></span>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {selectedImage && (
        <div className="image-viewer-overlay" onClick={handleCloseViewer}>
          <div className="image-viewer-content">
            <img src={selectedImage} alt="Large view" />
          </div>
        </div>
      )}
    </div>
  );
};

export default GuardAttendanceDailyReport;
