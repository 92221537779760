import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import ChildList from "../components/ChildList";
import LoadingSpinner from "../components/LoadingSpinner";
import { getGuardianById } from "../api/GuardianAPI";
import { getFullName } from "../helpers/childHelper";

const GuardianPage = () => {
  const { guardianId } = useParams();
  const [guardian, setGuardian] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGuardian = async () => {
      try {
        const data = await getGuardianById(guardianId);
        setGuardian(data);
      } catch (error) {
        console.error("Error fetching guardian:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchGuardian();
  }, [guardianId]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <Helmet>
        <title>Guardian | Maidan</title>
      </Helmet>

      <h1 className="page-heading">{getFullName(guardian)}</h1>

      <h3>Children</h3>
      <ChildList children={guardian.children} />
    </div>
  );
};

export default GuardianPage;
