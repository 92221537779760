import axios from "axios";

const baseURL = "/library/categories";

const api = axios.create({
  baseURL,
});

export const createCategory = async (categoryData) => {
    try {
      const response = await api.post("/", categoryData);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  export const getCategories = async () => {
    try {
      const response = await api.get("/");
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  export const getCategoryById = async (categoryId = "") => {
    try {
      const response = await api.get(`/${categoryId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  export const updateCategory = async (categoryId, categoryData) => {
    try {
      const response = await api.put(`/${categoryId}`, categoryData);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  // Map a Book to an Category
  export const mapBooksToCategory = async (categoryId, bookId) => {
    try {
      const response = await api.post(
        `/${categoryId}/books/${bookId}/map`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  // Unmap a Book from an Category
  export const unmapBooksToCategory = async (categoryId, bookId) => {
    try {
      const response = await api.delete(
        `/${categoryId}/books/${bookId}/unmap`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  // Get Books mapped to an Category
  export const getBooksByCategory = async (categoryId) => {
    try {
      const response = await api.get(`/${categoryId}/books`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  // Get Books not mapped to an Category
  export const getBooksNotByCategory = async (categoryId) => {
    try {
      const response = await api.get(
        `/${categoryId}/books/not-mapped`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  