import moment from "moment";

export const formatDate = (dateString) => {
  if (!dateString) {
    return "";
  }
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = String(date.getFullYear()).slice(-2);
  return `${day}-${month}-${year}`;
};

export const formatDateToDDMM = (dateString) => {
  const options = { day: "2-digit", month: "2-digit" };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", options);
};

export const getDayOfWeek = (date) => {
  return moment(date).format("ddd");
};

export const calculateAge = (dob) => {
  if (!dob) {
    return "";
  }

  const birthDate = new Date(dob);
  const today = new Date();
  let years = today.getFullYear() - birthDate.getFullYear();
  let months = today.getMonth() - birthDate.getMonth();

  if (months < 0 || (months === 0 && today.getDate() < birthDate.getDate())) {
    years--;
    months += 12;
  }

  return `${years}y ${months}m`;
};

export const formatTime = (timeString) => {
  if (!timeString) return "";
  const date = new Date(timeString);
  return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
};

export const formatTimeToString = (time) => {
  return time ? moment(time).format("HH:mm") : null;
};

export const timeStringToMoment = (timeString) => {
  return timeString ? moment(timeString, "HH:mm") : null;
};
