import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import VoiceRecognitionComponent from "../../components/copilot/VoiceRecognitionComponent";
import IntentHandlerComponent from "../../components/copilot/IntentHandlerComponent";
import ConfirmationModalComponent from "../../components/copilot/ConfirmationModalComponent";

const CopilotPage = () => {
  const [recognizedText, setRecognizedText] = useState("");
  const [intent, setIntent] = useState(null);
  const [actionData, setActionData] = useState(null);
  const [isConfirming, setIsConfirming] = useState(false);

  const handleVoiceInput = (text) => {
    setRecognizedText(text);
    const { detectedIntent, data } = IntentHandlerComponent.parseIntent(text);
    setIntent(detectedIntent);
    setActionData(data);
    setIsConfirming(true);
  };

  const handleConfirmation = (confirmed) => {
    if (confirmed) {
      IntentHandlerComponent.executeAction(intent, actionData);
    }
    setIsConfirming(false);
  };

  return (
    <div>
      <Helmet>
        <title>Copilot | Maidan</title>
      </Helmet>

      <h1 className="page-heading">Say it, see it done.</h1>

      <VoiceRecognitionComponent onVoiceInput={handleVoiceInput} />
      {isConfirming && (
        <ConfirmationModalComponent
          intent={intent}
          actionData={actionData}
          onConfirm={handleConfirmation}
        />
      )}
    </div>
  );
};

export default CopilotPage;
