import React from "react";
import { Form, Input, Button, Typography } from "antd";
import { toast } from "react-toastify";

const { Title } = Typography;

const ClassroomEditForm = ({
  classroom = {},
  onFinish,
  refreshClassrooms,
  title,
}) => {
  const handleFinish = (classroomData) => {
    onFinish(classroomData)
      .then(() => {
        refreshClassrooms();
        toast.success(
          <>
            Classroom <b>{classroomData.name}</b>{" "}
            {classroom.id ? "updated" : "created"} successfully!
          </>
        );
      })
      .catch((error) => {
        console.error("Error handling classroom:", error);
        toast.error(
          <>
            Failed to {classroom.id ? "update" : "create"} classroom{" "}
            <b>{classroomData.name}</b>.
          </>
        );
      });
  };

  return (
    <>
      <Title level={2}>{title}</Title>
      <Form
        initialValues={classroom}
        onFinish={handleFinish}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please input the name." }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {classroom.id ? "Update Classroom" : "Create Classroom"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ClassroomEditForm;
