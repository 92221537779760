// client/src/pages/PublicLandingPage.js
import React from "react";
import { PhoneOutlined } from "@ant-design/icons";
import "../css/PublicLandingPage.css";

const PublicLandingPage = () => {
  const handleLogin = () => {
    const backendUrl = process.env.REACT_APP_BASE_SERVER_URL || "";
    window.location.href = `${backendUrl}/auth/google`;
  };

  return (
    <div className="public-landing-page">
      <div className="header">
        <div className="public-landing-page-logo">maidan</div>
        <a href="tel:6363010101" className="contact-number">
          <PhoneOutlined style={{ marginRight: "8px" }} />
          63630 10101
        </a>
      </div>
      <div className="content">
        <h1>
          Empowering Montessori Educators <br /> to Unlock Every Child’s
          Potential
        </h1>
        <button className="cta-button" onClick={handleLogin}>
          Join Now
        </button>
      </div>
      <div className="footer">
        &copy; {new Date().getFullYear()} IdeaBoard Technologies Pvt Ltd. All
        rights reserved.
      </div>
    </div>
  );
};

export default PublicLandingPage;
