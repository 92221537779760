// client/src/components/Sidebar.js
import React, { useState, useEffect, useRef } from "react";
import { Layout, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { LogoutOutlined, ThunderboltOutlined } from "@ant-design/icons";
import Divider from "@mui/material/Divider";
import { useAbility } from "../hooks/useAbility";
import UserInfo from "./UserInfo";
import { sidebarItems } from "../config/sidebarItems";
import { sidebarStructure } from "../config/sidebarStructure";
import { trackEvent } from "../utils/mixpanel";
import "../css/Sidebar.css";

const { Sider } = Layout;

const Sidebar = ({
  isMobile,
  isVisible,
  onLogout,
  onToggle,
  onMenuItemClick,
  user,
}) => {
  const sidebarRef = useRef(null);
  const ability = useAbility();
  const location = useLocation();

  const [selectedKeys, setSelectedKeys] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        isVisible &&
        isMobile
      ) {
        onToggle();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, onToggle, isMobile]);

  useEffect(() => {
    const path = location.pathname;
    const splitPath = path.split("/")[1]==="library" ? path.split("/")[2] : path.split("/")[1];

    setSelectedKeys([splitPath]);

    const role = user?.role;
    const structure = sidebarStructure[role] || [];

    const openSubmenus = structure
      .filter(
        (item) => item.type === "submenu" && item.children.includes(splitPath)
      )
      .map((submenu) => submenu.key);

    setOpenKeys(openSubmenus);
  }, [location.pathname, user]);

  const handleMenuItemClick = (menuItemKey) => {
    trackEvent(
      "Sidebar Item Clicked",
      {
        item: menuItemKey,
      },
      user
    );
    if (isMobile) {
      onMenuItemClick();
    }
  };

  const handleSubMenuToggle = (key) => {
    if (openKeys.includes(key)) {
      setOpenKeys(openKeys.filter((openKey) => openKey !== key));
    } else {
      setOpenKeys([...openKeys, key]);
    }
  };

  const getSidebarItems = () => {
    const role = user?.role;
    const structure = sidebarStructure[role] || [];

    return structure.map((item) => {
      if (item.type === "item") {
        const menuItem = sidebarItems.find((i) => i.key === item.key);
        return (
          ability.can("access", menuItem.key) && (
            <Menu.Item key={menuItem.key} icon={menuItem.icon}>
              <Link
                to={menuItem.path}
                onClick={() => handleMenuItemClick(menuItem.key)}
              >
                {menuItem.label}
              </Link>
            </Menu.Item>
          )
        );
      } else if (item.type === "submenu") {
        return (
          <Menu.SubMenu
            key={item.key}
            icon={sidebarItems.find((i) => i.key === item.children[0]).icon}
            title={item.label}
            onTitleClick={() => handleSubMenuToggle(item.key)}
          >
            {item.children.map((subItemKey) => {
              const subItem = sidebarItems.find((i) => i.key === subItemKey);
              return (
                ability.can("access", subItem.key) && (
                  <Menu.Item key={subItem.key} icon={subItem.icon}>
                    <Link
                      to={subItem.path}
                      onClick={() => handleMenuItemClick(subItem.key)}
                    >
                      {subItem.label}
                    </Link>
                  </Menu.Item>
                )
              );
            })}
          </Menu.SubMenu>
        );
      }
      return null;
    });
  };

  return (
    <Sider
      ref={sidebarRef}
      className={`sidebar ${isVisible ? "visible" : "hidden"}`}
      collapsible={false}
      collapsedWidth={0}
      width={200}
    >
      {!isMobile && (
        <div className="sidebar-header">
          <Link to="/" className="sidebar-logo">
            maidan
          </Link>
          <Link to="/copilot" className="copilot-icon-link">
            <ThunderboltOutlined className="copilot-icon" />
          </Link>
        </div>
      )}
      <Divider />
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={selectedKeys}
        openKeys={openKeys}
        onOpenChange={(keys) => setOpenKeys(keys)}
      >
        {getSidebarItems()}
        {/* Logout */}
        <Menu.Item
          key="logout"
          icon={<LogoutOutlined />}
          onClick={() => {
            onLogout();
            handleMenuItemClick("logout"); // Track logout
          }}
        >
          Logout
        </Menu.Item>
      </Menu>
      {process.env.NODE_ENV === "development" && <UserInfo user={user} />}
    </Sider>
  );
};

export default Sidebar;
