// src/components/NationalityDropdown.js
import React from "react";
import { Select } from "antd";
import { countries } from "../helpers/countryAndTimeZoneHelper";

const { Option } = Select;

const NationalityDropdown = ({ value, onChange }) => (
  <Select
    value={value}
    onChange={onChange}
    showSearch
    placeholder="Select Nationality"
    optionFilterProp="children"
    filterOption={(input, option) =>
      option.children.toLowerCase().includes(input.toLowerCase())
    }
    style={{ textAlign: "left" }}
  >
    {countries.map((country) => (
      <Option key={country} value={country}>
        {country}
      </Option>
    ))}
  </Select>
);

export default NationalityDropdown;
