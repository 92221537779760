import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import DomainList from "../components/DomainList";
import MasteryLevelList from "../components/MasteryLevelList";
import LoadingSpinner from "../components/LoadingSpinner";
import { getDomains } from "../api/DomainAPI";
import { getMasteryLevels } from "../api/MasteryLevelAPI";

const DomainsPage = () => {
  const [domains, setDomains] = useState([]);
  const [masteryLevels, setMasteryLevels] = useState([]);
  const [loading, setLoading] = useState(true);

  const refreshDomains = async () => {
    try {
      const data = await getDomains();
      setDomains(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshMasteryLevels = async () => {
    try {
      const data = await getMasteryLevels();
      setMasteryLevels(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refreshDomains();
    refreshMasteryLevels();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <Helmet>
        <title>Domains | Maidan</title>
      </Helmet>
      <h1 className="page-heading">Domains</h1>
      <DomainList
        domains={domains}
        refreshDomains={refreshDomains}
        showNewButton={true}
        showSearchBar={true}
      />

      <div style={{ height: "40px" }}></div>

      <h1 className="page-heading">Mastery Levels</h1>
      <MasteryLevelList
        masteryLevels={masteryLevels}
        refreshMasteryLevels={refreshMasteryLevels}
      />
    </div>
  );
};

export default DomainsPage;
