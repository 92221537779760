import React from "react";
import { Row, Col, Select } from "antd";
import ChildSortOptions from "../../components/ChildSortOptions";
import SelectInput from "../../components/SelectInput";
import SearchInput from "../../components/SearchInput";

const FilterSection = ({ filters, handleChange, data, isMobile }) => {
  return (
    <>
      {/* Desktop View Filters */}
      {!isMobile ? (
        <Row gutter={8} style={{ marginBottom: 16 }}>
          {filters.shouldShowClassroomSelector && (
            <Col span={4}>
              <SelectInput
                placeholder="Select Classroom"
                value={filters.selectedClassroom}
                onChange={handleChange("selectedClassroom")}
                options={data.classrooms}
              />
            </Col>
          )}
          <Col span={4}>
            <SelectInput
              placeholder="Select Domain"
              value={filters.selectedDomain}
              onChange={handleChange("selectedDomain")}
              options={data.domains}
            />
          </Col>
          <Col span={4}>
            <Select
              mode="multiple"
              placeholder="Select Levels"
              value={filters.selectedLevels}
              onChange={handleChange("selectedLevels")}
              options={data.masteryLevels.map((level) => ({
                label: level.level + ". " + level.name,
                value: level.level,
              }))}
              style={{ width: "100%" }}
              allowClear
            />
          </Col>
          <Col span={4}>
            <SearchInput
              placeholder="Search Concepts"
              onChange={handleChange("conceptSearch")}
            />
          </Col>
          <Col span={4}>
            <SearchInput
              placeholder="Search Children"
              onChange={handleChange("childSearch")}
            />
          </Col>
          <Col span={4}>
            <ChildSortOptions
              value={filters.sortOption}
              onChange={handleChange("sortOption")}
            />
          </Col>
        </Row>
      ) : (
        <>
          {/* Mobile View Filters: First Row */}
          <Row gutter={8} style={{ marginBottom: 16 }}>
            {filters.shouldShowClassroomSelector ? (
              <>
                <Col span={8}>
                  <SelectInput
                    placeholder="Select Classroom"
                    value={filters.selectedClassroom}
                    onChange={handleChange("selectedClassroom")}
                    options={data.classrooms}
                  />
                </Col>
                <Col span={8}>
                  <SelectInput
                    placeholder="Select Domain"
                    value={filters.selectedDomain}
                    onChange={handleChange("selectedDomain")}
                    options={data.domains}
                  />
                </Col>
                <Col span={8}>
                  <Select
                    mode="multiple"
                    placeholder="Select Levels"
                    value={filters.selectedLevels}
                    onChange={handleChange("selectedLevels")}
                    options={data.masteryLevels.map((level) => ({
                      label: level.level + ". " + level.name,
                      value: level.level,
                    }))}
                    style={{ width: "100%" }}
                    allowClear
                  />
                </Col>
              </>
            ) : (
              <>
                <Col span={12}>
                  <SelectInput
                    placeholder="Select Domain"
                    value={filters.selectedDomain}
                    onChange={handleChange("selectedDomain")}
                    options={data.domains}
                  />
                </Col>
                <Col span={12}>
                  <Select
                    mode="multiple"
                    placeholder="Select Levels"
                    value={filters.selectedLevels}
                    onChange={handleChange("selectedLevels")}
                    options={data.masteryLevels.map((level) => ({
                      label: level.level + ". " + level.name,
                      value: level.level,
                    }))}
                    style={{ width: "100%" }}
                    allowClear
                  />
                </Col>
              </>
            )}
          </Row>

          {/* Mobile View Filters: Second Row */}
          <Row gutter={8} style={{ marginBottom: 16 }}>
            <Col span={12}>
              <SearchInput
                placeholder="Search Children"
                onChange={handleChange("childSearch")}
              />
            </Col>
            <Col span={12}>
              <SearchInput
                placeholder="Search Concepts"
                onChange={handleChange("conceptSearch")}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default FilterSection;
