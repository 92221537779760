// src/components/ConceptList.js
import React, { useState, useEffect } from "react";
import { Table, Drawer, Button, Input, Row, Col, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { createConcept, updateConcept } from "../api/DomainAPI";
import ConceptEditForm from "./ConceptEditForm";
import { useAbility } from "../hooks/useAbility";
import useIsMobile from "../hooks/useIsMobile";
import "../css/ConceptList.css";

const { Search } = Input;

const ConceptList = ({
  domainId,
  concepts,
  refreshConcepts,
  showNewButton = false,
  showSearchBar = false,
}) => {
  const [filteredConcepts, setFilteredConcepts] = useState(concepts || []);
  const [parentConcepts, setParentConcepts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedParent, setSelectedParent] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedConcept, setSelectedConcept] = useState(null);

  const ability = useAbility();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (domainId) {
      setFilteredConcepts(concepts);
      const parentData = concepts.filter(
        (concept) => concept.parent_id === null
      );
      setParentConcepts(parentData);
    }
  }, [domainId, concepts]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredConceptsList = Array.isArray(filteredConcepts)
    ? filteredConcepts.filter((concept) => {
        const matchesSearch = concept.name
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const matchesParent = selectedParent
          ? concept.parent_id === selectedParent
          : true;
        return matchesSearch && matchesParent;
      })
    : [];

  const handleRowClick = (record) => {
    if (ability.can("update", "concept")) {
      setSelectedConcept(record);
      setIsDrawerOpen(true);
    }
  };

  const handleNewConcept = () => {
    setSelectedConcept({});
    setIsDrawerOpen(true);
  };

  const handleFormFinish = (conceptData) => {
    const dataWithDomainId = { ...conceptData, domain_id: domainId };

    if (selectedConcept && selectedConcept.id) {
      return updateConcept(selectedConcept.id, dataWithDomainId).then(() => {
        setIsDrawerOpen(false);
        refreshConcepts();
      });
    } else {
      return createConcept(dataWithDomainId).then(() => {
        setIsDrawerOpen(false);
        refreshConcepts();
      });
    }
  };

  const columns = [
    {
      title: "Name",
      key: "sequence_name",
      align: "left",
      render: (text, record) => (
        <span className={record.parent_id ? "child-concept" : "root-concept"}>
          {record.formatted_sequence} {record.name}
        </span>
      ),
      width: 200,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "left",
      render: (text) => <span>{text}</span>,
      width: 300,
    },
  ];

  return (
    <div className="table-view">
      <Row
        gutter={8}
        align="middle"
        className="controls"
        wrap={false}
        style={{ marginBottom: 16 }}
      >
        {showNewButton && ability.can("create", "concept") && (
          <Col xs={4} sm={2} md={1}>
            <Button
              type="default"
              onClick={handleNewConcept}
              icon={<PlusOutlined />}
              block={isMobile}
            />
          </Col>
        )}
        <Col xs={10} sm={10} md={11}>
          <Select
            placeholder="Filter by Parent Concept"
            allowClear
            style={{ width: "100%" }}
            onChange={(value) => setSelectedParent(value)}
          >
            {parentConcepts.map((parent) => (
              <Select.Option key={parent.id} value={parent.id}>
                {parent.formatted_sequence} {parent.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        {showSearchBar && (
          <Col xs={10} sm={12} md={12}>
            <Search
              placeholder="Search by name"
              allowClear
              onChange={handleSearch}
              style={{ width: "100%" }}
            />
          </Col>
        )}
      </Row>
      <Table
        className="custom-table"
        columns={columns}
        dataSource={filteredConceptsList}
        rowKey="id"
        rowClassName={(record) => (record.parent_id ? "row-child" : "row-root")}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
        pagination={false}
      />
      <Drawer
        width={350}
        onClose={() => {
          setIsDrawerOpen(false);
          setSelectedConcept(null);
        }}
        open={isDrawerOpen}
        closable={false}
      >
        {isDrawerOpen && (
          <ConceptEditForm
            domainId={domainId}
            concept={selectedConcept}
            onFinish={handleFormFinish}
            refreshConcepts={refreshConcepts}
            title={
              selectedConcept && selectedConcept.id
                ? "Edit Concept"
                : "New Concept"
            }
          />
        )}
      </Drawer>
    </div>
  );
};

export default ConceptList;
