import React from "react";
import { Input } from "antd";

const AadhaarInput = ({ value, onChange }) => (
  <Input
    value={value}
    onChange={onChange}
    placeholder="Enter Aadhaar number"
    maxLength={12}
    pattern="^\d{12}$"
    title="The Aadhaar number must be 12 digits."
  />
);

export default AadhaarInput;
