// src/config/sidebarStructure.js
import { SmileOutlined, SettingOutlined, CalendarOutlined, BookOutlined } from "@ant-design/icons";

export const sidebarStructure = {
  Owner: [
    { type: "item", key: "dashboard" },
    {
      type: "submenu",
      key: "children-menu",
      label: "Children",
      icon: <SmileOutlined />,
      children: [
        "children",
        "record-keeper",
        "record-attendance",
        "attendance-report",
        "record-keeper-settings",
      ],
    },
    { type: "item", key: "guardians" },
    { type: "item", key: "staff-members" },
    { type: "item", key: "security-guards" },
    {
      type: "submenu",
      key: "library-menu",
      label: "Library",
      icon: <BookOutlined />,
      children: [
        "books",
        "authors",
        "collections",
        "publishers",
        "categories",
      ],
    },
    {
      type: "submenu",
      key: "admin-menu",
      label: "Admin",
      icon: <SettingOutlined />,
      children: ["classrooms", "school-info"],
    },
  ],
  Admin: [
    { type: "item", key: "dashboard" },
    { type: "item", key: "children" },
    { type: "item", key: "staff-members" },
    { type: "item", key: "security-guards" },
    {
      type: "submenu",
      key: "admin-menu",
      label: "Admin",
      icon: <SettingOutlined />,
      children: ["classrooms", "school-info"],
    },
  ],
  Teacher: [
    { type: "item", key: "dashboard" },
    { type: "item", key: "record-keeper" },
    { type: "item", key: "record-attendance" },
    { type: "item", key: "attendance-report" },
    { type: "item", key: "children" },
    { type: "item", key: "staff-members" },
    { type: "item", key: "record-keeper-settings" },
  ],
};
