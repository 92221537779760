import React from "react";
import { Form, TimePicker } from "antd";
import moment from "moment";

const GuardDataForm = () => {
  return (
    <>
      <Form.Item
        name="shift_start"
        label="Shift Start Time"
        rules={[
          { required: true, message: "Please select the shift start time." },
        ]}
      >
        <TimePicker
          format="HH:mm"
          use12Hours={false} // Ensure 24-hour format
          defaultValue={moment("00:00", "HH:mm")}
        />
      </Form.Item>

      <Form.Item
        name="shift_end"
        label="Shift End Time"
        rules={[
          { required: true, message: "Please select the shift end time." },
        ]}
      >
        <TimePicker
          format="HH:mm"
          use12Hours={false} // Ensure 24-hour format
          defaultValue={moment("00:00", "HH:mm")}
        />
      </Form.Item>
    </>
  );
};

export default GuardDataForm;
