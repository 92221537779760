// src/contexts/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { defineAbilitiesFor } from "../middleware/ability";

const AuthContext = createContext();

export { AuthContext };

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userAbility, setUserAbility] = useState(defineAbilitiesFor(null));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkLoggedIn = async () => {
      try {
        const response = await axios.get("/auth/status");
        if (response.data.isLoggedIn && response.data.user) {
          setCurrentUser(response.data.user);
          setUserAbility(defineAbilitiesFor(response.data.user));
        } else {
          setCurrentUser(null);
          setUserAbility(defineAbilitiesFor(null));
        }
        setLoading(false);
      } catch (error) {
        console.error("Failed to check login status", error);
        setUserAbility(defineAbilitiesFor(null));
        setLoading(false);
      }
    };

    checkLoggedIn();
  }, []);

  const handleLogout = () => {
    fetch("/auth/logout")
      .then((response) => {
        if (response.ok) {
          setCurrentUser(null);
          // Reset user ability to its initial state if needed
          setUserAbility(defineAbilitiesFor(null));
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  const value = {
    currentUser,
    userAbility,
    setCurrentUser,
    loading,
    handleLogout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
