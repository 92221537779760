// src/helpers/countryAndTimeZoneHelper.js

export const countries = [
  "India",
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo, Democratic Republic of the",
  "Congo, Republic of the",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor (Timor-Leste)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia, The",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, North",
  "Korea, South",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar (Burma)",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Other",
];

export const timeZones = [
  { value: "Africa/Cairo", label: "Africa/Cairo - UTC+2:00" },
  { value: "Africa/Johannesburg", label: "Africa/Johannesburg - UTC+2:00" },
  { value: "Africa/Lagos", label: "Africa/Lagos - UTC+1:00" },
  { value: "Africa/Nairobi", label: "Africa/Nairobi - UTC+3:00" },
  {
    value: "America/Argentina/Buenos_Aires",
    label: "America/Argentina/Buenos_Aires - UTC-3:00",
  },
  { value: "America/Chicago", label: "America/Chicago - UTC-6:00" },
  { value: "America/Denver", label: "America/Denver - UTC-7:00" },
  { value: "America/Halifax", label: "America/Halifax - UTC-4:00" },
  { value: "America/Los_Angeles", label: "America/Los_Angeles - UTC-8:00" },
  { value: "America/Mexico_City", label: "America/Mexico_City - UTC-6:00" },
  { value: "America/New_York", label: "America/New_York - UTC-5:00" },
  { value: "America/Phoenix", label: "America/Phoenix - UTC-7:00" },
  { value: "America/Sao_Paulo", label: "America/Sao_Paulo - UTC-3:00" },
  { value: "America/Toronto", label: "America/Toronto - UTC-5:00" },
  { value: "America/Vancouver", label: "America/Vancouver - UTC-8:00" },
  { value: "Asia/Bangkok", label: "Asia/Bangkok - UTC+7:00" },
  { value: "Asia/Beirut", label: "Asia/Beirut - UTC+2:00" },
  { value: "Asia/Dhaka", label: "Asia/Dhaka - UTC+6:00" },
  { value: "Asia/Dubai", label: "Asia/Dubai - UTC+4:00" },
  { value: "Asia/Hong_Kong", label: "Asia/Hong_Kong - UTC+8:00" },
  { value: "Asia/Jakarta", label: "Asia/Jakarta - UTC+7:00" },
  { value: "Asia/Jerusalem", label: "Asia/Jerusalem - UTC+2:00" },
  { value: "Asia/Karachi", label: "Asia/Karachi - UTC+5:00" },
  { value: "Asia/Kolkata", label: "Asia/Kolkata - UTC+5:30" },
  { value: "Asia/Riyadh", label: "Asia/Riyadh - UTC+3:00" },
  { value: "Asia/Seoul", label: "Asia/Seoul - UTC+9:00" },
  { value: "Asia/Singapore", label: "Asia/Singapore - UTC+8:00" },
  { value: "Asia/Tokyo", label: "Asia/Tokyo - UTC+9:00" },
  { value: "Australia/Adelaide", label: "Australia/Adelaide - UTC+9:30" },
  { value: "Australia/Brisbane", label: "Australia/Brisbane - UTC+10:00" },
  { value: "Australia/Melbourne", label: "Australia/Melbourne - UTC+10:00" },
  { value: "Australia/Perth", label: "Australia/Perth - UTC+8:00" },
  { value: "Australia/Sydney", label: "Australia/Sydney - UTC+10:00" },
  { value: "Europe/Amsterdam", label: "Europe/Amsterdam - UTC+1:00" },
  { value: "Europe/Athens", label: "Europe/Athens - UTC+2:00" },
  { value: "Europe/Brussels", label: "Europe/Brussels - UTC+1:00" },
  { value: "Europe/Bucharest", label: "Europe/Bucharest - UTC+2:00" },
  { value: "Europe/Budapest", label: "Europe/Budapest - UTC+1:00" },
  { value: "Europe/Berlin", label: "Europe/Berlin - UTC+1:00" },
  { value: "Europe/Dublin", label: "Europe/Dublin - UTC+0:00" },
  { value: "Europe/Helsinki", label: "Europe/Helsinki - UTC+2:00" },
  { value: "Europe/Istanbul", label: "Europe/Istanbul - UTC+3:00" },
  { value: "Europe/Lisbon", label: "Europe/Lisbon - UTC+0:00" },
  { value: "Europe/London", label: "Europe/London - UTC+0:00" },
  { value: "Europe/Madrid", label: "Europe/Madrid - UTC+1:00" },
  { value: "Europe/Moscow", label: "Europe/Moscow - UTC+3:00" },
  { value: "Europe/Paris", label: "Europe/Paris - UTC+1:00" },
  { value: "Pacific/Auckland", label: "Pacific/Auckland - UTC+12:00" },
  { value: "Pacific/Honolulu", label: "Pacific/Honolulu - UTC-10:00" },
  { value: "Pacific/Fiji", label: "Pacific/Fiji - UTC+12:00" },
];
