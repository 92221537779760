import axios from "axios";

const baseURL = "/library/publishers";

const api = axios.create({
  baseURL,
});

export const createPublisher = async (publisherData) => {
    try {
      const response = await api.post("/", publisherData);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  export const getPublishers = async () => {
    try {
      const response = await api.get("/");
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  export const getPublisherById = async (publisherId = "") => {
    try {
      const response = await api.get(`/${publisherId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  export const updatePublisher = async (publisherId, publisherData) => {
    try {
      const response = await api.put(`/${publisherId}`, publisherData);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  export const getBooksById = async (publisherId) => {
    try {
      const response = await api.get(`/${publisherId}/books`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  