import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import EmployeeList from "../components/EmployeeList";
import LoadingSpinner from "../components/LoadingSpinner";
import { getEmployees } from "../api/EmployeeAPI";

const EmployeesPage = () => {
  const [employees, setEmployees] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);

  const refreshEmployees = async () => {
    try {
      const data = await getEmployees();
      setEmployees(data.employees);
      setRoles(data.roles);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refreshEmployees();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <Helmet>
        <title>Staff | Maidan</title>
      </Helmet>

      <h1 className="page-heading">Staff</h1>

      <EmployeeList
        employees={employees}
        roles={roles}
        refreshEmployees={refreshEmployees}
        showNewButton={true}
        showSortOption={true}
        showSearchBar={true}
      />
    </div>
  );
};

export default EmployeesPage;
