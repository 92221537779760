import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Table, Drawer, Button, Input, Modal, Menu, Dropdown, Tag, Select } from "antd";
import BookEditForm from "./BookEditForm";
import {
  createBook,
  mapAuthorsToBook,
  mapCategoriesToBook,
  mapCollectionsToBook,
  unmapAuthorsToBook,
  unmapCategoriesToBook,
  unmapCollectionsToBook,
  updateBook,
} from "../../api/library/BookAPI";
import {
  getCategoriesByBook,
  getAuthorsByBook,
  getCollectionsByBook,
  getPublisherByBook,
} from "../../api/library/BookAPI";
import ImportBooks from "./ImportBooks";
import { PlusOutlined, DownOutlined } from "@ant-design/icons";
import { createCategory, getCategories } from "../../api/library/CategoryAPI";
import { createCollection, getCollections } from "../../api/library/CollectionAPI";
import { createAuthor, getAuthors } from "../../api/library/AuthorAPI";
import "../../css/BookList.css"

const { Search } = Input;
const { Option } = Select;

function splitName(fullName) {
  fullName = fullName.trim();
  const nameParts = fullName.split(" ");
  const hasInitials = /^[A-Z]\.[A-Z]\.?/.test(nameParts[0]);

  if (hasInitials) {
    return {
      first_name: fullName,
      last_name: null,
    };
  } else if (nameParts.length === 2) {
    return {
      first_name: nameParts[0],
      last_name: nameParts[1],
    };
  } else {
    return {
      first_name: fullName,
      last_name: null,
    };
  }
}

const BookList = ({ books, refreshBooks, showNewButton = false, showSearchBar = false }) => {
  const [filters, setFilters] = useState([]);
  const [selectedFilterType, setSelectedFilterType] = useState("title");
  const [selectedLanguages, setSelectedLanguages] = useState([]); 
  const [filteredBooks, setFilteredBooks] = useState(books);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [search,setSearch] = useState("");
  const navigate = useNavigate();

  // Compute unique languages from a list of books
  const computeUniqueLanguages = (booksList) => {
    const languageSet = new Set();
    booksList.forEach((book) => {
      if (book.language) {
        languageSet.add(book.language);
      }
    });
    return Array.from(languageSet);
  };

  // Available languages based on current search filters
  const [availableLanguages, setAvailableLanguages] = useState([]);

  useEffect(() => {
    const filterBooks = async () => {
      // Apply search filters
      const searchFilteredBooks = await Promise.all(
        books.map(async (book) => {
          const categories = await getCategoriesByBook(book.id);
          const authors = await getAuthorsByBook(book.id);
          const collections = await getCollectionsByBook(book.id);
          const publisher = await getPublisherByBook(book.id);

          let matches = true;

          filters.forEach((filter) => {
            const query = filter.query.toLowerCase();
            switch (filter.type) {
              case "title":
                matches = matches && book.title.toLowerCase().includes(query);
                break;
              case "author":
                const matchAuthor = authors.some(
                  (author) =>
                    author.first_name.toLowerCase().includes(query) ||
                    (author.last_name && author.last_name.toLowerCase().includes(query))
                );
                matches = matches && matchAuthor;
                break;
              case "publisher":
                const matchPublisher = publisher?.name
                  ? publisher.name.toLowerCase().includes(query)
                  : false;
                matches = matches && matchPublisher;
                break;
              case "category":
                const matchCategory = categories.some((category) =>
                  category.name.toLowerCase().includes(query)
                );
                matches = matches && matchCategory;
                break;
              case "collection":
                const matchCollection = collections.some((collection) =>
                  collection.name.toLowerCase().includes(query)
                );
                matches = matches && matchCollection;
                break;
              default:
                break;
            }
          });

          return matches;
        })
      );

      const searchFiltered = books.filter((_, index) => searchFilteredBooks[index]);

      const languages = computeUniqueLanguages(searchFiltered);
      setAvailableLanguages(languages);

      let finalFiltered = searchFiltered;
      if (selectedLanguages.length > 0) {
        finalFiltered = searchFiltered.filter((book) =>
          selectedLanguages.includes(book.language)
        );
      }

      setFilteredBooks(finalFiltered);
    };

    filterBooks();
  }, [books, filters, selectedLanguages]);

  const handleRowClick = async (record) => {
    const categories = await getCategoriesByBook(record.id);
    const collections = await getCollectionsByBook(record.id);
    const authors = await getAuthorsByBook(record.id);

    record.categories = categories.map((category) => category.id);
    record.collections = collections.map((collection) => collection.id);
    record.authors = authors.map((author) => author.id);

    setSelectedBook(record);
    setIsDrawerOpen(true);
  };

  const handleNewBook = () => {
    setSelectedBook({});
    setIsDrawerOpen(true);
  };

  const handleFormFinish = async (bookData) => {
    if (selectedBook && selectedBook.id) {
      const updateCategories = await getCategories();
      const updateCollections = await getCollections();
      const updateAuthors = await getAuthors();

      // Unmap existing categories, collections, and authors
      await Promise.all(
        updateCategories.map(async (category) => {
          await unmapCategoriesToBook(selectedBook.id, category.id);
        })
      );

      await Promise.all(
        updateCollections.map(async (collection) => {
          await unmapCollectionsToBook(selectedBook.id, collection.id);
        })
      );

      await Promise.all(
        updateAuthors.map(async (author) => {
          await unmapAuthorsToBook(selectedBook.id, author.id);
        })
      );

      // Map new categories
      await Promise.all(
        bookData.categories.map(async (category) => {
          if (typeof category === "string") {
            category = (await createCategory({ name: category })).id;
          }
          await mapCategoriesToBook(selectedBook.id, category);
        })
      );

      // Map new collections
      await Promise.all(
        bookData.collections.map(async (collection) => {
          if (typeof collection === "string") {
            collection = (await createCollection({ name: collection })).id;
          }
          await mapCollectionsToBook(selectedBook.id, collection);
        })
      );

      // Map new authors
      await Promise.all(
        bookData.authors.map(async (author) => {
          if (typeof author === "string") {
            const authorData = splitName(author);
            author = (await createAuthor(authorData)).id;
          }
          await mapAuthorsToBook(selectedBook.id, author);
        })
      );

      // Update the book details
      await updateBook(selectedBook.id, {
        title: bookData.title,
        description: bookData.description,
        page_count: bookData.page_count,
        cover_image_url: bookData.cover_image_url,
        language: bookData.language,
        publisher_id: bookData.publisher_id,
        published_year: bookData.published_year,
      });

      setIsDrawerOpen(false);
      refreshBooks();
    } else {
      // Create a new book
      await createBook(bookData);
      setIsDrawerOpen(false);
      refreshBooks();
    }
  };

  const handleSearch = (value) => {
    if (value) {
      setFilters((prevFilters) => [
        ...prevFilters,
        { type: selectedFilterType, query: value },
      ]);
      setSearch("");
    }
  };

  const handleFilterRemove = (filterIndex) => {
    setFilters((prevFilters) => prevFilters.filter((_, index) => index !== filterIndex));
  };

  const handleLanguageChange = (values) => {
    setSelectedLanguages(values);
  };

  const columnsDefinition = [
    {
      title: "Sl No",
      key: "sl_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => <Link to={`/library/books/${record.id}`}>{text}</Link>,
    },
    {
      title: "Authors",
      key: "authors",
      render: (text, record) => {
        return record.authors && record.authors.length > 0
          ? record.authors.map((author) => (
              <Link key={author.id} to={`/library/authors/${author.id}`}>
                |{author.first_name} {author.last_name}|
              </Link>
            ))
          : "No Authors";
      },
    },
    {
      title: "Categories",
      key: "categories",
      render: (text, record) => {
        return record.categories && record.categories.length > 0
          ? record.categories.map((category) => (
              <Link key={category.id} to={`/library/categories/${category.id}`}>
                |{category.name}|
              </Link>
            ))
          : "No Categories";
      },
    },
    {
      title: "Collections",
      key: "collections",
      render: (text, record) => {
        return record.collections && record.collections.length > 0
          ? record.collections.map((collection) => (
              <Link key={collection.id} to={`/library/collections/${collection.id}`}>
                |{collection.name}|
              </Link>
            ))
          : "No Collections";
      },
    },
    {
      title: "Publishers",
      key: "publisher",
      render: (text, record) => {
        return record.publisher ? (
          <Link to={`/library/publishers/${record.publisher.id}`}>{record.publisher.name}</Link>
        ) : (
          "No Publisher"
        );
      },
    },
    {
      title: "Language",
      dataIndex: "language",
      key: "language",
    },
  ];

  const menu = (
    <Menu>
      <Menu.Item key="new-book" onClick={handleNewBook}>
        New Book
      </Menu.Item>
      <Menu.Item key="isbn-scan" onClick={() => navigate("/isbn")}>
        Scan
      </Menu.Item>
      <Menu.Item key="import-book" onClick={() => setIsModalVisible(true)}>
        Import
      </Menu.Item>
    </Menu>
  );

  const searchMenu = (
    <Menu
      onClick={(e) => setSelectedFilterType(e.key)}
      items={[
        { label: "Title", key: "title" },
        { label: "Author", key: "author" },
        { label: "Publisher", key: "publisher" },
        { label: "Category", key: "category" },
        { label: "Collection", key: "collection" },
      ]}
    />
  );

  return (
    <div className="table-view">
      <div className="flex-container">
        <div className="left-side">
          {showNewButton && (
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button type="default" icon={<PlusOutlined />} style={{ marginRight: 12 }} />
            </Dropdown>
          )}
      
          {/* Search Filter */}
          {showSearchBar && (
            <>
              {/* Search Type Dropdown */}
              <Dropdown overlay={searchMenu} trigger={["click"]}>
                <Button style={{ marginRight: 8 }}>
                  Search by: {selectedFilterType.charAt(0).toUpperCase() + selectedFilterType.slice(1)} <DownOutlined />
                </Button>
              </Dropdown>

              {/* Search Bar */}
              <Search
                className="search-bar"
                placeholder={`Search by ${selectedFilterType}`}
                allowClear
                value={search}
                onChange={(e)=>setSearch(e.target.value)}
                onSearch={handleSearch}
                style={{ width: 400, marginRight: 8 }}
              />
            </>
          )}
        </div>
        {/* Right Side (Language Filter) */}
        <div className="language-filter" >
          <Select
            mode="multiple"
            allowClear
            placeholder="Language"
            onChange={handleLanguageChange}
            value={selectedLanguages}
            style={{ width: 110, marginRight: 8}}
          >
            {availableLanguages.map((language) => (
              <Option key={language} value={language}>
                {language}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div style={{ marginTop: 16, marginBottom: 16 }}>
        {filters.map((filter, index) => (
          <Tag
            key={index}
            closable
            onClose={() => handleFilterRemove(index)}
          >
            {`${filter.type}: ${filter.query}`}
          </Tag>
        ))}
      </div>
      
      <Table
        className="custom-table"
        columns={columnsDefinition}
        dataSource={filteredBooks}
        rowKey="id"
        rowClassName={(record, index) => (index % 2 === 0 ? "row-even" : "row-odd")}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
        pagination={filteredBooks.length > 10 ? { pageSize: 10 } : false}
      />

      {/* Edit/Create Book Drawer */}
      <Drawer
        width={400}
        onClose={() => {
          setIsDrawerOpen(false);
          setSelectedBook(null);
        }}
        open={isDrawerOpen}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {isDrawerOpen && (
          <BookEditForm
            book={selectedBook}
            onFinish={handleFormFinish}
            refreshBooks={refreshBooks}
            title={selectedBook && selectedBook.id ? "Edit Book" : "New Book"}
          />
        )}
      </Drawer>

      {/* Import Books Modal */}
      <Modal
        title="Import Books"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <ImportBooks />
      </Modal>
      </div>
  );
};

export default BookList;
