import React, { useEffect, useState } from "react";
import moment from "moment";
import { Table, Spin, Button, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import { CheckCircleFilled, QuestionCircleOutlined } from "@ant-design/icons";
import {
  getMyPlannedActivities,
  upsertChildMastery,
} from "../../api/RecordKeeperAPI";
import { getFullName } from "../../helpers/childHelper";
import { trackEvent } from "../../utils/mixpanel";
import "../../css/MyPlannedActivities.css";

const NoActivitiesMessage = ({ navigate }) => (
  <div className="no-activities">
    <p>
      No planned presentations yet.{" "}
      <Button
        type="link"
        onClick={() => navigate("/record-keeper")}
        style={{ padding: 0, height: "auto" }}
      >
        Start planning now!
      </Button>
    </p>
  </div>
);

const ActivitiesTable = ({ columns, dataSource }) => (
  <Table
    columns={columns}
    dataSource={dataSource}
    pagination={false}
    expandable={{
      expandedRowRender: () => null,
      rowExpandable: () => false,
    }}
    showHeader
  />
);

const MyPlannedActivities = () => {
  const [loading, setLoading] = useState(true);
  const [plannedActivities, setPlannedActivities] = useState(null);
  const [activeTab, setActiveTab] = useState("byActivity");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const data = await getMyPlannedActivities();
        setPlannedActivities(data);
        trackEvent("MyPlannedActivities: Page Loaded", {
          activitiesCount: data?.activities
            ? Object.keys(data.activities).length
            : 0,
          viewMode: activeTab === "byActivity" ? "By Presentation" : "By Child",
        });
      } catch (error) {
        console.error("Error fetching planned activities:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchActivities();
  }, [activeTab]);

  const handleToggleMasteryLevel = async (
    childId,
    conceptId,
    currentMasteryLevelId
  ) => {
    const newMasteryLevelId =
      currentMasteryLevelId === plannedActivities.presented_mastery_level_id
        ? plannedActivities.planned_mastery_level_id
        : plannedActivities.presented_mastery_level_id;

    const newMasteryLevelName =
      newMasteryLevelId === plannedActivities.presented_mastery_level_id
        ? "Presented"
        : "Planned";

    try {
      await upsertChildMastery(
        childId,
        conceptId,
        newMasteryLevelId,
        moment().format("YYYY-MM-DD")
      );

      trackEvent("MyPlannedActivities: Mastery Level Toggled", {
        childId,
        conceptId,
        newMasteryLevelId,
        newMasteryLevelName,
        viewMode: activeTab === "byActivity" ? "By Presentation" : "By Child",
      });

      const updatedActivities = await getMyPlannedActivities();
      setPlannedActivities(updatedActivities);
    } catch (error) {
      console.error("Error updating mastery level:", error);
    }
  };

  const renderMasteryIcon = (isPresented, onClick) =>
    isPresented ? (
      <CheckCircleFilled
        style={{ color: "green", marginRight: 8, cursor: "pointer" }}
        onClick={onClick}
      />
    ) : (
      <QuestionCircleOutlined
        style={{ marginRight: 8, cursor: "pointer" }}
        onClick={onClick}
      />
    );

  const columnsByActivity = [
    {
      title: "Presentation",
      dataIndex: "conceptName",
      key: "conceptName",
      render: (text, record) => (
        <>
          <div>{text}</div>
          {record.parentConceptName && (
            <div className="parent-concept-name">
              {record.parentConceptName}
            </div>
          )}
        </>
      ),
    },
    {
      title: "Children",
      dataIndex: "children",
      key: "children",
      className: "children-column",
      render: (children, record) =>
        children.map((child) => (
          <div key={child.child_id}>
            {renderMasteryIcon(
              child.mastery_level_id ===
                plannedActivities.presented_mastery_level_id,
              () =>
                handleToggleMasteryLevel(
                  child.child_id,
                  record.key,
                  child.mastery_level_id
                )
            )}
            {getFullName(child)}
          </div>
        )),
    },
  ];

  const columnsByChild = [
    {
      title: "Child",
      dataIndex: "childName",
      key: "childName",
      className: "child-column",
    },
    {
      title: "Presentations",
      dataIndex: "activities",
      key: "activities",
      render: (activities) =>
        activities.map((activity, index) => (
          <div key={index} className="child-activity">
            {renderMasteryIcon(
              activity.mastery_level_id ===
                plannedActivities.presented_mastery_level_id,
              () =>
                handleToggleMasteryLevel(
                  activity.child_id,
                  activity.concept_id,
                  activity.mastery_level_id
                )
            )}
            {activity.name}
            {activity.parentConceptName && (
              <div className="parent-concept-name">
                {activity.parentConceptName}
              </div>
            )}
          </div>
        )),
    },
  ];

  const tableDataByActivity = plannedActivities?.activities
    ? Object.entries(plannedActivities.activities).map(
        ([conceptId, conceptData]) => ({
          key: conceptId,
          conceptName: conceptData.name,
          parentConceptName: conceptData.parent_concept_name,
          children: conceptData.children,
        })
      )
    : [];

  const tableDataByChild = [];
  const childMap = new Map();

  if (plannedActivities?.activities) {
    Object.entries(plannedActivities.activities).forEach(
      ([conceptId, conceptData]) => {
        conceptData.children.forEach((child) => {
          const childKey = `${child.child_id}`;
          if (!childMap.has(childKey)) {
            childMap.set(childKey, {
              key: childKey,
              childName: `${getFullName(child)}`,
              activities: [],
            });
          }
          childMap.get(childKey).activities.push({
            name: conceptData.name,
            parentConceptName: conceptData.parent_concept_name,
            mastery_level_id: child.mastery_level_id,
            child_id: child.child_id,
            concept_id: conceptId,
          });
        });
      }
    );
    tableDataByChild.push(...childMap.values());
  }

  const tabItems = [
    {
      label: "By Presentation",
      key: "byActivity",
      children: (
        <ActivitiesTable
          columns={columnsByActivity}
          dataSource={tableDataByActivity}
        />
      ),
    },
    {
      label: "By Child",
      key: "byChild",
      children: (
        <ActivitiesTable
          columns={columnsByChild}
          dataSource={tableDataByChild}
        />
      ),
    },
  ];

  return (
    <div className="my-planned-activities-widget">
      <h2>My Planned Presentations</h2>
      {loading ? (
        <Spin />
      ) : tableDataByActivity.length === 0 ? (
        <NoActivitiesMessage navigate={navigate} />
      ) : (
        <Tabs
          defaultActiveKey="byActivity"
          onChange={setActiveTab}
          items={tabItems}
        />
      )}
    </div>
  );
};

export default MyPlannedActivities;
