import React, { useState, useEffect } from "react";
import CollectionList from "../../components/library/CollectionList";
import { getCollections } from "../../api/library/CollectionAPI";
import { Helmet } from "react-helmet-async";

const CollectionsPage = () => {
  const [collections, setCollections] = useState([]);

  const refreshCollections = async () => {
    try {
      const data = await getCollections();
      setCollections(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    refreshCollections();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Collections | Maidan</title>
      </Helmet>
      <div className="content-below-header">
        <CollectionList
          collections={collections}
          refreshCollections={refreshCollections}
          showNewButton={true}
          showSearchBar={true}
        />
      </div>
    </div>
  );
};

export default CollectionsPage;
