// src/components/SchoolNameOnboardingForm.js
import React, { useState } from "react";
import { Input, Button, Select } from "antd";
import { createSchoolAndOwnerAccount } from "../api/OnboardingAPI";
import { countries, timeZones } from "../helpers/countryAndTimeZoneHelper";
import "../css/SchoolNameOnboardingForm.css";

const { Option } = Select;

const SchoolNameOnboardingForm = ({ setMessage }) => {
  const [schoolName, setSchoolName] = useState("");
  const [country, setCountry] = useState("India");
  const [timeZone, setTimeZone] = useState("Asia/Kolkata");
  const [dateFormat, setDateFormat] = useState("dd/mm/yyyy");
  const [phone, setPhone] = useState("");

  const handleSchoolNameChange = (event) => {
    setSchoolName(event.target.value);
  };

  const handleCountryChange = (value) => {
    setCountry(value);
  };

  const handleTimeZoneChange = (value) => {
    setTimeZone(value);
  };

  const handleDateFormatChange = (value) => {
    setDateFormat(value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleSubmit = async () => {
    setMessage(null);
    if (schoolName && phone) {
      try {
        const response = await createSchoolAndOwnerAccount({
          role: "Founder",
          schoolName,
          country,
          timeZone,
          dateFormat,
          phone,
        });
        setMessage({ type: "success", text: response.message });
        window.location.reload();
      } catch (error) {
        console.error("Error submitting role:", error);
        setMessage({
          type: "error",
          text:
            error.message || "An error occurred while submitting your role.",
        });
      }
    } else {
      setMessage({
        type: "warning",
        text: "Please enter a school name and phone number.",
      });
    }
  };

  return (
    <div className="founder-form-container">
      <div className="form-row">
        <label className="form-label">School Name:</label>
        <Input
          type="text"
          value={schoolName}
          onChange={handleSchoolNameChange}
          className="founder-form-input"
          style={{ textAlign: "left" }}
          autoFocus
        />
      </div>
      <div className="form-row">
        <label className="form-label">Phone:</label>
        <Input
          type="text"
          value={phone}
          onChange={handlePhoneChange}
          className="founder-form-input"
          style={{ textAlign: "left" }}
        />
      </div>
      <div className="form-row">
        <label className="form-label">Country:</label>
        <Select
          value={country}
          onChange={handleCountryChange}
          showSearch
          placeholder="Select Country"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          className="founder-form-select"
        >
          {countries.map((country) => (
            <Option key={country} value={country}>
              {country}
            </Option>
          ))}
        </Select>
      </div>
      <div className="form-row">
        <label className="form-label">Time Zone:</label>
        <Select
          value={timeZone}
          onChange={handleTimeZoneChange}
          showSearch
          placeholder="Select Time Zone"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          className="founder-form-select"
        >
          {timeZones.map((zone) => (
            <Option key={zone.value} value={zone.value}>
              {zone.label}
            </Option>
          ))}
        </Select>
      </div>
      <div className="form-row">
        <label className="form-label">Date Format:</label>
        <Select
          value={dateFormat}
          onChange={handleDateFormatChange}
          className="founder-form-select"
        >
          <Option value="dd/mm/yyyy">dd/mm/yyyy</Option>
          <Option value="mm/dd/yyyy">mm/dd/yyyy</Option>
        </Select>
      </div>
      <div className="form-row">
        <Button
          type="primary"
          onClick={handleSubmit}
          className="founder-form-button"
        >
          Get Started
        </Button>
      </div>
    </div>
  );
};

export default SchoolNameOnboardingForm;
