import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import ConceptList from "../components/ConceptList";
import LoadingSpinner from "../components/LoadingSpinner";
import { getConceptsByDomain } from "../api/DomainAPI";
import { useParams } from "react-router-dom";

const DomainPage = () => {
  const { domainId } = useParams();
  const [domainData, setDomainData] = useState({
    domain_name: "",
    concepts: [],
  });
  const [loading, setLoading] = useState(true);

  const refreshConcepts = useCallback(async () => {
    try {
      const data = await getConceptsByDomain(domainId);
      setDomainData(data);
      console.log(data.concepts);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [domainId]);

  useEffect(() => {
    refreshConcepts();
  }, [refreshConcepts]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <Helmet>
        <title>{domainData.domain_name} | Maidan</title>
      </Helmet>

      <h1 className="page-heading">{domainData.domain_name}</h1>

      <ConceptList
        domainId={domainId}
        concepts={domainData.concepts}
        refreshConcepts={refreshConcepts}
        showNewButton={true}
        showSearchBar={true}
      />
    </div>
  );
};

export default DomainPage;
