import { isString } from "antd/es/button";
import axios from "axios";
import { createPublisher } from "./PublisherAPI";

const baseURL = "/library/books"; 

const api = axios.create({
  baseURL,
});

export const createBook = async (bookData) => {
  try {
    bookData.publisher_id=isString(bookData.publisher_id)?(await createPublisher({name:bookData.publisher_id})).id : bookData.publisher_id;
    const response = await api.post("/", bookData, {
      'headers' : { 'Content-Type' : 'multipart/form-data' }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBooks = async () => {
  try {
    const response = await api.get("/");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBookById = async (bookId = "") => {
  try {
    const response = await api.get(`/${bookId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updateBook = async (bookId, bookData) => {
  try {
    bookData.publisher_id=isString(bookData.publisher_id)?(await createPublisher({name:bookData.publisher_id})).id : bookData.publisher_id;
    const response = await api.put(`/${bookId}`, bookData, {
      'headers' : { 'Content-Type' : 'multipart/form-data' }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Map a Category to a Book
export const mapCategoriesToBook = async (bookId, categoryId) => {
  try {
    const response = await api.post(
      `/${bookId}/categories/${categoryId}/map`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Unmap a Category from a Book
export const unmapCategoriesToBook = async (bookId, categoryId) => {
  try {
    const response = await api.delete(
      `/${bookId}/categories/${categoryId}/unmap`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Get Categories mapped to a Book
export const getCategoriesByBook = async (bookId) => {
  try {
    const response = await api.get(`/${bookId}/categories`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Get Categories not mapped to a Book
export const getCategoriesNotByBook = async (bookId) => {
  try {
    const response = await api.get(
      `/${bookId}/categories/not-mapped`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Map a Author to a Book
export const mapAuthorsToBook = async (bookId, authorId) => {
  try {
    const response = await api.post(
      `/${bookId}/authors/${authorId}/map`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Unmap a Authir from a Book
export const unmapAuthorsToBook = async (bookId, authorId) => {
  try {
    const response = await api.delete(
      `/${bookId}/authors/${authorId}/unmap`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Get Authors mapped to a Book
export const getAuthorsByBook = async (bookId) => {
  try {
    const response = await api.get(`/${bookId}/authors`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Get Authors not mapped to a Book
export const getAuthorsNotByBook = async (bookId) => {
  try {
    const response = await api.get(
      `/${bookId}/authors/not-mapped`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPublisherByBook = async (bookId) => {
  try {
    const response = await api.get(
      `/${bookId}/publisher`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Map a Collection to a Book
export const mapCollectionsToBook = async (bookId, collectionId) => {
  try {
    const response = await api.post(
      `/${bookId}/collections/${collectionId}/map`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Unmap a Authir from a Book
export const unmapCollectionsToBook = async (bookId, collectionId) => {
  try {
    const response = await api.delete(
      `/${bookId}/collections/${collectionId}/unmap`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Get Collections mapped to a Book
export const getCollectionsByBook = async (bookId) => {
  try {
    const response = await api.get(`/${bookId}/collections`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Get Collections not mapped to a Book
export const getCollectionsNotByBook = async (bookId) => {
  try {
    const response = await api.get(
      `/${bookId}/collections/not-mapped`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

//Get Book by isbn code
export const getBookByIsbn = async (isbn) => {
  try {
    const response = await api.get(`https://www.googleapis.com/books/v1/volumes?q=isbn:${isbn}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//create Book By Isbn
export const createBookByIsbn = async (bookData) => {
  try {
    const response = await api.post("/isbn", bookData);
    return response.data;
  } catch (error) {
    throw error;
  }
};