// BloodGroupDropdown.js
import React from "react";
import { Select } from "antd";

const { Option } = Select;

const BloodGroupDropdown = ({ value, onChange }) => {
  return (
    <Select value={value} onChange={onChange}>
      <Option value="A+">A+</Option>
      <Option value="A-">A-</Option>
      <Option value="B+">B+</Option>
      <Option value="B-">B-</Option>
      <Option value="AB+">AB+</Option>
      <Option value="AB-">AB-</Option>
      <Option value="O+">O+</Option>
      <Option value="O-">O-</Option>
    </Select>
  );
};

export default BloodGroupDropdown;
