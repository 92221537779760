import React, { useEffect, useRef, useState } from 'react';
import Quagga from 'quagga';

const WebcamComponent = ({ onDetected, setError }) => {
  const videoRef = useRef(null);  
  const canvasRef = useRef(null); 
  const [currentFrame, setCurrentFrame] = useState(null); 
  const quaggaInitializedRef = useRef(false);

  useEffect(() => {
    const startCamera = async () => {
      try {
        setError('')
        
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: { ideal: 'environment' } }  // Prefer the rear camera
        });

        if (videoRef.current) {
          videoRef.current.srcObject = stream;

          videoRef.current.onloadedmetadata = () => {
            videoRef.current.play().catch((err) => {
              console.error('Error playing the video stream:', err);
              setError('Error playing the video stream');
            });
          };
        }

        const waitForVideoReady = new Promise((resolve) => {
          if (videoRef.current) {
            videoRef.current.onloadedmetadata = () => resolve();
          }
        });

        await waitForVideoReady;

        const videoWidth = videoRef.current.videoWidth;
        const videoHeight = videoRef.current.videoHeight;

        if (canvasRef.current) {
          canvasRef.current.width = videoWidth;
          canvasRef.current.height = videoHeight;
        }

        const captureFrame = () => {
          if (videoRef.current && canvasRef.current) {
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');

            context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

            const frameData = context.getImageData(0, 0, canvas.width, canvas.height);
            setCurrentFrame(frameData); 

            requestAnimationFrame(captureFrame);
          }
        };

        Quagga.init(
          {
            inputStream: {
              name: 'Live',
              type: 'LiveStream',
              target: videoRef.current,
              constraints: {
                facingMode: 'environment', // Use rear camera by default
              },
            },
            decoder: {
              readers: ['ean_reader'], // Add other readers as needed
            },
            locate: true,
          },
          (err) => {
            if (err) {
              console.error('Error initializing Quagga:', err);
              setError('Error initializing Quagga');
              return;
            }
            Quagga.start();
            quaggaInitializedRef.current = true;
          }
        );

        // Handle detected barcode
        Quagga.onDetected((result) => {
          if (result && result.codeResult) {
            onDetected(result.codeResult.code);
            Quagga.stop(); 
            quaggaInitializedRef.current = false;
            handleUnload(); 
          }
        });

        captureFrame(); 
      } catch (error) {
        console.error('Error accessing the camera:', error);
      }
    };

    const handleUnload = () => {
      if (quaggaInitializedRef.current) {
        Quagga.stop();
        quaggaInitializedRef.current = false;
      }
      if (videoRef.current && videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject;
        stream.getTracks().forEach(track => track.stop());
        videoRef.current.srcObject = null; 
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        handleUnload(); // Stop camera when the page is not visible
      }
    };

    startCamera(); // Initialize the camera

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      handleUnload(); 
    };
  }, [setError, onDetected]);

  return (
    <div>
      {/* Video element to display the camera feed */}
      <video ref={videoRef} style={{ width: '100%', height: 'auto' }} autoPlay muted playsInline />
      {/* Hidden canvas element to capture frames */}
      <canvas ref={canvasRef} style={{ display: 'none' }} />
      {/* Display the captured frame data */}
      {currentFrame ? <p>Current frame capturing...</p> : <p>Check camera permission whether the site is running on HTTPS.</p>}
    </div>
  );
};

export default WebcamComponent;
