import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Table, Drawer, Button, Input } from "antd";
import PublisherEditForm from "./PublisherEditForm";
import { createPublisher, updatePublisher } from "../../api/library/PublisherAPI";

const { Search } = Input;

const PublisherList = ({
  publishers,
  refreshPublishers,
  showNewButton = false,
  showSearchBar = false,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedPublisher, setSelectedPublisher] = useState(null);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter publishers while handling cases where the publisher object might be null
  const filteredPublishers = publishers
    .filter((publisher) => publisher && publisher.name) // Ensure publisher is not null and has a name
    .filter((publisher) =>
      publisher.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

  const handleRowClick = (record) => {
    setSelectedPublisher(record);
    setIsDrawerOpen(true);
  };

  const handleNewPublisher = () => {
    setSelectedPublisher({});
    setIsDrawerOpen(true);
  };

  const handleFormFinish = (publisherData) => {
    if (selectedPublisher && selectedPublisher.id) {
      return updatePublisher(selectedPublisher.id, publisherData).then(() => {
        setIsDrawerOpen(false);
        refreshPublishers();
      });
    } else {
      return createPublisher(publisherData).then(() => {
        setIsDrawerOpen(false);
        refreshPublishers();
      });
    }
  };

  const columns = [
    {
      title: "Sl No",
      key: "sl_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`/library/publishers/${record.id}`}>{text}</Link>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (text) => text || "-", // Display "-" if address is null or undefined
    },
    {
      title: "Contact Info",
      dataIndex: "contact_info",
      key: "contact_info",
      render: (text) => text || "-", // Display "-" if contact info is null or undefined
    },
  ];

  return (
    <div className="table-view">
      {showNewButton && (
        <Button
          type="default"
          onClick={handleNewPublisher}
          style={{ marginBottom: 16 }}
        >
          New Publisher
        </Button>
      )}
      {showSearchBar && (
        <Search
          placeholder="Search by name"
          allowClear
          onChange={handleSearch}
          style={{ marginBottom: 16 }}
        />
      )}
      <Table
        className="custom-table"
        columns={columns}
        dataSource={filteredPublishers}
        rowKey="id"
        rowClassName={(record, index) =>
          index % 2 === 0 ? "row-even" : "row-odd"
        }
        onRow={(record) => {
          return {
            onClick: () => {
              handleRowClick(record);
            },
          };
        }}
        pagination={filteredPublishers.length > 10 ? { pageSize: 10 } : false}
      />
      <Drawer
        width={400}
        onClose={() => {
          setIsDrawerOpen(false);
          setSelectedPublisher(null);
        }}
        open={isDrawerOpen}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {isDrawerOpen && (
          <PublisherEditForm
            publisher={selectedPublisher}
            onFinish={handleFormFinish}
            refreshPublishers={refreshPublishers}
            title={
              selectedPublisher && selectedPublisher.id
                ? "Edit Publisher"
                : "New Publisher"
            }
          />
        )}
      </Drawer>
    </div>
  );
};

export default PublisherList;
