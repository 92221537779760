import React, { useEffect, useState, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import ClassroomList from "../components/ClassroomList";
import LoadingSpinner from "../components/LoadingSpinner";
import { getEmployeeById } from "../api/EmployeeAPI";
import { getFullName } from "../helpers/childHelper";

const EmployeePage = () => {
  const { employeeId } = useParams();
  const [employee, setEmployee] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchEmployee = useCallback(async () => {
    try {
      const data = await getEmployeeById(employeeId);
      setEmployee(data);
    } catch (error) {
      console.error("Error fetching employee:", error);
    } finally {
      setLoading(false);
    }
  }, [employeeId]);

  useEffect(() => {
    fetchEmployee();
  }, [fetchEmployee]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <Helmet>
        <title>Staff Member | Maidan</title>
      </Helmet>

      <h1 className="page-heading">{getFullName(employee)}</h1>

      {employee.role === "Teacher" && (
        <>
          <h3>Classrooms</h3>
          <ClassroomList classrooms={employee.classrooms} />
        </>
      )}
    </div>
  );
};

export default EmployeePage;
