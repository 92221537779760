import React, { useState, useEffect } from "react";
import { Modal, Table, Input, Select } from "antd";
import { getGuardiansNotMappedToChild } from "../api/SchoolAPI";

const { Search } = Input;
const { Option } = Select;

const ManageGuardiansModal = ({
  childId,
  isOpen,
  onClose,
  onAdd,
  mode,
  existingGuardians,
}) => {
  const [guardians, setGuardians] = useState([]);
  const [selectedGuardians, setSelectedGuardians] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [relationship, setRelationship] = useState({});

  useEffect(() => {
    if (isOpen) {
      const fetchGuardians = async () => {
        try {
          let guardians = [];
          if (mode === "add") {
            guardians = await getGuardiansNotMappedToChild(childId);
          } else {
            guardians = existingGuardians;
          }
          setGuardians(guardians);
        } catch (error) {
          console.error("Error fetching guardians:", error);
        }
      };
      fetchGuardians();
    }
  }, [isOpen, childId, mode, existingGuardians]);

  const handleAddGuardians = () => {
    onAdd(
      selectedGuardians.map((id) => ({
        guardianId: id,
        relationship: relationship[id] || "Other",
      }))
    );
    setSelectedGuardians([]);
  };

  const handleGuardianSelection = (selectedRowKeys) => {
    setSelectedGuardians(selectedRowKeys);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleRelationshipChange = (guardianId, value) => {
    setRelationship((prev) => ({ ...prev, [guardianId]: value }));
  };

  const filteredGuardians = guardians.filter((guardian) =>
    guardian.first_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const guardianColumns = [
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone_number",
      key: "phone_number",
    },
  ];

  if (mode === "remove") {
    guardianColumns.push({
      title: "Relationship",
      dataIndex: "relationship",
      key: "relationship",
    });
  } else {
    guardianColumns.push({
      title: "Relationship",
      key: "relationship",
      render: (_, record) => (
        <Select
          defaultValue={relationship[record.guardian_id] || "Other"}
          style={{ width: 120 }}
          onChange={(value) =>
            handleRelationshipChange(record.guardian_id, value)
          }
        >
          <Option value="Mother">Mother</Option>
          <Option value="Father">Father</Option>
          <Option value="Grandmother">Grandmother</Option>
          <Option value="Grandfather">Grandfather</Option>
          <Option value="Other">Other</Option>
        </Select>
      ),
    });
  }

  const rowSelection = {
    selectedRowKeys: selectedGuardians,
    onChange: handleGuardianSelection,
  };

  return (
    <Modal
      title={
        mode === "add"
          ? "Add Guardians to Child"
          : "Remove Guardians from Child"
      }
      open={isOpen}
      onOk={handleAddGuardians}
      onCancel={onClose}
      okText={mode === "add" ? "Add to Child" : "Remove from Child"}
    >
      <Search
        placeholder="Search by name"
        allowClear
        onChange={handleSearch}
        style={{ marginBottom: 16 }}
      />
      <Table
        rowSelection={rowSelection}
        columns={guardianColumns}
        dataSource={filteredGuardians}
        rowKey="guardian_id"
        pagination={{ pageSize: 10 }}
      />
    </Modal>
  );
};

export default ManageGuardiansModal;
