import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import moment from "moment";
import { Button, message, DatePicker, Row, Col, List } from "antd";
import { useAuth } from "../contexts/AuthContext";
import ClassroomSelector from "../components/ClassroomSelector";
import LoadingSpinner from "../components/LoadingSpinner";
import NoClassroomMessage from "../components/NoClassroomMessage";
import { getFullName } from "../helpers/childHelper";
import {
  recordAttendance,
  getAttendanceLogsForClassroomOnDate,
} from "../api/AttendanceAPI";
import { getChildrenInClassroom } from "../api/SchoolAPI";
import { shouldShowClassroomSelector } from "../helpers/teacherHelper";
import { trackEvent } from "../utils/mixpanel";
import "../css/AttendancePage.css";
import "../css/ChildAttendanceList.css";

const AttendancePage = () => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(moment());
  const [selectedClassroom, setSelectedClassroom] = useState(null);
  const [attendance, setAttendance] = useState({});
  const [children, setChildren] = useState([]);
  const [attendanceRecorded, setAttendanceRecorded] = useState(false);

  useEffect(() => {
    if (currentUser.classrooms && currentUser.classrooms.length > 0) {
      setSelectedClassroom(currentUser.classrooms[0].id);
    }
  }, [currentUser.classrooms]);

  useEffect(() => {
    if (selectedClassroom) {
      setLoading(true);
      const fetchData = async () => {
        try {
          const attendanceData = await getAttendanceLogsForClassroomOnDate(
            selectedClassroom,
            date.format("YYYY-MM-DD")
          );

          if (attendanceData.length > 0) {
            const initialAttendance = attendanceData.reduce((acc, log) => {
              acc[log.child_id] = log.present;
              return acc;
            }, {});

            setAttendance(initialAttendance);
            setAttendanceRecorded(true);

            setChildren(
              attendanceData.map((log) => ({
                child_id: log.child_id,
                first_name: log.first_name,
                last_name: log.last_name,
              }))
            );
          } else {
            const childrenData =
              await getChildrenInClassroom(selectedClassroom);
            setChildren(childrenData);
            const initialAttendance = childrenData.reduce((acc, child) => {
              acc[child.child_id] = true; // Default to present
              return acc;
            }, {});
            setAttendance(initialAttendance);
            setAttendanceRecorded(false); // No attendance recorded initially
          }
          trackEvent("AttendancePage Loaded", {}, currentUser);
        } catch (error) {
          console.error("Error fetching attendance data:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [selectedClassroom, date]);

  const handleRecordAttendance = () => {
    setLoading(true);
    const attendanceLogs = Object.entries(attendance).map(
      ([childId, isPresent]) => ({
        date: date.format("YYYY-MM-DD"),
        classroom_id: selectedClassroom,
        child_id: childId,
        present: isPresent,
        note: "",
      })
    );

    recordAttendance(attendanceLogs)
      .then(() => {
        message.success("Attendance recorded successfully");

        trackEvent(
          "Children Attendance Recorded",
          {
            selectedClassroom,
            date: date.format("YYYY-MM-DD"),
            attendanceLogs,
          },
          currentUser
        );
        setAttendanceRecorded(true);
        window.scrollTo(0, 0); // Scroll to top of the page
      })
      .catch((error) => {
        console.error("Error recording attendance:", error);
        message.error("Failed to record attendance");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const toggleAttendance = (childId) => {
    setAttendance((prevAttendance) => {
      const newAttendance = {
        ...prevAttendance,
        [childId]: !prevAttendance[childId],
      };

      trackEvent(
        "Child Attendance Marked",
        { childId, isPresent: newAttendance[childId] },
        currentUser
      );

      setAttendanceRecorded(false); // Show "Record Attendance" button if any child state is toggled

      return newAttendance;
    });
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const renderAttendanceSummary = () => {
    const total = children.length;
    const present = Object.values(attendance).filter((status) => status).length;
    const absent = total - present;
    const presentPercentage = ((present / total) * 100).toFixed(1);
    const absentPercentage = ((absent / total) * 100).toFixed(1);

    return (
      <>
        <table className="attendance-summary-table">
          <tbody>
            <tr>
              <td>Total:</td>
              <td>{total}</td>
            </tr>
            <tr>
              <td>Present:</td>
              <td>
                {present} ({presentPercentage}%)
              </td>
            </tr>
            <tr>
              <td>Absent:</td>
              <td>
                {absent} ({absentPercentage}%)
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  if (currentUser.role === "Teacher" && currentUser.classrooms.length === 0) {
    return <NoClassroomMessage title="Record Attendance" />;
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <Helmet>
        <title>Record Attendance | Maidan</title>
      </Helmet>
      <Row
        align="middle"
        justify="space-between"
        gutter={8}
        style={{ marginBottom: 20 }}
      >
        <Col>
          <h1 className="page-heading-without-bottom-margin">
            Record Attendance
          </h1>
        </Col>
        <Col>
          <DatePicker
            value={date}
            onChange={(date) => setDate(date)}
            disabledDate={disabledDate}
            format="DD-MM-YYYY"
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: 20, alignItems: "center" }}>
        {shouldShowClassroomSelector(currentUser) && (
          <Col xs={24} sm={12} md={6}>
            <ClassroomSelector
              selectedClassroom={selectedClassroom}
              setSelectedClassroom={setSelectedClassroom}
            />
          </Col>
        )}
      </Row>

      {selectedClassroom && (
        <>
          {attendanceRecorded && renderAttendanceSummary()}
          <List
            className="child-attendance-list"
            dataSource={children}
            renderItem={(child) => (
              <List.Item
                className={`attendance-item ${
                  attendance[child.child_id] ? "present" : "absent"
                }`}
                onClick={() => toggleAttendance(child.child_id)}
              >
                <span className="child-name">{getFullName(child)}</span>
              </List.Item>
            )}
          />
          {!attendanceRecorded && (
            <Button
              type="primary"
              className="record-attendance-button"
              onClick={handleRecordAttendance}
            >
              Record Attendance
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default AttendancePage;
