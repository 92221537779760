// client/src/api/ChildAPI.js
import axios from "axios";

const baseURL = "/memberships";

const api = axios.create({
  baseURL,
});

export const createChild = async (childData) => {
  try {
    const response = await api.post("/children", childData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getChildren = async () => {
  try {
    const response = await api.get("/children");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getChildById = async (childId = "") => {
  try {
    const response = await api.get(`/children/${childId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateChild = async (childId, childData) => {
  try {
    const response = await api.put(`/children/${childId}`, childData);
    return response.data;
  } catch (error) {
    throw error;
  }
};
