import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import BookList from "../../components/library/BookList"; // Ensure this component is created to display books
import ManageBookCategoryModal from "../../components/library/ManageBookCategoryModal"; // Ensure this component is created as per previous instructions
import {
  getCategoryById,
  getBooksByCategory,
  mapBooksToCategory,
  unmapBooksToCategory,
} from "../../api/library/CategoryAPI";
import { Helmet } from "react-helmet-async";

const CategoryPage = () => {
  const { categoryId } = useParams();
  const [category, setCategory] = useState(null);
  const [books, setBooks] = useState([]);
  const [isBooksModalVisible, setIsBooksModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState("add");

  useEffect(() => {
    const fetchCategoryAndBooks = async () => {
      try {
        // Fetch category details
        const categoryData = await getCategoryById(categoryId);
        setCategory(categoryData);

        // Fetch books associated with the category
        const booksData = await getBooksByCategory(categoryId);
        setBooks(booksData);
      } catch (error) {
        console.error("Error fetching category and books:", error);
      }
    };
    fetchCategoryAndBooks();
  }, [categoryId]);

  const refreshCategory = useCallback(async () => {
    try {
      // Fetch updated category details
      const categoryData = await getCategoryById(categoryId);
      setCategory(categoryData);

      // Fetch updated books associated with the category
      const booksData = await getBooksByCategory(categoryId);
      setBooks(booksData);
    } catch (error) {
      console.error("Error refreshing category and books:", error);
    }
  }, [categoryId]);

  useEffect(() => {
    refreshCategory();
  }, [refreshCategory]);

  const handleAddBooks = async (bookIds) => {
    try {
      for (const bookId of bookIds) {
        await mapBooksToCategory(bookId, categoryId);
      }
      setIsBooksModalVisible(false);
      refreshCategory();
    } catch (error) {
      console.error("Error adding books to category:", error);
    }
  };

  const handleRemoveBooks = async (bookIds) => {
    try {
      for (const bookId of bookIds) {
        await unmapBooksToCategory(bookId, categoryId);
      }
      setIsBooksModalVisible(false);
      refreshCategory();
    } catch (error) {
      console.error("Error removing books from category:", error);
    }
  };

  if (!category) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Helmet>
        <title>{category.name} | Category</title>
      </Helmet>
      <h2>{category.name} </h2>

      <h3 style={{marginLeft:8}}>Books</h3>
      <Button
        onClick={() => {
          setModalMode("add");
          setIsBooksModalVisible(true);
        }}
        style={{marginLeft:8}}
      >
        Add Books
      </Button>
      <Button
        onClick={() => {
          setModalMode("remove");
          setIsBooksModalVisible(true);
        }}
        style={{ marginLeft: 8 }}
      >
        Remove Books
      </Button>
      <BookList books={books} /> {/* Display the list of books associated with the category */}

      <ManageBookCategoryModal
        categoryId={categoryId}
        isVisible={isBooksModalVisible}
        onClose={() => setIsBooksModalVisible(false)}
        onAdd={modalMode === "add" ? handleAddBooks : handleRemoveBooks}
        mode={modalMode}
        existingBooks={books}
      />
    </div>
  );
};

export default CategoryPage;
