import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { getPublishers } from "../../api/library/PublisherAPI"; // Import the API call

const { Option } = Select;

const PublisherDropdown = ({ value, onChange }) => {
  const [publishers, setPublishers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newPublisher, setNewPublisher] = useState(""); // State to track new input

  useEffect(() => {
    const fetchPublishers = async () => {
      setLoading(true);
      try {
        const publishersList = await getPublishers();
        setPublishers(publishersList);
      } catch (error) {
        console.error("Error fetching publishers:", error);
      }
      setLoading(false);
    };

    fetchPublishers();
  }, []);

  const handleSearch = (input) => {
    setNewPublisher(input);
  };

  const handleSelectChange = (selectedValue) => {
    if (selectedValue === "new") {
      const newEntry = { id: newPublisher, name: newPublisher };
      setPublishers([...publishers, newEntry]);
      onChange(newPublisher); // Trigger the onChange with new value
    } else {
      onChange(selectedValue); // If existing value is selected
    }
  };

  return (
    <Select
      value={value}
      onChange={handleSelectChange}
      showSearch
      placeholder="Select or add a Publisher"
      loading={loading}
      optionFilterProp="label" // Use 'label' to filter options
      filterOption={(input, option) =>
        option.label.toLowerCase().includes(input.toLowerCase()) // Use option.label here
      }
      onSearch={handleSearch} // Capture the search input
      notFoundContent={newPublisher ? `Add "${newPublisher}"` : null} // Show "Add new" text
    >
      {publishers.map((publisher) => (
        <Option key={publisher.id} value={publisher.id} label={publisher.name}>
          {publisher.name}
        </Option>
      ))}
      {newPublisher && !publishers.find(p => p.name === newPublisher) && (
        <Option key="new" value="new" label={`Add "${newPublisher}"`}>
          Add "{newPublisher}"
        </Option>
      )}
    </Select>
  );
};

export default PublisherDropdown;
