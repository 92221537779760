import axios from "axios";

const baseURL = "/schools";

const api = axios.create({
  baseURL,
});

// API to get school information by ID
export const getSchoolById = async () => {
  try {
    const response = await api.get("/schools");
    return response.data;
  } catch (error) {
    console.error("Error fetching school:", error);
    throw error;
  }
};

// API to update school information
export const updateSchool = async (schoolData) => {
  try {
    const response = await api.put("/schools", schoolData);
    return response.data;
  } catch (error) {
    console.error("Error updating school:", error);
    throw error;
  }
};

// Classroom API methods
export const createClassroom = async (classroomData) => {
  try {
    const response = await api.post("/classrooms", classroomData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getClassrooms = async () => {
  try {
    const response = await api.get("/classrooms");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getClassroomById = async (classroomId = "") => {
  try {
    const response = await api.get(`/classrooms/${classroomId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateClassroom = async (classroomId, classroomData) => {
  try {
    const response = await api.put(`/classrooms/${classroomId}`, classroomData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Mapping and Unmapping API methods
export const mapChildToClassroom = async (classroomId, childId) => {
  try {
    const response = await api.post(
      `/classrooms/${classroomId}/children/${childId}/map`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const unmapChildFromClassroom = async (classroomId, childId) => {
  try {
    const response = await api.delete(
      `/classrooms/${classroomId}/children/${childId}/unmap`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const mapTeacherToClassroom = async (classroomId, teacherId) => {
  try {
    const response = await api.post(
      `/classrooms/${classroomId}/teachers/${teacherId}/map`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const unmapTeacherFromClassroom = async (classroomId, teacherId) => {
  try {
    const response = await api.delete(
      `/classrooms/${classroomId}/teachers/${teacherId}/unmap`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const mapGuardianToChild = async (childId, guardianId, relationship) => {
  try {
    const response = await api.post(
      `/children/${childId}/guardians/${guardianId}/map`,
      { relationship }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const unmapGuardianFromChild = async (childId, guardianId) => {
  try {
    const response = await api.delete(
      `/children/${childId}/guardians/${guardianId}/unmap`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getChildrenInClassroom = async (classroomId) => {
  try {
    const response = await api.get(`/classrooms/${classroomId}/children`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getChildrenNotInClassroom = async (classroomId) => {
  try {
    const response = await api.get(
      `/classrooms/${classroomId}/children-not-in-classroom`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTeachersNotInClassroom = async (classroomId) => {
  try {
    const response = await api.get(
      `/classrooms/${classroomId}/teachers-not-in-classroom`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getGuardiansNotMappedToChild = async (childId) => {
  try {
    const response = await api.get(`/children/${childId}/guardians-not-mapped`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getClassroomsNotMappedToChild = async (childId) => {
  try {
    const response = await api.get(
      `/children/${childId}/classrooms-not-mapped`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateSchoolLogo = (schoolId, logo) => {
  const formData = new FormData();

  const file = new File(
    [logo],
    `school-logo-${Date.now()}.${logo.type.split("/")[1]}`,
    {
      type: logo.type,
    }
  );

  formData.append("logo", file);

  return axios
    .post(`${baseURL}/${schoolId}/logo`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error updating school logo:", error);
      throw error;
    });
};
