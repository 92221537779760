import React from "react";
import { Form, Input, Button, Typography } from "antd";
import { toast } from "react-toastify";

const { Title } = Typography;

const AuthorEditForm = ({
  author = {},
  onFinish,
  refreshAuthors,
  title,
}) => {
  const handleFinish = (authorData) => {
    onFinish(authorData)
      .then(() => {
        refreshAuthors();
        toast.success(
          <>
            Author <b>{authorData.name}</b>{" "}
            {author.id ? "updated" : "created"} successfully!
          </>
        );
      })
      .catch((error) => {
        console.error("Error handling author:", error);
        toast.error(
          <>
            Failed to {author.id ? "update" : "create"} author{" "}
            <b>{authorData.name}</b>.
          </>
        );
      });
  };

  return (
    <>
      <Title level={2}>{title}</Title>
      <Form
        initialValues={author}
        onFinish={handleFinish}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          name="first_name"
          label="First Name"
          rules={[{ required: true, message: "Please input the first_name." }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="last_name"
          label="Last Name"
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {author.id ? "Update Author" : "Create Author"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AuthorEditForm;
