// client/src/api/GuardianAPI.js
import axios from "axios";

const baseURL = "/memberships";

const api = axios.create({
  baseURL,
});

export const createGuardian = async (guardianData) => {
  try {
    const response = await api.post("/guardians", guardianData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getGuardians = async () => {
  try {
    const response = await api.get("/guardians");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getGuardianById = async (guardianId = "") => {
  try {
    const response = await api.get(`/guardians/${guardianId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateGuardian = async (guardianId, guardianData) => {
  try {
    const response = await api.put(`/guardians/${guardianId}`, guardianData);
    return response.data;
  } catch (error) {
    throw error;
  }
};
