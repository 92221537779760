// src/api/DomainAPI.js

import axios from "axios";

const baseURL = "/domains";

const api = axios.create({
  baseURL,
});

export const getDomains = async () => {
  try {
    const response = await api.get("/");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createDomain = async (domainData) => {
  try {
    const response = await api.post("/", domainData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateDomain = async (domainId, domainData) => {
  try {
    const response = await api.put(`/${domainId}`, domainData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getConceptsByDomain = async (domainId) => {
  try {
    const response = await api.get(`/${domainId}/concepts`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createConcept = async (conceptData) => {
  try {
    const response = await api.post(`/concepts`, conceptData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateConcept = async (conceptId, conceptData) => {
  try {
    const response = await api.put(`/concepts/${conceptId}`, conceptData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteConcept = async (conceptId) => {
  try {
    const response = await api.delete(`/concepts/${conceptId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPossibleParents = async (domainId, conceptId) => {
  try {
    const response = await api.get(
      `/${domainId}/concepts/${conceptId}/possible-parents`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPossibleSequences = async (domainId, parentId, isNew) => {
  try {
    const response = await api.get(
      `/concepts/${domainId}/${parentId}/possible-sequences`,
      {
        params: { isNew },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
